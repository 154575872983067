import React from 'react';
import ReactJkMusicPlayer from 'components/ZDMediaPlayer';
import { MdHeadset } from 'react-icons/md';
import 'components/ZDMediaPlayer/assets/index.css';

/*eslint-disable no-console*/
const audioList1 = [
  {
    name: 'Let Me Take You There', //releaseName
    singer: 'Max Styler', //artist
    track: 'KC Lights Remix (Clean)', //trackname
    cover:
      'https://upload.wikimedia.org/wikipedia/en/thumb/2/27/I_Will_Follow_by_Jeremy_Camp.jpg/220px-I_Will_Follow_by_Jeremy_Camp.jpg',
    musicSrc: '//cdn.lijinke.cn/gaoshang.mp3',
    // lyric
  },
  {
    name: 'Plastic Dreams 2020',
    singer: 'The Cube Guys',
    track: 'Cubed Remix 2020',
    cover:
      'https://static.vibe.com/files/2017/02/Screen-Shot-2017-02-24-at-3.52.52-PM-1487969596-compressed.png',
    musicSrc: () => {
      return Promise.resolve(
        'http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3',
      );
    },
  },
];

const audioList2 = [
  {
    name: 'Bedtime Stories',
    singer: 'Jay Chou',
    track: 'Test track',
    cover:
      'https://beta.zipdj.com/archive/legacy/164660/medium_201907052056341607823094_ringmybell.jpg',
    musicSrc:
      'http://res.cloudinary.com/alick/video/upload/v1502375674/Bedtime_Stories.mp3',
  },
  {
    name: 'Dorost Nemisham',
    singer: 'Sirvan Khosravi',
    track: 'Test track 2',
    cover:
      'https://res.cloudinary.com/ehsanahmadi/image/upload/v1573758778/Sirvan-Khosravi-Dorost-Nemisham_glicks.jpg',
    musicSrc: () => {
      return Promise.resolve(
        'https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3',
      );
    },
  },
  {
    name: 'David',
    singer: 'lorem',
    track: 'Test track 3',
    cover:
      'https://upload.wikimedia.org/wikipedia/en/thumb/7/7d/Khalid_-_American_Teen.png/220px-Khalid_-_American_Teen.png',
    musicSrc: '//cdn.lijinke.cn/nande.mp3',
  },
];

const options = {
  //audio lists model
  audioLists: [],
  // audioLists: audioList1,

  //default play index of the audio player  [type `number` default `0`]
  defaultPlayIndex: 0,

  //if you want dynamic change current play audio you can change it [type `number` default `0`]
  // playIndex: 0,

  //color of the music player theme    [ type `string: 'light' or 'dark'  ` default 'dark' ]
  theme: 'dark',

  // Specifies movement boundaries. Accepted values:
  // - `parent` restricts movement within the node's offsetParent
  //    (nearest node with position relative or absolute), or
  // - a selector, restricts movement within the targeted node
  // - An object with `left, top, right, and bottom` properties.
  //   These indicate how far in each direction the draggable
  //   can be moved.
  bounds: 'body',

  // Replace a new playlist with the first loaded playlist
  // instead of adding it at the end of it.
  // [type `boolean`, default `false`]
  clearPriorAudioLists: false,

  // Play your new play list right after your new play list is loaded turn false.
  // [type `boolean`, default `false`]
  autoPlayInitLoadPlayList: false,

  //Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
  //"auto|metadata|none" "true| false"
  preload: false,

  //Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
  glassBg: false,

  //The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
  remember: true,

  //The Audio Can be deleted  [type `Boolean`, default `true`]
  remove: true,

  //audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
  defaultPosition: {
    bottom: 0,
    right: 0,
  },

  // play mode text config of the audio player
  playModeText: {
    order: 'Play in order',
    orderLoop: 'List loop',
    singleLoop: 'Single cycle',
    shufflePlay: 'Shuffle Playback',
  },

  //audio controller open text  [ type `String | ReactNode` default 'open']
  openText: 'Turn on',

  //audio controller close text  [ type `String | ReactNode` default 'close']
  closeText: 'Shut down',

  //audio theme switch checkedText  [ type `String | ReactNode` default '-']
  checkedText: 'Open',

  //audio theme switch unCheckedText [ type `String | ReactNode` default '-']
  unCheckedText: 'Turn off',

  // audio list panel show text of the playlist has no songs [ type `String` | ReactNode  default 'no music']
  notContentText: 'No music',

  panelTitle: 'Playlist',

  hotKeysPanelTitle: 'Keys',

  defaultPlayMode: 'order',

  //audio mode        mini | full          [type `String`  default `mini`]
  mode: 'full',

  /**
   * [ type `Boolean` default 'false' ]
   * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
   */
  once: false,

  //Whether the audio is played after loading is completed. [type `Boolean` default 'true']
  autoPlay: true,

  //Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
  toggleMode: false,

  //audio cover is show of the "mini" mode [type `Boolean` default 'true']
  showMiniModeCover: false,

  //audio playing progress is show of the "mini"  mode
  showMiniProcessBar: false,

  //audio controller is can be drag of the "mini" mode     [type `Boolean` default `true`]
  drag: false,

  //drag the audio progress bar [type `Boolean` default `true`]
  seeked: true,

  //audio controller title [type `String | ReactNode`  default <MdHeadset/>]
  controllerTitle: <MdHeadset />,

  //Displays the audio load progress bar.  [type `Boolean` default `true`]
  showProgressLoadBar: true,

  //play button display of the audio player panel   [type `Boolean` default `true`]
  showPlay: true,

  //reload button display of the audio player panel   [type `Boolean` default `true`]
  showReload: false,

  //download button display of the audio player panel   [type `Boolean` default `true`]
  showDownload: true,

  //download button display of the audio player panel   [type `Boolean` default `true`]
  hasFeedback: false,

  //loop button display of the audio player panel   [type `Boolean` default `true`]
  showPlayMode: false,

  //theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
  showThemeSwitch: false,

  //lyric display of the audio player panel   [type `Boolean` default `false`]
  showLyric: false,

  //Extensible custom content       [type 'Array' default '[]' ]
  extendsContent: [],

  //default volume of the audio player [type `Number` default `100` range `0-100`]
  defaultVolume: 100,

  //playModeText show time [type `Number(ms)` default `700`]
  playModeShowTime: 600,

  //Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
  loadAudioErrorPlayNext: true,

  // Auto hide the cover photo if no cover photo is available [type `Boolean` default `false`]
  autoHiddenCover: true,

  //Music is downloaded handle
  onAudioDownload(audioInfo) {},

  //audio play handle
  onAudioPlay(audioInfo) {
    let elem = document.querySelectorAll(`[data-id="${audioInfo.track_id}"]`);
    let trElem = document.querySelectorAll(
      `[data-tr-id="${audioInfo.track_id}"]`,
    );

    if (elem[0] !== undefined) {
      for (let i = 0; i < elem.length; i++) {
        if (
          elem[i].children[0] &&
          elem[i].children[0].classList.contains('zd-icon-repeat-track')
        ) {
          elem[i].children[0].classList.add('zd-icon-pause');
          elem[i].children[0].classList.remove('zd-icon-repeat-track');
          elem[i].setAttribute('data-play-status', 'pause');
        }
      }
    }
    if (trElem[0] !== undefined) {
      for (let i = 0; i < trElem.length; i++) {
        trElem[i].classList.add('zd-selected-row');
      }
    }
  },

  //audio pause handle
  onAudioPause(audioInfo) {
    let elem = document.querySelectorAll(`[data-id="${audioInfo.track_id}"]`);
    if (elem[0] !== undefined) {
      for (let i = 0; i < elem.length; i++) {
        if (
          elem[i].children[0] &&
          elem[i].children[0].classList.contains('zd-icon-pause')
        ) {
          elem[i].children[0].classList.remove('zd-icon-pause');
          elem[i].children[0].classList.add('zd-icon-repeat-track');
        }
      }
    }
  },

  //When the user has moved/jumped to a new location in audio
  onAudioSeeked(audioInfo) {},

  //When the volume has changed  min = 0.0  max = 1.0
  onAudioVolumeChange(currentVolume) {},

  //The single song is ended handle
  onAudioEnded(audioInfo) {
    let elem = document.querySelectorAll(`[data-id="${audioInfo.track_id}"]`);
    let trElem = document.getElementsByClassName('zd-selected-row');
    if (elem[0] !== undefined) {
      for (let i = 0; i < elem.length; i++) {
        if (elem[i].classList.contains('zd-item-play')) {
          elem[i].children[0].classList.remove('zd-icon-pause');
          elem[i].children[0].classList.add('zd-icon-repeat-track');
        }
      }
    }
    if (trElem[0] !== undefined) {
      for (let i = 0; i < trElem.length; i++) {
        trElem[i].classList.remove('zd-selected-row');
      }
    }
  },

  //audio load abort The target event like {...,audioName:xx,audioSrc:xx,playMode:xx}
  onAudioAbort(e) {},

  //audio play progress handle
  onAudioProgress(audioInfo) {},

  //audio reload handle
  onAudioReload(audioInfo) {},

  //audio load failed error handle
  onAudioLoadError(e) {
    console.error('audio load err', e);
  },

  //theme change handle
  onThemeChange(theme) {},

  onAudioListsChange(currentPlayId, audioLists, audioInfo) {
    let elem = document.querySelectorAll(`[data-id="${audioInfo.track_id}"]`);
    let trElem = document.getElementsByClassName('zd-selected-row');
    if (elem[0] !== undefined && audioInfo.id !== currentPlayId) {
      for (let i = 0; i < elem.length; i++) {
        if (
          elem[i].children[0] &&
          elem[i].children[0].classList.contains('zd-icon-pause')
        ) {
          elem[i].children[0].classList.remove('zd-icon-pause');
          elem[i].children[0].classList.add('zd-icon-repeat-track');
        }
      }
    }
    if (trElem[0] !== undefined) {
      for (let i = 0; i < trElem.length; i++) {
        trElem[i].classList.remove('zd-selected-row');
      }
    }
  },

  onAudioPlayTrackChange(currentPlayId, audioLists, audioInfo) {
    // console.log(
    //   'audio play track change:',
    //   currentPlayId,
    //   audioLists,
    //   audioInfo,
    // );
    let elem = document.querySelectorAll(`[data-id="${audioInfo.track_id}"]`);
    let trElem = document.getElementsByClassName('zd-selected-row');
    if (elem[0] !== undefined) {
      for (let i = 0; i < elem.length; i++) {
        if (
          elem[i].children[0] &&
          elem[i].children[0].classList.contains('zd-icon-pause')
        ) {
          elem[i].children[0].classList.remove('zd-icon-pause');
          elem[i].children[0].classList.add('zd-icon-repeat-track');
        }
      }
    }
    if (trElem[0] !== undefined) {
      for (let i = 0; i < trElem.length; i++) {
        trElem[i].classList.remove('zd-selected-row');
      }
    }
  },

  onPlayModeChange(playMode) {},

  onModeChange(mode) {},

  onAudioListsPanelChange(panelVisible) {},

  onHotKeysPanelChange(panelVisible) {},

  onAudioListsDragEnd(fromIndex, endIndex) {},

  onAudioLyricChange(lineNum, currentLyric) {},

  // custom music player root node
  getContainer() {
    return document.body;
  },

  /**
   * @description get origin audio element instance , you can use it do everything
   * @example
   * audio.playbackRate = 1.5  // set play back rate
   * audio.crossOrigin = 'xxx' // config cross origin
   */
  getAudioInstance(audio) {},

  // transform audio info like return a Promise

  /**
   * @return
   *  {
   *    src: 'xxx',
   *    filename: 'xxx',
   *    mimeType: 'xxx'
   *  }
   */
  // onBeforeAudioDownload() {
  //   return Promise.resolve({
  //     src: '1.mp3'
  //   })
  // }
};

class ZDMusicPlayer extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    params: options,
  };

  onAddAudio = () => {
    const data = {
      ...this.state.params,
      audioLists: [
        ...this.state.params.audioLists,
        {
          name: "I'm new here",
          singer: 'jack',
          track: 'Test track 4',
          cover: 'http://www.lijinke.cn/music/1387583682387727.jpg',
          musicSrc: `http://www.lijinke.cn/music/${Date.now()}.mp3`,
        },
      ],
    };
    this.setState({
      params: data,
    });
  };
  extendsContent = () => {
    const data = {
      ...this.state.params,
      extendsContent: (
        <>
          <button>button1</button>
          <button>button2</button>
        </>
      ),
    };
    this.setState({
      params: data,
    });
  };

  onChangeToFirstAudioList = () => {
    const data = {
      ...this.state.params,
      clearPriorAudioLists: true,
      audioLists: audioList1,
    };
    this.setState({
      params: data,
    });
  };

  onChangeToSecondAudioList = () => {
    const data = {
      ...this.state.params,
      clearPriorAudioLists: true,
      audioLists: audioList2,
    };
    this.setState({
      params: data,
    });
  };
  onAutoPlayMode = () => {
    const data = {
      ...this.state.params,
      autoPlay: !this.state.params.autoPlay,
    };
    this.setState({
      params: data,
    });
  };

  onAutoPlayInitLoadPlayList = () => {
    const data = {
      ...this.state.params,
      autoPlayInitLoadPlayList: !this.state.params.autoPlayInitLoadPlayList,
    };
    this.setState({
      params: data,
    });
  };

  onShowGlassBg = () => {
    this.onChangeKey('glassBg');
  };
  onDrag = () => {
    this.onChangeKey('drag');
  };
  onToggleMode = () => {
    this.onChangeKey('toggleMode');
  };
  onSeeked = () => {
    this.onChangeKey('seeked');
  };
  onChangeKey = key => {
    const data = {
      ...this.state.params,
      [key]: !this.state.params[key],
    };
    if (key === 'light' || key === 'dark') {
      data.theme = key;
    }
    if (key === 'full' || key === 'mini') {
      data.mode = key;
    }
    this.setState({ params: data });
  };
  showMiniProcessBar = () => {
    this.onChangeKey('showMiniProcessBar');
  };
  showMiniModeCover = () => {
    this.onChangeKey('showMiniModeCover');
  };
  playModeShowTime = () => {
    const data = {
      ...this.state.params,
      playModeShowTime: 1,
    };
    this.setState({
      params: data,
    });
  };
  changePlayIndex = () => {
    const data = {
      ...this.state.params,
      playIndex: 1,
    };
    this.setState({
      params: data,
    });
  };
  componentDidMount() {
    const { params } = this.state;
    params.audioLists = this.props.zdAudioList;
  }

  showNotificationFn(data) {
    this.props.dmwNotification(data);
  }
  downloadManagerGlobalDataFn(data) {
    this.props.downloadManagerGlobalData(data);
  }
  render() {
    const { params } = this.state;

    params.audioLists = this.props.zdAudioList;
    if (
      this.props.zdAudioList === undefined &&
      JSON.parse(localStorage.getItem('audioLists')) &&
      JSON.parse(localStorage.getItem('lastPlayStatus'))
    ) {
      params.remember = true;
      let stat = JSON.parse(localStorage.getItem('lastPlayStatus'));
      stat.pause = true;
      localStorage.setItem('lastPlayStatus', JSON.stringify(stat));
      params.audioLists = JSON.parse(localStorage.getItem('audioLists'));
    }

    return (
      <>
        <ReactJkMusicPlayer
          {...params}
          {...this.props}
          dmwNotification={this.showNotificationFn.bind(this)}
          getTotalDownloadItems={this.getTotalDownloadItemsFn}
          downloadManagerGlobalData={this.downloadManagerGlobalDataFn.bind(
            this,
          )}
          updateDownloadManager={this.props.updateDownloadManager}
          activeTracksList={this.props.activeTracksList}
          setActiveTracksList={this.props.setActiveTracksList}
        />
      </>
    );
  }
}

export default ZDMusicPlayer;
