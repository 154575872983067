import React from 'react';

export const LeftArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 19L15.1282 12L8 5"
      stroke="#ABB1B8"
      stroke-width="1.3"
      stroke-linecap="round"
    />
  </svg>
);

export const RightArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19L8.87183 12L16 5"
      stroke="#ABB1B8"
      stroke-width="1.3"
      stroke-linecap="round"
    />
  </svg>
);
