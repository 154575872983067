import React, { useEffect, useState } from 'react';
import './AllTag.css';
import gsap from 'gsap';
import { zdSetBreadcrumb } from '../../utils/Helpers';
import { removeGenreIds, removeTags } from '../../utils/GlobalVariables';

const scrollToTag = e => {
  const selectedLetter = e.target.innerHTML;
  // const moveParameter = document.getElementById(selectedLetter)
  // moveParameter.scrollIntoView({ behavior: "smooth", block: "start" })
  // moveParameter.parentNode.scrollTop = moveParameter.offsetTop - 70;

  let modalIndexElement = document.getElementById(selectedLetter);
  let topPosition = modalIndexElement.offsetTop - 25;

  setTimeout(() => {
    document.getElementsByClassName('tag-container')[0].scrollTo({
      top: topPosition,
      behavior: 'smooth',
    });
  }, 100);
};

const AllTag = ({
  tags,
  megaTagFilter,
  genreTags,
  filteredTags,
  activeTracksList,
  convertGenres,
  searchTerm,
}) => {
  if (filteredTags) {
    tags = filteredTags;
  }

  console.log(tags, 'tags');

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    screenSet();
    const tl = gsap.timeline();
    tl.from('.alltag', {
      opacity: 0.4,
      y: -70,
      delay: 0.1,
    }).from('.alltag__container h5', 1, {
      opacity: 0.4,
      y: 50,
      stagger: {
        amount: 0.1,
      },
    });
  }, []);

  const screenSet = () => {
    if (window.innerWidth <= 1224 || window.innerWidth >= 2200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener('resize', () => {
    screenSet();
  });
  // const findTag = tag_id => {
  //   let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));
  //   return genre_preference.find(element => element == tag_id);
  // };

  const handleSearch = tags => {
    if (searchTerm === '') {
      return tags;
    }
    let filteredTags = [];
    let search = '';
    if (searchTerm) {
      search = searchTerm.toLowerCase();
    }

    filteredTags = tags.map(tag => {
      let children = tag.children.filter(child => {
        let tag_key = child.tag_key ? child.tag_key.toLowerCase() : '';
        let genre_title = child.genre_title
          ? child.genre_title.toLowerCase()
          : '';
        return tag_key.includes(search) || genre_title.includes(search);
      });
      return { ...tag, children };
    });

    // Check if all children arrays have a length of 0
    let allChildrenEmpty = filteredTags.every(tag => tag.children.length === 0);

    if (allChildrenEmpty) {
      return [];
    }
    console.log(filteredTags, 'filteredTags');
    return filteredTags;
  };

  const ifNotFromRemovedList = (tagId, type, cnt) => {
    if (cnt === 11584) {
      return false;
    }
    if (type === 'tag') {
      if (!tagId) return true;
      return !removeTags.includes(tagId);
    }
    if (type === 'genre') {
      if (!tagId) return true;
      return !removeGenreIds.includes(tagId);
    }
  };

  return (
    <div className="">
      <div
        className={
          activeTracksList && activeTracksList.length > 0
            ? 'tag-container tag-container-tablet'
            : 'tag-container'
        }
      >
        {handleSearch(tags).length > 0 &&
          handleSearch(tags).map((eachTag, index) => {
            if (eachTag.children.length > 0) {
              return (
                <>
                  <div key={index} className="alltag__container">
                    <h5 className="alltag__heading" id={eachTag.heading}>
                      {eachTag.heading}
                    </h5>
                    <div className="septags">
                      {eachTag.children.map(tag => (
                        <>
                          {genreTags &&
                          ifNotFromRemovedList(
                            tag.tag_id ? tag.tag_id : tag.genre_id,
                            tag.tag_id ? 'tag' : 'genre',
                            tag.tag_id ? tag.cnt : null,
                          ) ? (
                            <div
                              className="alltag__tag"
                              key={tag.tag_id ? tag.tag_id : tag.genre_id}
                            >
                              <p
                                className="zd-cursor"
                                onClick={e => {
                                  megaTagFilter(
                                    e,
                                    tag.tag_id ? 'tag_id' : 'all_genre_id',
                                  );
                                  zdSetBreadcrumb(
                                    'Genre',
                                    tag.tag_key ? tag.tag_key : tag.genre_title,
                                    tag.tag_key ? tag.tag_key : tag.genre_title,
                                    tag.tag_id ? tag.tag_id : tag.genre_id,
                                  );
                                }}
                                data-id={tag.tag_id ? tag.tag_id : tag.genre_id}
                                title={
                                  tag.tag_key ? tag.tag_key : tag.genre_title
                                }
                              >
                                <span
                                // className={
                                //   findTag(tag.tag_id ? tag.tag_id : tag.genre_id)
                                //     ? 'border_bottom_bar_left'
                                //     : ''
                                // }
                                >
                                  <span>
                                    {tag.tag_key
                                      ? tag.tag_key.replaceAll('/', ' • ')
                                      : tag.genre_title.replaceAll('/', ' • ')}
                                  </span>
                                  {/* <span
                                    style={{
                                      paddingLeft: '5px',
                                      color: '#0f0',
                                    }}
                                  >
                                    {tag.tag_id ? '# tag #' : '# genre #'}
                                    {tag.tag_id || tag.genre_id}
                                  </span> */}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              );
            }
          })}
        {handleSearch(tags).length === 0 && (
          <div className="">
            <h5 className="">No Results</h5>
          </div>
        )}
        <div
          className={
            activeTracksList && activeTracksList.length > 0
              ? 'alltag_sm alltag_sm-tablet'
              : 'alltag_sm'
          }
        >
          {/* {isMobile &&
            tags.map((eachTag, index) => {
              return eachTag.children.length > 0 ? (
                <div className="alltag_az_sm active" key={index}>
                  <p onClick={scrollToTag}>{eachTag.heading}</p>
                </div>
              ) : (
                <div className="alltag_az_sm" key={index}>
                  <p>{eachTag.heading}</p>
                </div>
              );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default AllTag;
