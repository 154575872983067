/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/_genrecard.scss';
import { genresData } from '../../../utils/GlobalVariables';
import ZDWaveLoader from 'components/ZDWaveLoader';
import axios from 'axios';
axios.defaults.timeout = 60000;

const MegaGenre = props => {
  useEffect(() => {
    apiCallToGetTags();
  }, []);
  const [data, setData] = useState([]);
  // ! Axios call To get all Tags from database
  const apiCallToGetTags = () => {
    const URL = process.env.REACT_APP_API_PATH + '/zdmapi/genretags';
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    const post_data = {
      type: 'all',
    };
    axios
      .post(URL, post_data, {
        headers: { 'X-Session-Token': loginInfo.token },
      })
      .then(response => {
        if (response.data.tags.length > 0) {
          console.log('response.data.tags', response.data);
          setData(response.data.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const AllDataGenres = () => {
    return (
      <>
        {genresData.length > 0 ? (
          genresData.map(genre => (
            <div
              key={genre.id}
              className={
                data.find(element => element == genre.tag_id)
                  ? 'genrecard__card border_left'
                  : 'genrecard__card'
              }
            >
              <Link
                to={
                  genre.genre_id
                    ? `/genres/subgenre/genres/id/${genre.genre_id}`
                    : `/member/newrelease/?tag_id[0]=${genre.tag_id}`
                }
              >
                <div className="genrecard__img">
                  <img
                    src={
                      process.env.REACT_APP_S3_PATH +
                      '/images/genres/genre_' +
                      String(
                        Math.floor(Math.random() * (59 - 2 + 1) + 2),
                      ).padStart(4, '0') +
                      '.png'
                    }
                    alt="Card Image"
                  />
                </div>
                <div className="genrecard__content">
                  {}
                  <span>
                    {genre &&
                      genre.tag_title &&
                      genre.tag_title.replaceAll('/', '\u{26AC}')}
                  </span>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <ZDWaveLoader />
        )}
      </>
    );
  };

  return (
    <>
      {/* <h2 className="genre_heading">
        <span
          className="default active"
          onClick={() => props.setIsActive(false)}
        >
          DEFAULT
        </span>
        <span className="alpha ">ALPHABETICAL</span>
      </h2> */}

      <div className="genrecard">
        <div className="genrecard__cards">
          {genresData ? AllDataGenres() : ''}
        </div>
      </div>
    </>
  );
};

export default MegaGenre;
