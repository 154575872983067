import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {
  return (
    <>
      <Navbar className="zd-footer">
        <Nav navbar className="zd-text-6b7883">
          <NavItem>
            &copy; 2007 - {new Date().getFullYear()}{' '}
            <span>ZIPDJ - All Rights Reserved.</span>
          </NavItem>
        </Nav>
      </Navbar>
    </>
  );
};

export default Footer;
