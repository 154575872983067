import React from 'react';
import cls from 'classnames';

const HotKeysPanel = ({
  notContentText,
  onCancel,
  closeIcon,
  hotKeysPanelTitle,
  hotKeysPanelToggleAnimate,
  glassBg,
  isMobile,
}) => (
  <div
    className={cls(
      'audio-lists-panel hot-keys-panel',
      hotKeysPanelToggleAnimate,
      {
        'glass-bg': glassBg,
      },
    )}
    key="hot-keys-panel"
  >
    <div className="audio-lists-panel-header hot-keys-panel-header">
      <h2 className="title">
        <span key="panel-title">{hotKeysPanelTitle}</span>
        <span
          key="close-btn"
          className="close-btn"
          title="Close"
          onClick={onCancel}
        >
          {closeIcon}
        </span>
      </h2>
    </div>
    <div className="zd-media-panel-content zd-hot-keys-content">
      <div className="panel-content-bg">
        <h4>Player Keyboard Shortcuts</h4>
        <ul>
          <li>Space Bar - Play/Pause</li>
          <li>Back Space/Delete = Mute</li>
          <li>Ctrl + Left/Right Arrow Keys = Seek</li>
          <li>on Mac: Control + Option + Left/Right Arrow Keys = Seek</li>
        </ul>

        <h4>Page Keyboard Shortcuts</h4>
        <ul>
          <li>Up/Down Arrow Keys = Scroll Through Mixes</li>
          <li>Left/Right Arrow Keys = Scroll Through And Play Mixes</li>
          <li>Enter Key = Play Selected Mix</li>
          {/* <li>Q Key = Cue Selected Mix</li> */}
          <li>Tab Key = Download Selected Mix</li>
        </ul>
      </div>
    </div>
  </div>
);

export default HotKeysPanel;
