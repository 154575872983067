import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import '../../assets/zdv3-icons/zdv3-icon.scss';
import { Button, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';
import { zdsetClearGenreFilter, zdsetGenreDropDownValue } from 'utils/Helpers';
import ZdV5ArrowRight from '../../assets/img/sidebar/zd-v5-arrow-right.svg';
import { LeftArrow, RightArrow } from '../../@const/icons';

//for desktop view
const navItems = [
  {
    to: '/member',
    name: 'Home',
    exact: true,
    icon: 'home',
    classname: 'home',
    badge: '',
    fontIcon: 'zdv3-icon-home',
  },
  {
    to: '/member/newrelease',
    name: 'New Releases',
    exact: false,
    icon: 'newReleases',
    classname: 'new-releases',
    badge: '',
    fontIcon: 'zdv3-icon-new-releases',
  },
  {
    to: '/member/topdownloads',
    name: 'Top Downloads',
    exact: false,
    icon: 'topDownloads',
    classname: 'top-downloads',
    fontIcon: 'zdv3-icon-top-downloads',
    badge: '',
  },
  {
    to: '/member/all',
    name: 'Trending Charts',
    exact: false,
    icon: 'trendingCharts',
    classname: 'trending-charts',
    fontIcon: 'zdv3-icon-trending-charts',
    badge: '',
    activeTabURLs: [
      '/member/all',
      '/member/airplay',
      '/member/electronic',
      '/member/mainstream',
      '/member/urban',
    ],
  },
  {
    to: '/member/charts/dance',
    name: 'Top Charts',
    exact: false,
    icon: 'charts',
    classname: 'charts',
    fontIcon: 'zdv3-icon-top-charts',
    badge: '',
    activeTabURLs: [
      '/member/charts/dance',
      '/member/charts/club',
      '/member/charts/urban',
      '/member/charts/house',
    ],
  },
  {
    to: '/member/packsets',
    name: 'ZIPDJ Packs',
    exact: false,
    icon: 'zipdjpacks',
    classname: 'zipdjpacks',
    fontIcon: 'zdv3-icon-packs',
    badge: '',
    activeTabURLs: [
      '/member/packsets',
      '/member/packs',
      '/member/trending-packs',
    ],
  },
  // {
  //   to: '/member/labels',
  //   name: 'Labels',
  //   exact: false,
  //   icon: 'labels',
  //   classname: 'labels',
  //   fontIcon: 'zdv3-icon-labels',
  //   badge: '',
  // },

  {
    to: '/member/genres/index',
    name: 'Genres',
    exact: false,
    icon: 'genres',
    classname: 'genres',
    fontIcon: 'zdv3-icon-genres',
    badge: '',
  },
  {
    to: '/member/download-manager',
    name: 'Download Manager',
    exact: false,
    icon: 'downloadManager',
    classname: 'zd-download-manager-btn',
    fontIcon: 'zdv3-icon-download-manager',
    badge: '0',
  },
];
//for mobile view
const SmnavItems = [
  {
    to: '/member',
    name: 'Home',
    exact: true,
    icon: 'home',
    classname: 'home',
    badge: '',
    fontIcon: 'zdv3-icon-home',
  },
  {
    to: '/member/newrelease',
    name: 'New Releases',
    exact: false,
    icon: 'newReleases',
    classname: 'new-releases',
    badge: '',
    fontIcon: 'zdv3-icon-new-releases',
  },
  {
    to: '/member/topdownloads',
    name: 'Top Downloads',
    exact: false,
    icon: 'topDownloads',
    classname: 'top-downloads',
    fontIcon: 'zdv3-icon-top-downloads',
    badge: '',
  },
  {
    to: '/member/all',
    name: 'Trending Charts',
    exact: false,
    icon: 'trendingCharts',
    classname: 'trending-charts',
    fontIcon: 'zdv3-icon-trending-charts',
    badge: '',
    activeTabURLs: [
      '/member/all',
      '/member/airplay',
      '/member/electronic',
      '/member/mainstream',
      '/member/urban',
    ],
  },
  {
    to: '/member/charts/dance',
    name: 'Top Charts',
    exact: false,
    icon: 'charts',
    classname: 'charts',
    fontIcon: 'zdv3-icon-top-charts',
    badge: '',
    activeTabURLs: [
      '/member/charts/dance',
      '/member/charts/club',
      '/member/charts/urban',
      '/member/charts/house',
    ],
  },
  {
    to: '/member/packsets',
    name: 'ZIPDJ Packs',
    exact: false,
    icon: 'zipdjpacks',
    classname: 'zipdjpacks',
    fontIcon: 'zdv3-icon-packs',
    badge: '',
    activeTabURLs: [
      '/member/packsets',
      '/member/packs',
      '/member/trending-packs',
    ],
  },
  // {
  //   to: '/member/labels',
  //   name: 'Labels',
  //   exact: false,
  //   icon: 'labels',
  //   classname: 'labels',
  //   fontIcon: 'zdv3-icon-labels',
  //   badge: '',
  // },

  {
    to: '/member/genres/index',
    name: 'Genres',
    exact: false,
    icon: 'genres',
    classname: 'genres',
    fontIcon: 'zdv3-icon-genres',
    badge: '',
  },
  // {
  //   to: '/member/profile?q=myprofile',
  //   name: 'My Account',
  //   exact: false,
  //   icon: '',
  //   classname: 'genres',
  //   fontIcon: '',
  //   badge: '',
  // },
  // ----------------
  {
    to: '/member/download-manager',
    name: 'Download Manager',
    exact: false,
    icon: '',
    classname: 'genres',
    fontIcon: '',
    badge: '',
  },
  {
    to: '/member/logout',
    name: 'Logout',
    exact: false,
    icon: '',
    classname: 'genres',
    fontIcon: '',
    badge: '',
  },
  // {
  //   to: '/member/download-manager',
  //   name: 'Download Manager',
  //   exact: false,
  //   icon: 'downloadManager',
  //   classname: 'zd-download-manager-btn',
  //   fontIcon: 'zdv3-icon-download-manager',
  //   badge: '0',
  // },
  {
    to: '/member/logout',
    name: 'Logout',
    exact: false,
    icon: '',
    classname: 'genres',
    fontIcon: '',
    badge: '',
  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: false,
    isOpenContents: false,
    isOpenPages: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  clearfilter = e => {
    // e.preventDefault();
    zdsetClearGenreFilter(true);
    zdsetGenreDropDownValue('');
    // zdsetDateDropDownValue('');
    this.props.history.push({
      search: '',
    });
    localStorage.removeItem('breadcrumb');

    let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));

    if (genre_preference) {
      this.props.setGenrePreferenceStatus(false);
    }
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    document.body.classList.toggle('zd-sidebar-open');
    // Check if zd-v5-search-mobile-close display is not none
    if (document.querySelector('.zd-search-open')) {
      this.props.handleSearchGenre();
    }
    if (this.props.megaMenuShow) {
      this.props.showMegaMenu();
      document.body.classList.remove('zd-search-open');
    }
    if (
      document.querySelector('.cr-sidebar--open .zd-hamburger-menu.desktop ')
    ) {
      document.querySelector(
        '.cr-sidebar--open .zd-hamburger-menu.desktop',
      ).innerHTML = `<svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19L8.87183 12L16 5"
        stroke="#ABB1B8"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>`;
    } else {
      document.querySelector('.zd-hamburger-menu.desktop').innerHTML = `<svg
      width="24"
      height="24" viewBox="0 0 24 24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 19L15.1282 12L8 5"
        stroke="#ABB1B8"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>`;
    }

    if (document.querySelector('.cr-sidebar--open .zd-hamburger-menu.mobile')) {
      document.querySelector(
        '.cr-sidebar--open .zd-hamburger-menu.mobile',
      ).innerHTML = `<i class="zdv3-icon-menu-after" id="ZD-Sidebar-toggler"></i>`;
    } else {
      document.querySelector('.zd-hamburger-menu.mobile').innerHTML =
        '<i class="zdv3-icon-menu" id="ZD-Sidebar-toggler"></i>';
    }
  };

  handleProfileUrl = () => {
    this.props.history.push('/member/profile?q=myprofile');
  };

  handleMobileScreenNavChange = e => {
    this.handleSidebarControlButton(e);
    this.clearfilter(e);
  };

  render() {
    let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));

    var query_url = '';
    let activeGenrePreference =
      this.props.activeGenrePreference.length > 0
        ? this.props.activeGenrePreference
        : genre_preference;

    if (activeGenrePreference && activeGenrePreference.length > 0) {
      activeGenrePreference &&
        activeGenrePreference.length &&
        activeGenrePreference.map((tag_id, index) => {
          if (index !== 0) {
            query_url = query_url + '&tag_id[' + index + ']=' + tag_id;
          } else {
            query_url = query_url + 'tag_id[' + index + ']=' + tag_id;
          }
        });
    }

    navItems[7].badge =
      this.props && 'totalDownloadItems' in this.props
        ? this.props.totalDownloadItems
        : '0';

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('content')}>
          <span className="zd-hamburger-menu-parent">
            <Button
              className="zd-hamburger-menu desktop"
              onClick={this.handleSidebarControlButton}
            >
              <LeftArrow />
            </Button>
            <Button
              className="zd-hamburger-menu mobile"
              onClick={this.handleSidebarControlButton}
            >
              <i className="zdv3-icon-menu"></i>
            </Button>
          </span>

          <Nav vertical>
            <>
              <div className="zd-lg-sidebar-nav-item">
                {navItems.map(
                  (
                    {
                      to,
                      name,
                      exact,
                      classname,
                      badge,
                      fontIcon,
                      activeTabURLs,
                    },
                    index,
                  ) => (
                    <NavItem
                      key={index}
                      className={`${classname} ${bem.e('nav-item')}`}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={
                          to == '/member/newrelease' ||
                          to == '/member/topdownloads' ||
                          to == 'member/all'
                            ? `${to}?${query_url}`
                            : to
                        }
                        activeClassName="active"
                        exact={exact}
                        onClick={this.clearfilter}
                        active={
                          activeTabURLs &&
                          activeTabURLs.includes(this.props.location.pathname)
                        }
                      >
                        <i className={`zd-sidebar-icon ${fontIcon}`}></i>

                        {badge !== '' ? (
                          <b className="position-absolute badge badge-primary rubberBand">
                            <small>{badge}</small>
                          </b>
                        ) : (
                          ''
                        )}
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ),
                )}
              </div>
              <div className="zd-sm-sidebar-nav-item">
                {SmnavItems.map(
                  (
                    {
                      to,
                      name,
                      exact,
                      classname,
                      badge,
                      fontIcon,
                      activeTabURLs,
                    },
                    index,
                  ) => (
                    <NavItem
                      key={index}
                      className={`${classname} ${bem.e('nav-item')}`}
                      onClick={this.handleMobileScreenNavChange}
                    >
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={`${to}?${query_url}`}
                        activeClassName="active"
                        exact={exact}
                        active={
                          activeTabURLs &&
                          activeTabURLs.includes(this.props.location.pathname)
                        }
                      >
                        <i className={`zd-sidebar-icon ${fontIcon}`}></i>

                        {badge !== '' ? (
                          <b className="position-absolute badge badge-primary rubberBand">
                            <small>{badge}</small>
                          </b>
                        ) : (
                          ''
                        )}
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ),
                )}
              </div>
            </>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default withRouter(Sidebar);
