import React, {
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { WaveSurfer, WaveForm } from 'wavesurfer-react';
import Slider from 'rc-slider/lib/Slider';
import circle from 'assets/img/circle.svg';
import axios from 'axios';
axios.defaults.timeout = 60000;

const sliderBaseOptions = {
  min: 0,
  step: 0.01,
  trackStyle: { backgroundColor: '#31c27c' },
  handleStyle: { backgroundColor: '#31c27c', border: '1px solid #909090' },
};

const WavesurferPlayer = forwardRef((props, ref) => {
  // For gradient progress wave
  let linGrad = document
    .createElement('canvas')
    .getContext('2d')
    .createLinearGradient(0, 0, 540, 44);
  linGrad.addColorStop(0, 'rgba(79, 123, 233, 0.3');
  linGrad.addColorStop(1, '#4F7BE9');

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [soundValue, setSoundValue] = useState(1);
  let trackPcm = null;
  let index = props.musicSrc.indexOf('/upload');
  let trackS3Path = props.musicSrc.slice(index);
  let url = process.env.REACT_APP_S3_PATH + trackS3Path;

  let loginInfo = localStorage.getItem('_zd_tk');
  loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

  const api = {
    baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/getpcm',
    xSessionToken: loginInfo.token,
  };

  const get_data = {
    remoteUrl: trackS3Path,
    track_id: parseInt(props.activeTrackId),
  };

  const wavesurferRef = useRef();
  const waveformContainer = useRef();

  const loadPcm = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    try {
      const response = await axios.get(
        `${url}.pcm.json`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        {
          cancelToken: source.token,
        },
      );
      if (response.status === 200 && response.data) {
        trackPcm = response.data;
        wavesurferRef.current.load(url, trackPcm);
        // wavesurferRef.current.play();
      } else if (response.status !== 200 || !response.data) {
        axios
          .post(api.baseUrl, get_data, {
            headers: {
              'X-Session-Token': api.xSessionToken,
            },
          })
          .then(response => {
            if (response.data) {
              trackPcm = response.data.trackPcm;
              wavesurferRef.current.load(
                url,
                JSON.parse(window.atob(trackPcm)),
              );
            }
          });
      }
    } catch (ex) {
      axios
        .post(api.baseUrl, get_data, {
          headers: {
            'X-Session-Token': api.xSessionToken,
          },
        })
        .then(response => {
          if (response.data) {
            trackPcm = response.data.trackPcm;
            wavesurferRef.current.load(url, JSON.parse(window.atob(trackPcm)));
          }
        })
        .catch(err => {
          // wavesurferRef.current.load(url);
          source.cancel('Operation canceled by the user.');
        });
    }
  };

  const handleWSMount = useCallback(
    waveSurfer => {
      if (wavesurferRef.current) {
        wavesurferRef.current.container.removeEventListener(
          'touchstart',
          addMouseMoveListener,
        );
        wavesurferRef.current.container.removeEventListener(
          'mousedown',
          addMouseMoveListener,
        );
        document.removeEventListener('mouseup', removeMouseMoveListener);
        document.removeEventListener('touchend', removeMouseMoveListener);

        wavesurferRef.current.destroy();
      }
      wavesurferRef.current = waveSurfer;
      if (wavesurferRef.current && props.musicSrc.length > 0) {
        loadPcm();

        wavesurferRef.current.on('ready', () => {
          audioSoundChange(isMute ? 0 : soundValue);
          // if (!props.pause) play();
          play();
        });

        // Play the next song on the end of the current song
        wavesurferRef.current.on('finish', () => {
          props.audioNextPlay();
        });
        wavesurferRef.current.container.addEventListener(
          'touchstart',
          addMouseMoveListener,
          { passive: false },
        );
        wavesurferRef.current.container.addEventListener(
          'mousedown',
          addMouseMoveListener,
        );

        // add mouseup
        document.addEventListener('mouseup', removeMouseMoveListener);
        document.addEventListener('touchend', removeMouseMoveListener, {
          passive: false,
        });

        if (window) {
          window.surferidze = wavesurferRef.current;
        }
      }
    },
    [props.musicSrc],
  );

  // add mousemove listener for drag n seek
  const addMouseMoveListener = () => {
    wavesurferRef.current.container.addEventListener(
      'mousemove',
      handleSeeking,
    );
    wavesurferRef.current.container.addEventListener(
      'touchmove',
      handleTouchSeeking,
    );
  };

  // remove mousemove listener for drag n seek
  const removeMouseMoveListener = () => {
    wavesurferRef.current.container.removeEventListener(
      'mousemove',
      handleSeeking,
    );
    wavesurferRef.current.container.removeEventListener(
      'touchmove',
      handleTouchSeeking,
    );
  };

  // Seek the progress while touch dragging
  const handleTouchSeeking = e => {
    let clientX = e.touches[0].clientX;
    // get the wave element
    let wrapper = wavesurferRef.current.container.childNodes[0];
    // get the bounding box
    const bbox = wrapper.getBoundingClientRect();

    // calculate the progress of song from cursor position on the waveform
    let progress =
      (clientX - bbox.left + wrapper.scrollLeft) / wrapper.scrollWidth || 0;
    // set seek progress
    seeking(progress);
  };

  // Seek the progress while mouse dragging
  const handleSeeking = e => {
    // get the wave element
    let wrapper = wavesurferRef.current.container.childNodes[0];
    // get the bounding box
    const bbox = wrapper.getBoundingClientRect();

    // calculate the progress of song from cursor position on the waveform
    let progress =
      (e.clientX - bbox.left + wrapper.scrollLeft) / wrapper.scrollWidth || 0;
    // set seek progress
    seeking(progress);
  };

  // set seekTo progress value
  const seeking = useCallback(value => {
    wavesurferRef.current.seekTo(value);
  });

  // handles play & pause
  const play = useCallback(() => {
    wavesurferRef.current.playPause();

    setIsPlaying(() => (wavesurferRef.current.isPlaying() ? true : false));
    // control play/pause icons in the playlist or history panel.
    props.setPause(!wavesurferRef.current.isPlaying() ? true : false);
  }, []);

  // handles volume change
  const audioSoundChange = useCallback(value => {
    wavesurferRef.current.setVolume(value);
    wavesurferRef.current.setMute(false);

    setSoundValue(value);
  }, []);

  // Handles mute
  const mute = () => {
    wavesurferRef.current.setMute(true);
    setIsMute(true);
    // setSoundValue(0);
  };

  // handles unMute
  const unMute = () => {
    wavesurferRef.current.setMute(false);
    // setSoundValue(wavesurferRef.current.getVolume());
    setIsMute(false);
  };

  // Passes the methods to the parent which enables to control the player from the Parent Component
  useImperativeHandle(
    ref,
    () => ({
      destroy() {
        wavesurferRef.current.destroy();
      },
      handlePlayRef() {
        if (wavesurferRef.current) {
          play();
        } else {
          return;
        }
      },
      handleMuteRef() {
        if (wavesurferRef.current) {
          wavesurferRef.current.getMute() ? unMute() : mute();
        }
      },
      handleForwardSeekRef() {
        if (wavesurferRef.current) {
          wavesurferRef.current.skipForward();
        }
      },
      handleBackwardSeekRef() {
        if (wavesurferRef.current) {
          wavesurferRef.current.skipBackward();
        }
      },
      getPlayStatusRef() {
        return isPlaying;
      },
    }),
    [],
  );

  return (
    <div className="player-content">
      <div className="w-100 zd-waves">
        <WaveSurfer
          onMount={handleWSMount}
          className="zd-waveform"
          key={props.uniqueKey}
        >
          <WaveForm
            id="waveform"
            ref={waveformContainer}
            className="zd-cursor"
            progressColor={linGrad}
            waveColor="#47484A"
            height="38"
            backend="MediaElement"
            fillParent={true}
            responsive={true}
            hideScrollbar={true}
          ></WaveForm>
        </WaveSurfer>
      </div>
      <span className="group zd-manage-track-player">
        <span
          className="group prev-audio mouse-interactions"
          title="Previous track"
          onClick={props.audioPrevPlay}
        >
          <i className="zdv3-icon-backward"></i>
        </span>
        <span
          className="group play-btn circle-play"
          onClick={play}
          key={isPlaying}
          title={isPlaying ? 'Click to pause' : 'Click to play'}
          style={{
            backgroundImage: `url(${circle})`,
          }}
        >
          {isPlaying ? (
            <div className="d-flex justify-content-center align-items-center play-icons mouse-interactions">
              <i className="zdv3-icon-pause zd-text-4f7be9"></i>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center play-icons mouse-interactions">
              <i className="zdv3-icon-play zd-text-4f7be9"></i>
            </div>
          )}
        </span>
        <span
          className="group next-audio mouse-interactions"
          title="Next track"
          onClick={props.audioNextPlay}
        >
          <i className="zdv3-icon-forward"></i>
        </span>
      </span>
      <span
        className="group play-sounds vertical-sound"
        key="play-sound"
        title="Volume"
      >
        {isMute ? (
          <span className="sounds-icon mouse-interactions" onClick={unMute}>
            <i className="zdv3-icon-player-mute"></i>
          </span>
        ) : (
          <span className="sounds-icon mouse-interactions" onClick={mute}>
            <i className="zdv3-icon-player-volume"></i>
          </span>
        )}
        <span className="vertical-sound-bar zd-volume-slide-hide">
          <span className="vertical-sound-bar-child">
            <Slider
              max={1}
              value={soundValue}
              vertical={true}
              onChange={audioSoundChange}
              className="sound-operation"
              {...sliderBaseOptions}
            />
          </span>
        </span>
        <span className="horaizonal-sound-bar ">
          <span className="vertical-sound-bar-child">
            <Slider
              max={1}
              value={soundValue}
              // vertical={true}
              onChange={audioSoundChange}
              className="sound-operation"
              {...sliderBaseOptions}
            />
          </span>
        </span>
      </span>
    </div>
  );
});

export default WavesurferPlayer;
