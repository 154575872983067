import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import {
  formatTime,
  createRandomNum,
  arrayEqual,
  uuId,
  isSafari,
} from './utils';
import HotKeysPanel from './components/HotKeysPanel';
import AudioListsPanel from './components/AudioListsPanel';
import DownloadButton from './components/DownloadButton';
import ZDMediaPauseIcon from 'components/ZDMediaIcons/ZDMediaPauseIcon';
import ZDMediaPlayIcon from 'components/ZDMediaIcons/ZDMediaPlayIcon';
import ZDMediaQueueIcon from 'components/ZDMediaIcons/ZDMediaQueueIcon';
import ZDDefaultThumb from 'assets/img/zipdj-default-thumb-300x300.svg';
import Draggable from 'react-draggable';
import {
  FaHeadphones,
  FaMinusSquare,
  FaSpinner,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdRepeatOne, MdViewHeadline, MdClose } from 'react-icons/md';
import { IoIosShuffle } from 'react-icons/io';
import { zdTrackArtistFilter } from 'utils/Filters';
import 'rc-slider/assets/index.css';
import WavesurferPlayer from './WavesurferPlayer';
const IS_MOBILE = false;
axios.defaults.timeout = 60000;

export const AnimatePlayIcon = () => <ZDMediaPlayIcon />;
export const AnimatePauseIcon = () => <ZDMediaPauseIcon />;
export const Load = () => (
  <span className="loading group" key="loading">
    <FaSpinner />
  </span>
);
export const PlayModel = ({ visible, value }) => (
  <div
    className={classNames('play-mode-title', {
      'play-mode-title-visible': visible,
    })}
    key="play-mode-title"
  >
    {value}
  </div>
);
export const CircleProcessBar = ({ progress = 0, r = 40 } = {}) => {
  const _progress = progress.toFixed(2);
  const perimeter = Math.PI * 2 * r;
  const strokeDasharray = `${~~(perimeter * _progress)} ${~~(
    perimeter *
    (1 - _progress)
  )}`;
  return (
    <svg className="audio-circle-process-bar">
      <circle
        cx={r}
        cy={r}
        r={r - 1}
        fill="none"
        className="stroke"
        strokeDasharray={strokeDasharray}
      />
      <circle
        cx={r}
        cy={r}
        r={r - 1}
        fill="none"
        className="bg"
        strokeDasharray="0 1000"
      />
    </svg>
  );
};

export default class ReactJkMusicPlayer extends PureComponent {
  initPlayId = '';
  audioListRemoveAnimateTime = 100;
  NETWORK_STATE = {
    NETWORK_EMPTY: 0,
    NETWORK_IDLE: 1,
    NETWORK_LOADING: 2,
    NETWORK_NO_SOURCE: 3,
  };
  READY_SUCCESS_STATE = 4;
  state = {
    audioLists: [],
    playId: this.initPlayId,
    name: '',
    cover: '',
    wavePath: '',
    singer: '',
    artist_details: [],
    track: '',
    musicSrc: '',
    lyric: '',
    currentLyric: '',
    isMobile: IS_MOBILE,
    toggle: false,
    pause: this.props.pause,
    playing: false,
    duration: 0,
    currentTime: 0,
    isLoop: false,
    isMute: false,
    soundValue: 100,
    isDrag: false,
    currentX: 0,
    currentY: 0,
    moveX: 0,
    moveY: 0,
    isMove: false,
    loading: false,
    audioListsPanelVisible: false,
    hotKeysPanelVisible: false,
    playModelNameVisible: false,
    theme: this.darkThemeName,
    extendsContent: [],
    playMode: '',
    currentAudioVolume: 0,
    initAnimate: false,
    hotKeyInitAnimate: false,
    isInitAutoplay: false,
    isInitRemember: false,
    loadProgress: 0,
    removeId: -1,
    isNeedMobileHack: IS_MOBILE,
    audioLyricVisible: false,
    isAudioListsChange: false,
    uniqueKey: null,
    lastPlayedTrack: null,
  };
  static defaultProps = {
    audioLists: [],
    theme: 'dark',
    mode: 'full',
    playModeText: {
      order: 'order',
      orderLoop: 'orderLoop',
      singleLoop: 'singleLoop',
      shufflePlay: 'shufflePlay',
    },
    defaultPlayMode: 'order',
    defaultPosition: {
      left: 0,
      top: 0,
    },
    controllerTitle: <FaHeadphones />,
    panelTitle: 'History',
    hotKeysPanelTitle: 'Keys',
    closeText: 'close',
    openText: 'open',
    notContentText: 'no music',
    checkedText: '',
    unCheckedText: '',
    once: false, //onAudioPlay Whether the event is only triggered once
    drag: true,
    toggleMode: true, // Can switch between mini and full modes
    showMiniModeCover: true, // Whether to display cover art in mini mode
    showDownload: true,
    hasFeedback: false,
    showPlay: true,
    showReload: true,
    showPlayMode: true,
    showThemeSwitch: true,
    showLyric: false,
    playModeTipVisible: false, //Switch the playback mode on the mobile phone
    autoPlay: true,
    defaultVolume: 100,
    showProgressLoadBar: true, //Audio preloading progress
    seeked: true,
    playModeShowTime: 600, //Play mode prompt display time,
    bounds: 'body', //Movable border dragged in mini mode
    showMiniProcessBar: false, // Whether to display the progress bar in mini mode
    loadAudioErrorPlayNext: true, // When loading audio fails, whether to try to play the next song
    preload: false, // Whether to load the audio immediately after the page loads
    glassBg: false, // Whether it is ground glass effect
    remember: false, // Whether to remember the current playback status
    remove: true, // Can the music be deleted
    defaultPlayIndex: 0, // Default playback index
    emptyLyricPlaceholder: 'NO LYRIC',
    getContainer: () => document.body, // The node where the player is mounted
    autoHiddenCover: false, // Whether to hide automatically when the currently playing song has no cover
    onBeforeAudioDownload: () => {}, // Convert audio address before download, etc.
  };
  static propTypes = {
    audioLists: PropTypes.array.isRequired,
    theme: PropTypes.oneOf(['dark', 'light']),
    mode: PropTypes.oneOf(['mini', 'full']),
    defaultPlayMode: PropTypes.oneOf([
      'order',
      'orderLoop',
      'singleLoop',
      'shufflePlay',
    ]),
    drag: PropTypes.bool,
    seeked: PropTypes.bool,
    autoPlay: PropTypes.bool,
    clearPriorAudioLists: PropTypes.bool,
    autoPlayInitLoadPlayList: PropTypes.bool,
    playModeText: PropTypes.object,
    panelTitle: PropTypes.string,
    hotKeysPanelTitle: PropTypes.string,
    closeText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    openText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    notContentText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    controllerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultPosition: PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onAudioPlay: PropTypes.func,
    onAudioPause: PropTypes.func,
    onAudioEnded: PropTypes.func,
    onAudioAbort: PropTypes.func,
    onAudioVolumeChange: PropTypes.func,
    onAudioLoadError: PropTypes.func,
    onAudioProgress: PropTypes.func,
    onAudioSeeked: PropTypes.func,
    onAudioDownload: PropTypes.func,
    onAudioReload: PropTypes.func,
    onThemeChange: PropTypes.func,
    onAudioListsChange: PropTypes.func,
    onPlayModeChange: PropTypes.func,
    onModeChange: PropTypes.func,
    onAudioListsPanelChange: PropTypes.func,
    onHotKeysPanelChange: PropTypes.func,
    onAudioPlayTrackChange: PropTypes.func,
    onAudioListsDragEnd: PropTypes.func,
    onAudioLyricChange: PropTypes.func,
    showDownload: PropTypes.bool,
    hasFeedback: PropTypes.bool,
    showPlay: PropTypes.bool,
    showReload: PropTypes.bool,
    showPlayMode: PropTypes.bool,
    showThemeSwitch: PropTypes.bool,
    showMiniModeCover: PropTypes.bool,
    toggleMode: PropTypes.bool,
    once: PropTypes.bool,
    extendsContent: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.node,
      PropTypes.element,
      PropTypes.string,
    ]),
    checkedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    unCheckedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultVolume: PropTypes.number,
    playModeShowTime: PropTypes.number,
    bounds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showMiniProcessBar: PropTypes.bool,
    loadAudioErrorPlayNext: PropTypes.bool,
    preload: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['auto', 'metadata', 'none']),
    ]),
    glassBg: PropTypes.bool,
    remember: PropTypes.bool,
    remove: PropTypes.bool,
    defaultPlayIndex: PropTypes.number,
    playIndex: PropTypes.number,
    lyricClassName: PropTypes.string,
    emptyLyricPlaceholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    showLyric: PropTypes.bool,
    getContainer: PropTypes.func,
    getAudioInstance: PropTypes.func,
    onBeforeAudioDownload: PropTypes.func,
    autoHiddenCover: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    let executed = false;
    // super(executed);
    this.audio = null; //Current player
    this.lightThemeName = 'light';
    this.darkThemeName = 'dark';
    //Mode configuration
    this.toggleModeName = {
      full: 'full',
      mini: 'mini',
    };
    this.targetId = 'music-player-controller';
    this.openPanelPeriphery = 1; //The difference between the movement is considered to be a click to open the panel
    this.x = 0;
    this.y = 0;
    this.isDrag = false;

    const {
      playModeText: { order, orderLoop, singleLoop, shufflePlay },
    } = props;
    //Play mode configuration
    this.PLAY_MODE = {
      order: {
        key: 'order',
        value: order,
      },
      orderLoop: {
        key: 'orderLoop',
        value: orderLoop,
      },
      singleLoop: {
        key: 'singleLoop',
        value: singleLoop,
      },
      shufflePlay: {
        key: 'shufflePlay',
        value: shufflePlay,
      },
    };

    // Added this statement to fix browser compatibility issue
    this._PLAY_MODE_ = Object.keys(this.PLAY_MODE).map(
      key => this.PLAY_MODE[key],
    );
    this._PLAY_MODE_LENGTH_ = this._PLAY_MODE_.length;

    this.waveRef = React.createRef();
  }

  render() {
    const {
      className,
      notContentText,
      style,
      showThemeSwitch,
      panelTitle,
      hotKeysPanelTitle,
      toggleMode,
      extendsContent,
      defaultPlayMode,
      showProgressLoadBar,

      preload,
      glassBg,
      remove,
      lyricClassName,
      emptyLyricPlaceholder,
      getContainer,
      autoHiddenCover,
    } = this.props;

    let {
      toggle,
      playing,
      duration,
      loading,
      audioListsPanelVisible,
      hotKeysPanelVisible,
      pause,
      theme,
      name,
      cover,
      wavePath,
      track,
      musicSrc,
      playId,
      isMobile,
      playMode,
      playModelNameVisible,
      initAnimate,
      hotKeyInitAnimate,
      audioLists,
      removeId,
      currentLyric,
      audioLyricVisible,
      artist_details,
      uniqueKey,
    } = this.state;

    if (cover.includes('zipdj-default-thumb-300x300')) {
      cover = ZDDefaultThumb;
    }

    const audioPause = () => {
      this.waveRef.current.handlePlayRef();
    };

    const preloadState =
      preload === false || preload === 'none'
        ? {}
        : preload === true
        ? { preload: 'auto' }
        : { preload };

    const panelToggleAnimate = initAnimate
      ? { show: audioListsPanelVisible, hide: !audioListsPanelVisible }
      : { show: audioListsPanelVisible };

    const hotKeysPanelToggleAnimate = hotKeyInitAnimate
      ? { show: hotKeysPanelVisible, hide: !hotKeysPanelVisible }
      : { show: hotKeysPanelVisible };

    const _playMode_ = this.PLAY_MODE[playMode || defaultPlayMode];

    const currentPlayModeName = _playMode_['value'];

    const _duration = formatTime(duration);

    // const NoFeedbackDownloadComponent = (
    //   <a onClick={e => {this.onAudioDownload(e); }} className="zd-download-btn">
    //     <i className="zd-player-icon-download"></i>
    //   </a>
    // );
    //
    // const FeedbackDownloadComponent = (props) => (
    //   <ZDFeedbackModal
    //     dataId={props.release_id}
    //     trackFeedbackStatus="true"
    //     trackId={props.track_id}
    //     updateDownloadManagerDetails={this.updateDownloadManagerDetails.bind(this)}
    //     isDownloaded={props.is_downloaded}
    //   />
    // );

    const ThemeSwitchComponent = showThemeSwitch && (
      <span className="group theme-switch"></span>
    );

    const artistFilter = e => {
      e.preventDefault();
      let artist_id = e.currentTarget.getAttribute('data-id');
      let artist_name = e.currentTarget.getAttribute('data-artist');
      if (artist_id) {
        zdTrackArtistFilter(artist_id, artist_name, this.props);
      }
    };

    const container = getContainer() || document.body;
    document.addEventListener('keydown', this.keyEvents);

    return createPortal(
      <div
        className={classNames(
          'react-jinke-music-player-main',
          {
            'light-theme': theme === this.lightThemeName,
            'dark-theme': theme === this.darkThemeName,
          },
          className,
        )}
        style={style}
      >
        {toggle ? (
          isMobile ? undefined : (
            <div
              key="panel"
              className={classNames('music-player-panel', 'translate', {
                'glass-bg': glassBg,
              })}
            >
              <section className="panel-content" key="panel-content">
                {(!autoHiddenCover || (autoHiddenCover && cover)) && (
                  <div
                    className={classNames('img-content', 'img-rotate', {
                      'img-rotate-pause': pause || !playing || !cover,
                    })}
                    key="img-content"
                  >
                    <img src={cover} alt="" />
                  </div>
                )}
                <div
                  className="progress-bar-content"
                  key="progress-bar-content"
                >
                  <span className="audio-title zd-text-ellipsis">
                    <span className="the-name">
                      <span>{name}</span>
                      <span className="zd-text-6b7883"> ({track}) </span>
                    </span>

                    <span className="the-artist">
                      {artist_details
                        ? artist_details.map((artistItem, index) => {
                            return (
                              <span
                                className="zd-text-6b7883"
                                key={`zd-artist-item-${artistItem.artist_id}`}
                              >
                                <a
                                  className="zd-cursor"
                                  data-id={artistItem.artist_id}
                                  data-artist={artistItem.artist_name}
                                  onClick={artistFilter}
                                >
                                  <span className="zd-artist-name">
                                    {artistItem.artist_name}
                                  </span>
                                </a>
                                {index < artist_details.length - 1 ? ', ' : ''}
                              </span>
                            );
                          })
                        : []}
                    </span>
                  </span>
                  <section className="audio-main">
                    <div className="progress-bar" key="progress-bar">
                      {showProgressLoadBar ? (
                        <WavesurferPlayer
                          uniqueKey={uniqueKey}
                          musicSrc={musicSrc}
                          audioNextPlay={this.audioNextPlay}
                          audioPrevPlay={this.audioPrevPlay}
                          ref={this.waveRef}
                          setPause={this.setPause}
                          pause={this.state.pause}
                          {...this.props}
                        />
                      ) : undefined}
                    </div>
                    <span key="duration" className="duration">
                      {loading ? '--' : _duration}
                    </span>
                    {/* <span key="current-time" className="current-time">
                      {loading ? '--' : _currentTime}
                    </span> */}
                  </section>
                </div>
                <div
                  className="player-content zd-player-section-2"
                  key="player-content"
                >
                  {<DownloadButton player={this} />}
                  {ThemeSwitchComponent}

                  {extendsContent || null}

                  <span
                    className="group audio-lists-btn mx-1"
                    key="audio-lists-btn"
                    title="play lists"
                    {...(IS_MOBILE
                      ? { onTouchStart: this.openAudioListsPanel }
                      : { onClick: this.openAudioListsPanel })}
                  >
                    <span className="audio-lists-icon mouse-interactions">
                      <ZDMediaQueueIcon />
                    </span>
                  </span>

                  {/*Keyboard Shortcut*/}
                  <span
                    className="group audio-keys-btn justify-content-start"
                    key="audio-keys-btn"
                    title="Hot Keys"
                    {...(IS_MOBILE
                      ? { onTouchStart: this.openHotKeysPanel }
                      : { onClick: this.openHotKeysPanel })}
                  >
                    <span className="audio-keys-icon mouse-interactions">
                      <i className="zdv3-icon-keyboard"></i>
                    </span>
                  </span>

                  {toggleMode ? (
                    <span
                      className="group hide-panel mouse-interactions "
                      key="hide-panel-btn"
                      {...(IS_MOBILE
                        ? { onTouchStart: this.onHidePanel }
                        : { onClick: this.onHidePanel })}
                    >
                      <FaMinusSquare />
                    </span>
                  ) : undefined}
                  <span
                    className="group player-close mouse-interactions"
                    onClick={this.deleteAudioLists()}
                    title="Delete audiolists"
                  >
                    <i className="zdv3-icon-close-player"></i>
                  </span>
                </div>
              </section>
              <PlayModel
                visible={playModelNameVisible}
                value={currentPlayModeName}
              />
            </div>
          )
        ) : undefined}

        <AudioListsPanel
          setPause={this.setPause}
          onAudiopause={audioPause}
          playId={playId}
          pause={pause}
          loading={loading ? <Load /> : undefined}
          visible={audioListsPanelVisible}
          audioLists={audioLists}
          notContentText={notContentText}
          onPlay={this.audioListsPlay}
          onCancel={this.closeAudioListsPanel}
          playIcon={<AnimatePlayIcon />}
          pauseIcon={<AnimatePauseIcon />}
          closeIcon={<MdClose />}
          panelTitle={panelTitle}
          isMobile={isMobile}
          panelToggleAnimate={panelToggleAnimate}
          glassBg={glassBg}
          cover={cover}
          wavePath={wavePath}
          remove={remove}
          deleteIcon={<FaTrashAlt />}
          onDelete={this.deleteAudioLists}
          removeId={removeId}
          audioListsDragEnd={this.audioListsDragEnd}
        />

        {/* HotKeys Panel */}
        <HotKeysPanel
          loading={loading ? <Load /> : undefined}
          visible={hotKeysPanelVisible}
          notContentText={notContentText}
          onCancel={this.closeHotKeysPanel}
          closeIcon={<MdClose />}
          hotKeysPanelTitle={hotKeysPanelTitle}
          isMobile={isMobile}
          hotKeysPanelToggleAnimate={hotKeysPanelToggleAnimate}
          glassBg={glassBg}
        />

        {audioLyricVisible && (
          <Draggable>
            <div className={classNames('music-player-lyric', lyricClassName)}>
              {currentLyric || emptyLyricPlaceholder}
            </div>
          </Draggable>
        )}
        <audio
          preload="medadata"
          className="music-player-audio"
          {...preloadState}
          src={musicSrc}
          ref={node => (this.audio = node)}
        />
      </div>,
      container,
    );
  }

  toggleAudioLyric = () => {
    this.setState({
      audioLyricVisible: !this.state.audioLyricVisible,
    });
  };
  togglePlayMode = () => {
    let index = this._PLAY_MODE_.findIndex(
      ({ key }) => key === this.state.playMode,
    );
    const playMode =
      index === this._PLAY_MODE_LENGTH_ - 1
        ? this._PLAY_MODE_[0]['key']
        : this._PLAY_MODE_[++index]['key'];
    this.setState({
      playMode,
      playModelNameVisible: true,
      playModeTipVisible: true,
    });
    this.props.onPlayModeChange &&
      this.props.onPlayModeChange(this.PLAY_MODE[playMode]);

    clearTimeout(this.playModelTimer);
    this.playModelTimer = setTimeout(() => {
      this.setState({ playModelNameVisible: false, playModeTipVisible: false });
    }, this.props.playModeShowTime);
  };
  renderPlayModeIcon = playMode => {
    let IconNode = '';
    const animateName = 'react-jinke-music-player-mode-icon';
    switch (playMode) {
      case this.PLAY_MODE['order']['key']:
        IconNode = <MdViewHeadline className={animateName} />;
        break;
      case this.PLAY_MODE['orderLoop']['key']:
        IconNode = <MdRepeatOne className={animateName} />;
        break;
      case this.PLAY_MODE['singleLoop']['key']:
        IconNode = <MdRepeatOne className={animateName} />;
        break;
      case this.PLAY_MODE['shufflePlay']['key']:
        IconNode = <IoIosShuffle className={animateName} />;
        break;
      default:
        IconNode = <MdViewHeadline className={animateName} />;
    }
    return IconNode;
  };

  audioListsPlay = (playId, ignore = false, state = this.state) => {
    const { playId: currentPlayId, pause, playing, audioLists } = state;
    if (Array.isArray(audioLists) && audioLists.length === 0) {
      /*eslint-disable no-console*/
      return console.warn('Your playlist has no songs. and cannot play !');
      /*eslint-disable no-console*/
    }
    if (playId === currentPlayId && !ignore) {
      this.setState({ pause: !pause, playing: !playing });
      // PLAY
      return pause ? false : this._pauseAudio();
    }

    const {
      name,
      cover,
      wavePath,
      musicSrc,
      singer,
      track,
      lyric = '',
    } = audioLists.find(audio => audio.id === playId);

    const loadAudio = musicSrc => {
      this.setState(
        {
          name,
          cover,
          wavePath,
          musicSrc,
          singer,
          track,
          playId,
          lyric,
          currentTime: 0,
          duration: 0,
          playing: false,
          loading: true,
          loadProgress: 0,
          uniqueKey: Math.random(),
        },
        () => {
          this.audio.load();
        },
      );

      this.props.onAudioPlay && this.props.onAudioPlay(this.getBaseAudioInfo());
      this.props.onAudioPlayTrackChange &&
        this.props.onAudioPlayTrackChange(
          playId,
          audioLists,
          this.getBaseAudioInfo(),
        );
    };

    switch (typeof musicSrc) {
      case 'function':
        musicSrc().then(originMusicSrc => {
          loadAudio(originMusicSrc);
        }, this.onAudioLoadError);
        break;
      default:
        loadAudio(musicSrc);
    }
  };

  resetAudioStatus = () => {
    this.audio.pause();
    this.initPlayInfo([]);
    this.setState({
      currentTime: 0,
      duration: 0,
      loading: false,
      playing: false,
      pause: true,
      currentLyric: '',
    });
  };
  deleteAudioLists = audioId => e => {
    e.stopPropagation();
    this.props.setActiveReleaseId(null);
    this.props.setActiveTrackId(null);
    this.setState({ pause: false });

    this.executed = false;
    this.props.setActiveTracksList('');
    //If you don't pass id, delete all
    const { audioLists, playId } = this.state;
    if (audioLists.length < 1) {
      return this.hidePlayer(e) && this.resetAudioStatus();
    }

    if (!audioId) {
      this.props.onAudioListsChange &&
        this.props.onAudioListsChange('', [], {});
      this.setState({
        audioLists: [],
      });
      // return this.resetAudioStatus();
      return this.hidePlayer(e) && this.resetAudioStatus();
    }
    const currentPlayIndex = audioLists.findIndex(audio => audio.id === playId);
    const newAudioLists = [...audioLists].filter(audio => audio.id !== audioId);
    this.setState({ removeId: audioId });
    setTimeout(() => {
      this.setState(
        {
          audioLists: newAudioLists,
          removeId: -1,
        },
        () => {
          if (!newAudioLists.length) {
            return this.hidePlayer(e) && this.resetAudioStatus();
          }
          if (audioId === playId) {
            let elem = document.getElementsByClassName('zd-icon-pause');
            if (elem[0] !== undefined) {
              for (let i = 0; i < elem.length; i++) {
                elem[i].classList.add('zd-icon-repeat-track');
                elem[i].classList.remove('zd-icon-pause');
              }
            }

            this.handlePlay(
              this.PLAY_MODE['orderLoop']['key'],
              currentPlayIndex,
            );
          }
        },
      );
    }, this.audioListRemoveAnimateTime);

    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(
        playId,
        newAudioLists,
        this.getBaseAudioInfo(),
      );
  };
  openAudioListsPanel = () => {
    /* Close the Hot Keys panel */
    this.setState({ hotKeysPanelVisible: false });
    this.props.onHotKeysPanelChange && this.props.onHotKeysPanelChange(false);

    this.setState(({ audioListsPanelVisible, initAnimate }) => ({
      initAnimate: true,
      audioListsPanelVisible: !audioListsPanelVisible,
    }));
    this.props.onAudioListsPanelChange &&
      this.props.onAudioListsPanelChange(!this.state.audioListsPanelVisible);
  };

  isPlayerActive = () => {
    let musicPanel = document.getElementsByClassName('music-player-panel');
    return (
      musicPanel[0] !== undefined && musicPanel[0].classList.contains('active')
    );
  };

  hidePlayer = e => {
    let musicPanel = document.getElementsByClassName('music-player-panel');
    let elem = document.querySelectorAll(`[data-current-play-status="pause"]`);
    localStorage.removeItem('audioLists');
    if (elem[0] !== undefined) {
      for (let i = 0; i < elem.length; i++) {
        elem[i].children[0].classList.remove('zd-icon-pause');
        elem[i].children[0].classList.add('zd-icon-repeat-track');
        elem[i].setAttribute('data-current-play-status', 'play');
      }
    }

    if (
      musicPanel[0] !== undefined &&
      musicPanel[0].classList.contains('active')
    ) {
      musicPanel[0].classList.remove('active');
      document.body.classList.remove('player-active');

      this._pauseAudio();
    }
    this.closeAudioListsPanel(e);
    this.waveRef.current.destroy();
  };
  closeAudioListsPanel = e => {
    e.stopPropagation();
    this.setState({ audioListsPanelVisible: false });
    this.props.onAudioListsPanelChange &&
      this.props.onAudioListsPanelChange(false);
  };

  /* Open HotKeys Panel */
  openHotKeysPanel = () => {
    /* Close the Audio panel */
    this.setState({ audioListsPanelVisible: false });
    this.props.onAudioListsPanelChange &&
      this.props.onAudioListsPanelChange(false);

    this.setState(({ hotKeysPanelVisible, hotKeyInitAnimate }) => ({
      hotKeyInitAnimate: true,
      hotKeysPanelVisible: !hotKeysPanelVisible,
    }));
    this.props.onHotKeysPanelChange &&
      this.props.onHotKeysPanelChange(!this.state.hotKeysPanelVisible);
  };
  closeHotKeysPanel = e => {
    e.stopPropagation();
    this.setState({ hotKeysPanelVisible: false });
    this.props.onHotKeysPanelChange && this.props.onHotKeysPanelChange(false);
  };

  onAudioDownload = () => {
    if (this.state.musicSrc) {
      const baseAudioInfo = this.getBaseAudioInfo();

      this.addToDownloadManager(
        baseAudioInfo.track_id,
        baseAudioInfo.release_id,
        baseAudioInfo.xajax_type,
      );
      const onBeforeAudioDownload =
        this.props.onBeforeAudioDownload(baseAudioInfo);
      let transformedDownloadAudioInfo = {};
      if (onBeforeAudioDownload && onBeforeAudioDownload.then) {
        onBeforeAudioDownload.then(info => {
          // const { src, filename, mimeType } = info;
          transformedDownloadAudioInfo = info;
          // download(src, filename, mimeType);
        });
      } else {
        // download(this.state.musicSrc);
      }
      this.props.onAudioDownload &&
        this.props.onAudioDownload(baseAudioInfo, transformedDownloadAudioInfo);
    }
  };
  controllerMouseMove = (e, { deltaX, deltaY }) => {
    const isMove =
      Math.abs(deltaX) >= this.openPanelPeriphery ||
      Math.abs(deltaY) >= this.openPanelPeriphery;
    this.setState({
      isMove,
    });
  };
  controllerMouseUp = (e, { x, y }) => {
    if (!this.state.isMove) {
      if (this.state.isNeedMobileHack) {
        this.loadAndPlayAudio();
        this.setState({ isNeedMobileHack: false });
      }
      this.openPanel();
    }
    this.setState({ moveX: x, moveY: y });
    return false;
  };
  controllerMouseOut = e => {
    e.preventDefault();
    this.isDrag = false;
  };
  onHandleProgress = value => {
    if (typeof this.audio != 'undefined' && this.audio)
      this.audio['currentTime'] = value;
  };
  onSound = () => {
    this.setAudioVolume(this.state.currentAudioVolume);
  };
  setAudioVolume = value => {
    if (typeof this.audio != 'undefined') this.audio['volume'] = value;
    this.setState({
      currentAudioVolume: value,
      soundValue: value,
    });
  };
  stopAll = target => {
    target.stopPropagation();
    target.preventDefault();
  };
  getBoundingClientRect = ele => {
    const { left, top } = ele.getBoundingClientRect();
    return {
      left,
      top,
    };
  };

  onHidePanel = e => {
    this.setState({
      toggle: false,
      audioListsPanelVisible: false,
      hotKeysPanelVisible: false,
    });
    // // this.props.onModeChange && this.props.onModeChange(this.toggleModeName.mini)
    this.props.onModeChange &&
      this.props.onModeChange(this.toggleModeName.full);
  };
  getBaseAudioInfo() {
    const {
      playId,
      cover,
      wavePath,
      name,
      musicSrc,
      soundValue,
      lyric,
      audioLists,
    } = this.state;

    const {
      currentTime,
      duration,
      muted,
      networkState,
      readyState,
      played,
      paused,
      ended,
      startDate,
    } = this.audio;

    const currentPlayIndex = audioLists.findIndex(audio => audio.id === playId);
    const currentAudioListInfo = audioLists[currentPlayIndex] || {};

    return {
      ...currentAudioListInfo,
      cover,
      wavePath,
      name,
      musicSrc,
      volume: soundValue,
      currentTime,
      duration,
      muted,
      networkState,
      readyState,
      played,
      paused,
      ended,
      startDate,
      lyric,
    };
  }

  getCurrentRows = (btn = 'next') => {
    let selectableRows = [];
    let handleBtn = `${btn}-release-data`;
    switch (this.props.activePageName) {
      case 'new-releases':
        selectableRows = document.querySelectorAll('tr.zd-row-newrelease');
        break;
      case 'single-release-view':
        selectableRows = document.querySelectorAll('tr.zd-row-singlerelease');
        break;
      case 'single-packs-list':
        selectableRows = document.querySelectorAll(
          'tr.zd-row-singlepacks-list',
        );
        break;
      case 'packs-list':
        selectableRows = document.querySelectorAll('tr.zd-row-singlerelease');
        break;
      case 'track-search-result':
        selectableRows = document.querySelectorAll(
          'tr.zd-row-track-search-result',
        );
        break;
      case 'search-result':
        selectableRows = document.querySelectorAll('tr.zd-row-search-result');
        break;
      case 'Trending-packs':
        selectableRows = document.querySelectorAll('tr.zd-row-trending-packs');
        break;
      case 'top-downloads':
        selectableRows = document.querySelectorAll(
          'tr.zd-row-topdownload.parent-tr',
        );
        handleBtn = `${btn}-page`;
        break;
      case 'top-downloads-1':
        selectableRows = document.querySelectorAll('tr.zd-row-topdownload-1');
        handleBtn = `${btn}-td-block-1`;
        break;
      case 'top-downloads-2':
        selectableRows = document.querySelectorAll('tr.zd-row-topdownload-2');
        handleBtn = `${btn}-td-block-2`;
        break;
      case 'trending-charts-home':
        selectableRows = document.querySelectorAll('tr.zd-row-trendingcharts');
        handleBtn = `${btn}-tc-data`;
        break;
      case 'trending-charts':
        selectableRows = document.querySelectorAll(
          'tr.zd-row-trendingcharts.zd-parent-table',
        );
        handleBtn = `${btn}-tc-data`;
        break;
      case 'top-charts':
        selectableRows = document.querySelectorAll('tr.zd-row-topcharts');
        handleBtn = `${btn}-topc-data`;
        break;
      case 'download-history':
        selectableRows = document.querySelectorAll('tr.zd-row-downloadhistory');
        handleBtn = '';
        break;
    }
    return {
      selectableRows: selectableRows,
      handleBtn: handleBtn,
    };
  };

  makeSelectedRowVisible = () => {
    var selectedRow = document.querySelector('.hotkey-bg');

    var topBound;
    if (document.querySelector('.zd-head-bound') !== null) {
      topBound =
        document.querySelector('.zd-head-bound').getBoundingClientRect().top +
        document.querySelector('.zd-head-bound').getBoundingClientRect().height;
    }

    var bottomBound = document
      .querySelector('.music-player-panel')
      .getBoundingClientRect().top;
    if (bottomBound == 0) {
      bottomBound = window.innerHeight;
    }
    var selectedRowBounds = selectedRow.getBoundingClientRect();
    var topDistance = selectedRowBounds.top - topBound;
    var bottomDistance =
      bottomBound - (selectedRowBounds.top + selectedRowBounds.height);

    if (topDistance < 0 || bottomDistance < 0) {
      selectedRow.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      if (topDistance < 0) {
        window.scrollBy(0, topDistance);
      }
      if (bottomDistance < 0) {
        window.scrollBy(0, -1 * bottomDistance);
      }
    }
  };
  navigateDown = e => {
    e.preventDefault();
    let { selectableRows, handleBtn } = this.getCurrentRows('next');

    if (selectableRows.length < 1) {
      return;
    }
    var selectedRow = [];
    var selectedRowIndex = -1;
    for (let i = 0; i < selectableRows.length; i++) {
      if (selectableRows[i].classList.contains('hotkey-bg')) {
        selectedRow.push(selectableRows[i]);
        selectedRowIndex = i;
        break;
      }
    }
    for (let i = 0; i < selectableRows.length; i++) {
      selectableRows[i].classList.remove('hotkey-bg');
    }

    if (selectedRow.length < 1) {
      if (selectableRows.length > 0) {
        let currentRow = selectableRows[0];
        let currentReleaseId = parseInt(
          currentRow.getAttribute('data-release-id'),
        );
        let tempArr = [...this.props.expandedNewReleaseList];

        if (
          this.props.activePageName !== 'trending-charts' &&
          this.props.activePageName !== 'top-downloads' &&
          currentReleaseId != NaN &&
          !tempArr.includes(currentReleaseId)
        ) {
          tempArr.push(currentReleaseId);
          this.props.setExpandedNewReleaseList(tempArr);
        }

        currentRow.classList.add('hotkey-bg');
      }
      return;
    }
    var nextRowIndex = selectedRowIndex + 1;
    var nextRow = selectableRows[nextRowIndex];
    // for new release section(expand release)

    if (nextRow) {
      let releaseId = parseInt(nextRow.getAttribute('data-release-id'));
      let tempArr = [...this.props.expandedNewReleaseList];
      if (
        this.props.activePageName !== 'trending-charts' &&
        this.props.activePageName !== 'top-downloads' &&
        releaseId != NaN &&
        !tempArr.includes(releaseId)
      ) {
        tempArr.push(releaseId);
        this.props.setExpandedNewReleaseList(tempArr);
      }
      selectableRows[selectedRowIndex].classList.remove('hotkey-bg');
      nextRow.classList.add('hotkey-bg');

      if (nextRow.parentElement.parentElement.style.display === 'none') {
        let icon = nextRow.getAttribute('data-rotate-icon');
        let iconElem = document.getElementById(icon);

        if (iconElem) {
          if (!iconElem.classList.contains('up')) {
            iconElem.classList.add('up');
          }
        }

        let parent = nextRow.getAttribute('data-parent-table-elem');
        let parentElem = document.getElementById(parent);

        if (
          parentElem &&
          !parentElem.classList.contains('zd-table-hover-color')
        ) {
          parentElem.classList.add('zd-table-hover-color');
        }
        nextRow.parentElement.parentElement.style.display = 'table';
      }
      this.makeSelectedRowVisible();
    } else {
      let nextBtnEle = document.getElementById(handleBtn);
      if (nextBtnEle) {
        nextBtnEle.addEventListener('click', async () => {
          if (this.props.activePageName === 'new-releases') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else if (this.props.activePageName === 'top-downloads') {
            document
              .querySelector('.zd-td-section')
              .scrollIntoView({ block: 'start', inline: 'nearest' });
          } else if (this.props.activePageName === 'trending-charts') {
            document.getElementById('trendingcharttrack').scrollIntoView(true);
          }
        });
        nextBtnEle.click();
      }
      return;
    }

    var selectedRowId = document.querySelectorAll(
      `.currently-playing-track`,
    )[0];

    if (selectedRowId !== undefined) {
      selectedRowId = selectedRowId.getAttribute('data-tr-id');
    }
    var trackViewRow = document.querySelectorAll(
      `[data-tr-id="${selectedRowId}"]`,
    )[1];
    if (trackViewRow !== undefined) {
      trackViewRow.classList.add('hotkey-bg');
    }
    return;
  };

  navigateUp = e => {
    let { selectableRows, handleBtn } = this.getCurrentRows('prev');

    // var selectableRows = document.querySelectorAll('tr.zd-selectable-row');
    if (selectableRows.length < 1) {
      return;
    }
    e.preventDefault();
    var selectedRow = [];
    var selectedRowIndex = -1;
    for (let i = 0; i < selectableRows.length; i++) {
      if (selectableRows[i].classList.contains('hotkey-bg')) {
        selectedRow.push(selectableRows[i]);
        selectedRowIndex = i;
        break;
      }
    }
    for (let i = 0; i < selectableRows.length; i++) {
      selectableRows[i].classList.remove('hotkey-bg');
    }
    if (selectedRow.length < 1) {
      if (selectableRows.length > 0) {
        selectableRows[0].classList.add('hotkey-bg');
      }
      return;
    }
    var previousRowIndex = selectedRowIndex - 1;
    var previousRow = selectableRows[previousRowIndex];

    // for new release section(expand release)
    if (previousRow && previousRow.hasAttribute('data-release-id')) {
      let releaseId = parseInt(previousRow.getAttribute('data-release-id'));
      let tempArr = [...this.props.expandedNewReleaseList];
      if (
        this.props.activePageName !== 'trending-charts' &&
        this.props.activePageName !== 'top-downloads' &&
        releaseId != NaN &&
        !tempArr.includes(releaseId)
      ) {
        tempArr.push(releaseId);
        this.props.setExpandedNewReleaseList(tempArr);
      }
    }

    if (previousRow) {
      selectableRows[selectedRowIndex].classList.remove('hotkey-bg');
      previousRow.classList.add('hotkey-bg');
      if (previousRow.parentElement.parentElement.style.display === 'none') {
        let icon = previousRow.getAttribute('data-rotate-icon');
        let iconElem = document.getElementById(icon);

        if (iconElem) {
          if (!iconElem.classList.contains('up')) {
            iconElem.classList.add('up');
          }
        }

        let parent = previousRow.getAttribute('data-parent-table-elem');
        let parentElem = document.getElementById(parent);

        if (
          parentElem &&
          !parentElem.classList.contains('zd-table-hover-color')
        ) {
          parentElem.classList.add('zd-table-hover-color');
        }
        previousRow.parentElement.parentElement.style.display = 'table';
      }
      this.makeSelectedRowVisible();
    } else {
      let prevBtnEle = document.getElementById(handleBtn);
      if (prevBtnEle) prevBtnEle.click();
      return;
    }
    var selectedRowId = document.querySelectorAll(`.hotkey-bg`)[0];
    if (selectedRowId !== undefined) {
      selectedRowId = selectedRowId.getAttribute('data-tr-id');
    }
    var trackViewRow = document.querySelectorAll(
      `[data-tr-id="${selectedRowId}"]`,
    )[1];
    if (trackViewRow !== undefined) {
      trackViewRow.classList.add('hotkey-bg');
    }
    return;
  };
  playTrackAfterRender = (btn = 'first') => {
    let newPageRows = [];
    switch (this.props.activePageName) {
      case 'new-releases':
        newPageRows = document.querySelectorAll('tr.zd-row-parent-newrelease');
        break;
      case 'top-downloads':
        newPageRows = document.querySelectorAll('tr.zd-row-topdownload');
        break;
      case 'top-downloads-1':
        newPageRows = document.querySelectorAll('tr.zd-row-topdownload-1');
        break;
      case 'top-downloads-2':
        newPageRows = document.querySelectorAll('tr.zd-row-topdownload-2');
        break;
      case 'trending-charts':
        newPageRows = document.querySelectorAll(
          'tr.zd-row-trendingcharts.zd-parent-table',
        );
        break;
      case 'top-charts':
        newPageRows = document.querySelectorAll('tr.zd-row-topcharts');
        break;
    }
    if (newPageRows.length > 0) {
      let id;
      if (btn === 'first') {
        id = newPageRows[0].getAttribute('data-tr-id');
      } else {
        let lastTrack = newPageRows[newPageRows.length - 1];
        id = lastTrack.getAttribute('data-tr-id');
      }
      if (id) {
        let anchorTag = document.getElementById(`play-link-${id}`);
        anchorTag.click();
        return;
      }
    }
  };

  keyEvents = async e => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    // e.ctrlKey = true/false
    // e.metaKey = true/false
    if (
      e.target.nodeName.toLowerCase() === 'input' ||
      e.target.nodeName.toLowerCase() === 'textarea'
    )
      return;
    var playButtons = document.querySelectorAll('.zd-item-play');
    if (playButtons.length < 1) {
      return;
    }

    if (
      (e.ctrlKey && e.key === 'ArrowRight') ||
      (e.metaKey && e.key === 'Alt' && e.key === 'ArrowRight')
    ) {
      e.preventDefault();

      if (!this.waveRef.current) return;
      this.waveRef.current.handleForwardSeekRef();
      return;
    }
    if (
      (e.ctrlKey && e.key === 'ArrowLeft') ||
      (e.metaKey && e.key === 'Alt' && e.key === 'ArrowLeft')
    ) {
      e.preventDefault();

      if (!this.waveRef.current) return;
      this.waveRef.current.handleBackwardSeekRef();
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      return this.navigateDown(e);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      return this.navigateUp(e);
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      this.navigateDown(e);
      let nextPlayBtn = document.querySelectorAll('.hotkey-bg .zd-item-play');
      if (nextPlayBtn.length > 0) {
        nextPlayBtn[0].click();
      } else {
        // Keep a 1sec delay to allow the next page to render
        await delay(1000);
        this.playTrackAfterRender('first');
      }
      return;
    }

    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      this.navigateUp(e);
      var previousPlayBtn = document.querySelectorAll(
        '.hotkey-bg .zdv3-icon-play',
      );
      if (previousPlayBtn.length > 0) {
        previousPlayBtn[0].click();
      } else {
        // Keep a 1sec delay to allow the prev page to render
        await delay(1000);
        this.playTrackAfterRender('last');
      }
      return;
    }

    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      // this.state.isMute ? this.onSound() : this.onMute();
      if (this.waveRef.current) {
        this.waveRef.current.handleMuteRef();
      }
      return;
    }
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      var currentPlayBtn = document.querySelectorAll(
        '.hotkey-bg .zdv3-icon-play',
      );
      if (currentPlayBtn.length > 0) {
        currentPlayBtn[0].click();
      } else {
        if (this.waveRef.current) {
          this.waveRef.current.handlePlayRef();
        }
      }
      return;
    }
    // if (e.key === 'Enter') {
    //   e.preventDefault();
    //   var currentPlayBtn = document.querySelectorAll(
    //     '.hotkey-bg .zdv3-icon-play',
    //   );
    //   if (currentPlayBtn.length > 0) {
    //     currentPlayBtn[0].click();
    //   }
    //   return;
    // }

    if (e.key === 'Tab') {
      e.preventDefault();
      var currentDownloadBtn = document.querySelectorAll(
        '.hotkey-bg .zd-download-btn',
      );
      if (currentDownloadBtn.length > 0) {
        currentDownloadBtn[0].click();
      }
      return;
    }
    if (e.key === 'q' || e.key === 'Q') {
      e.preventDefault();
      var currentQueueBtn = document.querySelectorAll(
        '.currently-playing-track .zd-item-plus',
      );
      if (currentQueueBtn.length > 0) {
        currentQueueBtn[0].click();
        this.setState({
          audioListsPanelVisible: true,
        });
      }
      return;
    }
  };

  //Play
  // onPlay = () => {
  //   if (this.state.audioLists.length >= 1) {
  //     if (this.lyric !== undefined) {
  //       this.lyric.togglePlay();
  //     }
  //     if (this.state.playing) {
  //       this._pauseAudio();
  //     } else {
  //       this.loadAndPlayAudio();
  //     }
  //   }
  // };

  canPlay = () => {
    this.setAudioLength();
    this.setState({
      loading: false,
      playing: false,
      isAudioListsChange: false,
    });

    if (this.state.isInitAutoplay) {
      this.loadAndPlayAudio();
    }
  };

  pauseAudioChangeIcons = () => {
    var track_info = this.getBaseAudioInfo();
    var track_id = track_info['track_id'];
    var trackButtons = document.querySelectorAll(
      '.zd-item-play[data-id="' + track_id + '"]',
    );
    if (trackButtons.length > 0) {
      for (var i = 0; i < trackButtons.length; i++) {
        var trackButton = trackButtons[i];
        var trackIcon = trackButton.querySelector('.zd-icon');
        trackButton.setAttribute('data-current-play-status', 'play');
        if (trackIcon) {
          trackIcon.classList.remove('zd-icon-play');
          trackIcon.classList.add('zd-icon-repeat-track');
        }
      }
    }
  };
  // playAudioChangeIcons = () => {
  //   let track_info = this.getBaseAudioInfo();
  //   let track_id = track_info['track_id'];
  //   let playingTrackButtons = document.querySelectorAll(
  //     '.zd-item-play[data-play-status="pause"]',
  //   );
  //   if (playingTrackButtons.length > 0) {
  //     for (let i = 0; i < playingTrackButtons.length; i++) {
  //       let trackButton = playingTrackButtons[i];
  //       let trackIcon = trackButton.querySelector('.zd-icon');
  //       trackButton.setAttribute('data-play-status', 'play');
  //       trackIcon.classList.remove('zd-icon-pause');
  //       trackIcon.classList.remove('zd-icon-play');
  //       trackIcon.classList.add('zd-icon-repeat-track');
  //     }
  //   }
  //   let trackButtons = document.querySelectorAll(
  //     '.zd-item-play[data-id="' + track_id + '"]',
  //   );
  //   if (trackButtons.length > 0) {
  //     for (let i = 0; i < trackButtons.length; i++) {
  //       let trackButton = trackButtons[i];
  //       let trackIcon = trackButton.querySelector('.zd-icon');
  //       trackButton.setAttribute('data-play-status', 'pause');
  //       if (trackIcon) {
  //         trackIcon.classList.remove('zd-icon-play');
  //         trackIcon.classList.remove('zd-icon-repeat-track');
  //         trackIcon.classList.add('zd-icon-pause');
  //         // Make the row active
  //         if (
  //           track_info.release_id &&
  //           track_info.track_id &&
  //           !this.props.activeTrackId
  //         ) {
  //           this.props.setActiveReleaseId(track_info.release_id);
  //           this.props.setActiveTrackId(track_info.track_id);
  //         } else if (
  //           track_info.release_id &&
  //           track_info.track_id &&
  //           this.props.activeTrackId != '' &&
  //           this.props.activeTrackId != track_info.track_id
  //         ) {
  //           this.props.setActiveReleaseId(track_info.release_id);
  //           this.props.setActiveTrackId(track_info.track_id);
  //         }
  //       }
  //     }
  //   }
  // };
  playAudioChangeIcons = () => {
    let track_info = this.getBaseAudioInfo();

    let currentTrackId = localStorage.getItem('currentTrackId');
    if (!this.props.playedTracks.includes(currentTrackId)) {
      this.props.setPlayedTracks(currentTrackId);
    }
    // alert(currentTrackId);
    var trackButtons = document.querySelectorAll(
      '.zd-item-play[data-id="' + currentTrackId + '"]',
    );
    for (var i = 0; i < trackButtons.length; i++) {
      var trackButton = trackButtons[i];
      trackButton.setAttribute('data-play-status', 'pause');
    }
    if (
      track_info.release_id &&
      track_info.track_id &&
      !this.props.activeTrackId &&
      currentTrackId == track_info.track_id
    ) {
      this.props.setActiveReleaseId(track_info.release_id);
      this.props.setActiveTrackId(track_info.track_id);
    }
  };

  _pauseAudio = () => {
    this.audio.pause();
    this.setState({ playing: false, pause: true }, () => {
      this.lyric && this.lyric.stop();
    });
  };
  onPauseAudio = () => {
    this.setState({ lastPlayedTrack: this.props.activeTrackId });
    this.props.setActiveReleaseId(null);
    this.props.setActiveTrackId(null);
    this.lyric && this.lyric.stop();
    this.props.onAudioPause && this.props.onAudioPause(this.getBaseAudioInfo());
    this.pauseAudioChangeIcons();
  };
  loadAndPlayAudio = () => {
    // alert('line 1654'); Called twice
    const { autoPlay, remember } = this.props;
    const { isInitAutoplay, isInitRemember, loadProgress } = this.state;
    const { networkState } = 1; //default set 1
    const maxLoadProgress = 100;
    this.setState({ loading: true });
    if (loadProgress < maxLoadProgress) {
      this.setState({ loadProgress: loadProgress + 1 });
    }

    if (networkState !== this.NETWORK_STATE.NETWORK_NO_SOURCE) {
      const { pause } = this.getLastPlayStatus();
      const isLastPause = remember && !isInitRemember && pause;
      const canPlay = isInitAutoplay || autoPlay === true;
      this.setState(
        {
          playing: remember ? !isLastPause : canPlay,
          loading: false,
          pause: remember ? isLastPause : !canPlay,
          loadProgress: maxLoadProgress,
        },
        () => {
          if (remember ? !isLastPause : canPlay) {
          }
          this.setState({ isInitAutoplay: true, isInitRemember: true });
        },
      );
      this.playAudioChangeIcons();
    } else {
      this.onAudioLoadError();
    }
  };
  setAudioLength = () => {
    this.setState({
      duration: this.audio.duration,
    });
  };
  onAudioLoadError = e => {
    const { playMode, audioLists, playId, musicSrc } = this.state;

    const { loadAudioErrorPlayNext } = this.props;
    if (loadAudioErrorPlayNext && playId < audioLists.length - 1) {
      this.handlePlay(playMode);
    }

    if (musicSrc) {
      const info = this.getBaseAudioInfo();
      this.props.onAudioLoadError &&
        this.props.onAudioLoadError({
          ...e,
          audioInfo: info,
          errMsg: this.audio.error || null,
        });
    }
  };

  moveToNext = async (nextTr, selectableRows, selectedRowIndex) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    if (nextTr) {
      selectableRows[selectedRowIndex].classList.remove('hotkey-bg');
      await delay(500);
      nextTr.classList.add('hotkey-bg');
      let releaseId = parseInt(nextTr.getAttribute('data-release-id'));
      let tempArr = [...this.props.expandedNewReleaseList];
      if (
        this.props.activePageName !== 'trending-charts' &&
        this.props.activePageName !== 'top-downloads' &&
        releaseId != NaN &&
        !tempArr.includes(releaseId)
      ) {
        tempArr.push(releaseId);
        this.props.setExpandedNewReleaseList(tempArr);
      }
      let playTr = document.querySelectorAll('.hotkey-bg .zd-item-play')[0];
      if (playTr) playTr.click();
      return;
    }
  };

  handlePlay = async (playMode, idx, isNext = true, e) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    let { selectableRows, handleBtn } = this.getCurrentRows('next');
    let IconNode;
    let { playId, audioLists } = this.state;
    console.log(audioLists)
    const audioListsLen = audioLists.length;
    const currentPlayIndex = audioLists.findIndex(audio => audio.id === playId);
    if (currentPlayIndex > -1) {
      if (selectableRows.length < 1) {
        if (
          isNext &&
          audioLists.length > 0 &&
          audioLists[currentPlayIndex + 1]
        ) {
          this.audioListsPlay(audioLists[currentPlayIndex + 1].id);
        } else if (
          !isNext &&
          audioLists.length > 0 &&
          currentPlayIndex - 1 >= 0
        ) {
          this.audioListsPlay(audioLists[currentPlayIndex - 1].id);
        }
        return;
      }
      let selectedRow = [];
      let selectedRowIndex = -1;
      for (let i = 0; i < selectableRows.length; i++) {
        if (selectableRows[i].classList.contains('currently-playing-track')) {
          selectedRow.push(selectableRows[i]);
          selectedRowIndex = i;
          break;
        }
      }
      let IconNode;
      let nextTr;
      // Handle play next track
      if (
        isNext &&
        selectableRows[selectedRowIndex] &&
        selectableRows[selectedRowIndex + 1]
      ) {
        nextTr = selectableRows[selectedRowIndex + 1];
        this.moveToNext(nextTr, selectableRows, selectedRowIndex);
        return;
      }
      // Handle play prev track
      else if (
        !isNext &&
        selectableRows[selectedRowIndex] &&
        selectableRows[selectedRowIndex - 1]
      ) {
        nextTr = selectableRows[selectedRowIndex - 1];
        this.moveToNext(nextTr, selectableRows, selectedRowIndex);
        return;
      }

      //Render next page and play the first track
      else if (
        isNext &&
        selectableRows[selectedRowIndex] &&
        !selectableRows[selectedRowIndex + 1]
      ) {
        // Keep a 1sec delay to allow the next page to render
        await delay(1000);

        // Click the next button to render the next page
        let nextBtnEle = document.getElementById(handleBtn);

        if (nextBtnEle) {
          nextBtnEle.addEventListener('click', async () => {
            if (this.props.activePageName === 'new-releases') {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            } else if (this.props.activePageName === 'top-downloads') {
              document
                .querySelector('.zd-td-section')
                .scrollIntoView({ block: 'start', inline: 'nearest' });
            } else if (this.props.activePageName === 'trending-charts') {
              document
                .getElementById('trendingcharttrack')
                .scrollIntoView(true);
            }
          });
          nextBtnEle.click();
        }

        this.playTrackAfterRender('first');
        return;
      }
      //Render prev page and play the last track
      else if (!isNext && selectableRows[selectedRowIndex]) {
        let { handleBtn } = this.getCurrentRows('prev');

        // Keep a 1sec delay to allow the next page to render
        await delay(1000);

        // Click the next button to render the next page
        let nextBtnEle = document.getElementById(handleBtn);
        if (nextBtnEle) {
          nextBtnEle.addEventListener('click', async () => {
            if (this.props.activePageName === 'new-releases') {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            } else if (this.props.activePageName === 'top-downloads') {
              document
                .querySelector('.zd-td-section')
                .scrollIntoView({ block: 'start', inline: 'nearest' });
            } else if (this.props.activePageName === 'trending-charts') {
              document
                .getElementById('trendingcharttrack')
                .scrollIntoView(true);
            }
          });
          nextBtnEle.click();
        }

        this.playTrackAfterRender('last');
        return;
      } else {
        this.audioListsPlay(audioLists[0].id);
      }
      for (let i = 0; i < selectedRow.length; i++) {
        selectedRow[i].classList.remove('currently-playing-track');
      }
    }
    switch (playMode) {
      case this.PLAY_MODE['order']['key']:
        IconNode = <MdViewHeadline />;

        if (currentPlayIndex === audioListsLen - 1) {
          return this._pauseAudio();
        }
        this.audioListsPlay(
          isNext
            ? audioLists[currentPlayIndex + 1].id
            : audioLists[currentPlayIndex - 1].id,
        );

        break;

      case this.PLAY_MODE['orderLoop']['key']:
        IconNode = <MdRepeatOne />;
        if (isNext) {
          if (currentPlayIndex + idx === audioListsLen - 1) {
            return this.audioListsPlay(audioLists[0].id);
          }
          if (currentPlayIndex === audioListsLen - 1) {
            return this.audioListsPlay(audioLists[0].id);
          }
          this.audioListsPlay(audioLists[currentPlayIndex + 1 + idx].id);
        } else {
          if (currentPlayIndex === 0) {
            return this.audioListsPlay(audioLists[audioListsLen - 1].id);
          }
          this.audioListsPlay(audioLists[currentPlayIndex - 1].id);
        }
        break;

      case this.PLAY_MODE['singleLoop']['key']:
        IconNode = <MdRepeatOne />;
        this.audio.currentTime = 0;
        this.audioListsPlay(playId, true);
        break;

      case this.PLAY_MODE['shufflePlay']['key']:
        {
          IconNode = <IoIosShuffle />;
          let randomIndex = createRandomNum(0, audioListsLen - 1);
          const randomPlayId = (audioLists[randomIndex] || {}).id;
          this.audioListsPlay(randomPlayId, true);
        }
        break;
      default:
        IconNode = <MdViewHeadline />;
    }
  };
  audioEnd = () => {
    this.props.onAudioEnded && this.props.onAudioEnded(this.getBaseAudioInfo());
    this.handlePlay(this.state.playMode);
    let audioInfo = this.getBaseAudioInfo();
  };

  audioPrevAndNextBasePlayHandle = (isNext = true) => {
    const { playMode } = this.state;
    let _playMode = '';
    switch (playMode) {
      case this.PLAY_MODE['shufflePlay']['key']:
        _playMode = playMode;
        break;
      default:
        _playMode = this.PLAY_MODE['orderLoop']['key'];
        break;
    }
    this.handlePlay(_playMode, 0, isNext);
  };

  setPause = val => {
    this.setState({
      pause: val,
    });
    //add function here to update track play status and color
    if (val === true) {
      this.onPauseAudio();
    } else {
      this.playAudioChangeIcons();
    }
  };
  audioPrevPlay = () => {
    this.audioPrevAndNextBasePlayHandle(false);
  };
  audioNextPlay = () => {
    this.audioPrevAndNextBasePlayHandle(true);
  };
  audioTimeUpdate = async () => {
    const currentTime = this.audio && this.audio.currentTime;
    this.setState({ currentTime });
    if (this.props.remember) {
      this.saveLastPlayStatus();
    }
    this.props.onAudioProgress &&
      this.props.onAudioProgress(this.getBaseAudioInfo());
  };
  audioSoundChange = value => {
    this.setAudioVolume(value);
  };
  onAudioVolumeChange = () => {
    this.setState({ isMute: this.audio.volume <= 0 });
    this.props.onAudioVolumeChange &&
      this.props.onAudioVolumeChange(this.audio.volume);
  };
  onAudioPlay = () => {
    this.setState({ playing: true, loading: false }, this.initLyricParser);
    this.props.onAudioPlay && this.props.onAudioPlay(this.getBaseAudioInfo());
  };
  onAudioSeeked = () => {
    if (this.state.audioLists.length >= 1) {
      if (this.state.playing) {
        this.loadAndPlayAudio();
        setTimeout(() => {
          this.setState({ playing: true });
          this.lyric.seek(this.getLyricPlayTime());
        });
      }
      this.props.onAudioSeeked &&
        this.props.onAudioSeeked(this.getBaseAudioInfo());
    }
  };
  onMute = () => {
    this.setState(
      {
        isMute: !this.state.isMute,
        soundValue: !this.state.isMute ? 0 : 100,
        currentAudioVolume: this.audio.volume,
      },
      () => {
        this.audio.volume = this.state.isMute ? 0 : 100;
      },
    );
  };
  //Load interrupted
  onAudioAbort = e => {
    const { audioLists } = this.state;
    const audioInfo = this.getBaseAudioInfo();
    const mergedError = Object.assign({}, e, audioInfo);
    this.props.onAudioAbort && this.props.onAudioAbort(mergedError);
    if (audioLists.length) {
      this.audio.pause();
    }
  };
  //Switch player mode
  toggleMode = mode => {
    if (mode === this.toggleModeName['full']) {
      this.setState({ toggle: true });
    }
  };
  //List drag sort
  audioListsDragEnd = (fromIndex, toIndex) => {
    const { playId, audioLists } = this.state;
    const _audioLists = [...audioLists];
    const item = _audioLists.splice(fromIndex, 1)[0];
    _audioLists.splice(toIndex, 0, item);

    //If the song being played is dragged, the playing Id is equal to the index after dragging
    let _playId = fromIndex === playId ? toIndex : playId;

    this.setState({ audioLists: _audioLists, playId: _playId });

    this.props.onAudioListsDragEnd &&
      this.props.onAudioListsDragEnd(fromIndex, toIndex);

    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(
        _playId,
        _audioLists,
        this.getBaseAudioInfo(),
      );
  };
  saveLastPlayStatus = () => {
    const {
      currentTime,
      playId,
      duration,
      theme,
      soundValue,
      playMode,
      name,
      cover,
      wavePath,
      singer,
      track,
      musicSrc,
      pause,
      artist_details,
    } = this.state;
    const lastPlayStatus = JSON.stringify({
      currentTime,
      playId,
      duration,
      theme,
      playMode,
      soundValue,
      name,
      cover,
      wavePath,
      singer,
      track,
      musicSrc,
      pause,
      artist_details,
    });
    localStorage.setItem('lastPlayStatus', lastPlayStatus);
  };
  getLastPlayStatus = () => {
    const { theme, defaultPlayMode } = this.props;

    let status = {
      currentTime: 0,
      duration: 0,
      playMode: defaultPlayMode,
      name: '',
      cover: '',
      wavePath: '',
      singer: '',
      track: '',
      musicSrc: '',
      lyric: '',
      playId: this.getDefaultPlayId(),
      theme,
      pause: false,
      artist_details: [],
    };
    try {
      return JSON.parse(localStorage.getItem('lastPlayStatus')) || status;
    } catch (error) {
      return status;
    }
  };
  mockAutoPlayForMobile = () => {
    if (this.props.autoPlay && !this.state.playing && this.state.pause) {
      this.audio.load();
      this.audio.pause();
      // PLAY
    }
  };
  bindMobileAutoPlayEvents = () => {
    document.addEventListener(
      'touchstart',
      () => {
        this.mockAutoPlayForMobile();
      },
      { once: true },
    );
    //Listen to WeChat ready event
    document.addEventListener('WeixinJSBridgeReady', () => {
      this.mockAutoPlayForMobile();
    });
  };
  bindSafariAutoPlayEvents = () => {
    document.addEventListener(
      'click',
      () => {
        this.mockAutoPlayForMobile();
      },
      { once: true },
    );
  };
  unBindEvents = (...options) => {
    this.bindEvents(...options);
  };
  /**
   * Bind audio tag events
   */
  bindEvents = (
    target = this.audio,
    eventsNames = {
      waiting: this.loadAndPlayAudio,
      canplay: this.canPlay,
      error: this.onAudioLoadError,
      ended: this.audioEnd,
      seeked: this.onAudioSeeked,
      pause: this.onPauseAudio,
      play: this.onAudioPlay,
      timeupdate: this.audioTimeUpdate,
      volumechange: this.onAudioVolumeChange,
      stalled: this.onAudioLoadError, // When the browser tries to obtain media data, but the data is not available
      abort: this.onAudioAbort,
    },
    bind = true,
  ) => {
    const { once } = this.props;
    for (let name in eventsNames) {
      const _events = eventsNames[name];
      bind
        ? target.addEventListener(name, _events, {
            once: !!(once && name === 'play'),
          })
        : target.removeEventListener(name, _events);
    }
  };
  getPlayInfo = (audioLists = []) => {
    if (audioLists.length < 1) {
      this._pauseAudio();
      return this.waveRef.current.handlePlayRef();
    }
    const newAudioLists = audioLists.filter(audio => !audio['id']);
    let lastAudioLists = [];
    if (
      this.props.activePageName != 'zipdj-packs' &&
      this.props.activePageName != 'trending-packs'
    ) {
      lastAudioLists = audioLists.filter(audio => audio['id']);
    }
    let newIdx = lastAudioLists.length;
    const _audioLists = [
      ...newAudioLists.map(info => {
        return {
          ...info,

          idx: newIdx++,
          id: uuId(),
        };
      }),
      ...lastAudioLists,
    ];
    localStorage.setItem('audioLists', JSON.stringify(_audioLists));

    let elem = document.querySelectorAll('[data-current-play-status="pause"]');
    let previousId;
    let playIndex;
    if (elem[0] !== undefined) {
      previousId = elem[0].getAttribute('data-id');
    }
    playIndex = _audioLists.length - 1;
    for (let i = 0; i < _audioLists.length; i++) {
      if (_audioLists[i].track_id === previousId) {
        playIndex = i;
      }
    }
    let playId =
      _audioLists[playIndex] !== undefined && elem[0] !== undefined
        ? _audioLists[playIndex].id
        : '';
    if (_audioLists.length && _audioLists[0]._status === 'queue') {
      playId = _audioLists[0].id;
    }
    if (playId === '') {
      playId = this.state.playId;
    }
    const {
      name = '',
      cover = '',
      wavePath = '',
      singer = '',
      track = '',
      musicSrc = '',
      lyric = '',
      artist_details = [],
      uniqueKey = null,
    } = _audioLists.find(({ id }) => id === playId) || {};
    if (
      playId === this.state.playId &&
      _audioLists.length === this.state.audioLists.length
    ) {
      // this.onPlay();
      if (this.waveRef.current) {
        this.waveRef.current.handlePlayRef();
      }
    } else if (
      newAudioLists[newAudioLists.length - 1] !== undefined &&
      newAudioLists[newAudioLists.length - 1]._status === 'queue' &&
      _audioLists.length > 1
    ) {
      if (!this.state.playing) {
        this.audio.pause();
      }
    } else if (
      document.querySelectorAll(
        `[data-id="${_audioLists[playIndex].track_id}"]`,
      )[0] === undefined
    ) {
    } else if (_audioLists.length === 1 && _audioLists[0]._status === 'queue') {
      this._pauseAudio();
    } else {
      // called once on play
      this.loadAndPlayAudio();
    }
    return {
      name,
      cover,
      wavePath,
      singer,
      track,
      musicSrc,
      lyric,
      audioLists: _audioLists,
      playId,
      artist_details,
      uniqueKey,
    };
  };

  initPlayInfo = (audioLists, cb) => {
    if (audioLists.length < 1) {
      this._pauseAudio();
      return this.waveRef.current.handlePlayRef();
    }
    const info = this.getPlayInfo(audioLists);

    switch (typeof info.musicSrc) {
      case 'function':
        info.musicSrc().then(originMusicSrc => {
          this.setState({ ...info, musicSrc: originMusicSrc }, cb);
        }, this.onAudioLoadError);
        break;
      default:
        this.setState(info, cb);
    }
  };

  listenerIsMobile = ({ matches }) => {
    this.setState({
      isMobile: false,
    });
  };
  addMobileListener = () => {
    this.media = window.matchMedia(
      '(max-width: 768px) and (orientation : portrait)',
    );
    this.media.addListener(this.listenerIsMobile);
  };
  setDefaultAudioVolume = () => {
    const { defaultVolume, remember } = this.props;
    this.defaultVolume = Math.max(0, Math.min(defaultVolume, 100)) / 100;
    const { soundValue = this.defaultVolume } = this.getLastPlayStatus();
    this.setAudioVolume(remember ? soundValue : this.defaultVolume);
  };
  getDefaultPlayId = (audioLists = this.props.audioLists) => {
    const playIndex = Math.max(
      0,
      Math.min(audioLists.length, this.props.defaultPlayIndex),
    );
    return audioLists[playIndex] && audioLists[playIndex].id;
  };
  getLyricPlayTime = () => {
    const [m, s] = formatTime(this.audio.currentTime).split(':');
    return m * 1000 + s * 10;
  };

  updateMode = mode => {
    if (
      mode &&
      mode !== this.props.mode &&
      (mode === this.toggleModeName.full || mode === this.toggleModeName.mini)
    ) {
      this.setState({ toggle: mode === this.toggleModeName.full });
      this.props.onModeChange && this.props.onModeChange(mode);
    }
  };

  updateAudioLists = audioLists => {
    let activePageName = localStorage.getItem('activePageName');
    let pack_details = JSON.parse(localStorage.getItem('pack_details'));
    let newAudioLists = [];

    if(activePageName == 'single-packs-list'){

      let singlePackAudioList = [
          ...this.state.audioLists,
          ...audioLists.filter(
            audio =>
              this.state.audioLists.findIndex(
                v => v.musicSrc === audio.musicSrc,
              ) === -1,
          ),
        ];
      
      if((pack_details.pack_id == this.props.activePackId)){
        singlePackAudioList = audioLists;
      }

      newAudioLists = singlePackAudioList

    }else if (
      this.props.activePageName != 'zipdj-packs' &&
      this.props.activePageName != 'trending-packs' &&
      activePageName != 'zipdj-packs'
    ) {
      newAudioLists = [
        ...this.state.audioLists,
        ...audioLists.filter(
          audio =>
            this.state.audioLists.findIndex(
              v => v.musicSrc === audio.musicSrc,
            ) === -1,
        ),
      ];
    } else {
      newAudioLists = [
        ...audioLists.filter(
          audio =>
            this.state.audioLists.findIndex(
              v => v.musicSrc === audio.musicSrc,
            ) === -1,
        ),
      ];
    }



    this.initPlayInfo(newAudioLists);
    this.bindEvents(this.audio);
    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(
        this.state.playId,
        audioLists,
        this.getBaseAudioInfo(),
      );
  };

  loadNewAudioLists = (
    audioLists,
    {
      remember,
      defaultPlayIndex,
      defaultPlayMode,
      theme,
      autoPlayInitLoadPlayList,
    },
  ) => {
    if (audioLists.length >= 1) {
      const info = this.getPlayInfo(audioLists);
      const lastPlayStatus = remember
        ? this.getLastPlayStatus(defaultPlayIndex)
        : { playMode: defaultPlayMode, theme };

      switch (typeof info.musicSrc) {
        case 'function':
          info.musicSrc().then(val => {
            this.setState({
              ...info,
              musicSrc: val,
              isInitAutoplay: autoPlayInitLoadPlayList,
              ...lastPlayStatus,
            });
          }, this.onAudioLoadError);
          break;
        default:
          this.setState({
            ...info,
            isInitAutoplay: autoPlayInitLoadPlayList,
            ...lastPlayStatus,
          });
      }
    }
  };

  changeAudioLists = audioLists => {
    this.resetAudioStatus();
    this.loadNewAudioLists(audioLists, this.props);
    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(
        this.state.playId,
        audioLists,
        this.getBaseAudioInfo(),
      );
    this.setState({ isAudioListsChange: true });
  };

  resetPlayList = state => {
    const _playIndex = Math.max(
      0,
      Math.min(state.audioLists.length, this.props.defaultPlayIndex),
    );

    const currentPlay = state.audioLists[_playIndex];
    if (currentPlay && currentPlay.id) {
      this.audioListsPlay(currentPlay.id, true, state);
    }
  };

  updatePlayIndex = playIndex => {
    const currentPlayIndex = this.state.audioLists.findIndex(
      audio => audio.id === this.state.playId,
    );
    if (currentPlayIndex !== playIndex) {
      const _playIndex = Math.max(
        0,
        Math.min(this.state.audioLists.length, playIndex),
      );
      const currentPlay = this.state.audioLists[_playIndex];
      if (currentPlay && currentPlay.id) {
        this.audioListsPlay(currentPlay.id, true);
      }
    }
  };

  onGetAudioInstance = () => {
    this.props.getAudioInstance && this.props.getAudioInstance(this.audio);
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    audioLists,
    playIndex,
    theme,
    mode,
    clearPriorAudioLists,
  }) {
    if (!arrayEqual(audioLists)(this.props.audioLists)) {
      if (clearPriorAudioLists) {
        this.changeAudioLists(audioLists);
      } else {
        this.updateAudioLists(audioLists);
      }
    } else {
      if (playIndex === undefined) {
        this.updatePlayIndex(playIndex);
      } else {
        this.updatePlayIndex(playIndex);
      }
    }
  }
  addToDownloadManager = (track, release, xajaxType) => {
    let self = this;

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/add-track-single',
      xSessionToken: loginInfo.token,
    };

    let track_id = track;
    let release_id = release;
    let xajax = xajaxType ? xajaxType : 'AddToCart';

    let ajax_type = 'home';

    let url = api.baseUrl;
    const post_data = {
      xajax: xajax,
      xajaxargs: [track_id, release_id, ajax_type],
    };

    axios
      .post(url, post_data, {
        headers: { 'X-Session-Token': api.xSessionToken },
      })
      .then(function (response) {
        const data = response.data;
        if (data.error === false) {
          if (document.getElementById('download_row_icon_' + track_id)) {
            document.getElementById(
              'download_row_icon_' + track_id,
            ).style.display = 'none';
          }

          if (document.getElementById('track_download_row_icon_' + track_id)) {
            document.getElementById(
              'track_download_row_icon_' + track_id,
            ).style.display = 'none';
          }

          var el = document.body.getElementsByClassName(
            'zd-download-manager-btn',
          );
          for (var i = 0; i < el.length; i++) {
            el[i].classList.add('zd-animate');
          }
          if (data.data) {
            self.downloadManagerGlobalData(data.data);
          }

          const timer = setTimeout(() => {
            var el = document.body.getElementsByClassName(
              'zd-download-manager-btn',
            );
            for (var i = 0; i < el.length; i++) {
              el[i].classList.remove('zd-animate');
            }
          }, 1000);
          return () => clearTimeout(timer);
        }

        if (data.error === true) {
          if (data.message) {
            self.props.dmwNotification(data.message);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  downloadManagerGlobalData = data => {
    this.props.downloadManagerGlobalData(data);
  };
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.clearPriorAudioLists) {
      if (nextState.isAudioListsChange !== this.state.isAudioListsChange) {
        this.resetPlayList(nextState);
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      mode,
      audioLists,
      defaultPlayMode,
      remember,
      theme,
      defaultPlayIndex,
      autoPlay,
    } = this.props;

    //Switch 'mini' or'full' mode
    this.toggleMode(mode);

    if (audioLists.length >= 1) {
      const info = {
        ...this.getPlayInfo(audioLists),
        isInitAutoplay: autoPlay,
      };
      const lastPlayStatus = remember
        ? this.getLastPlayStatus(defaultPlayIndex)
        : { playMode: defaultPlayMode, theme };

      switch (typeof info.musicSrc) {
        case 'function':
          info.musicSrc().then(val => {
            this.setState({
              ...info,
              musicSrc: val,
              ...lastPlayStatus,
            });
          }, this.onAudioLoadError);
          break;
        default:
          this.setState({
            ...info,
            ...lastPlayStatus,
          });
      }
    }
  }
  bindUnhandledRejection = () => {
    window.addEventListener('unhandledrejection', this.onAudioLoadError);
  };
  unBindUnhandledRejection = () => {
    window.removeEventListener('unhandledrejection', this.onAudioLoadError);
  };
  componentWillUnmount() {
    this.unBindEvents(this.audio, undefined, false);
    this.unBindUnhandledRejection();
    this.media.removeListener(this.listenerIsMobile);
    this.media = undefined;
  }

  componentDidUpdate(prevProps) {
    if (this.state.audioLists.length > 0 && !this.executed) {
      this.executed = true;
      this.props.setActiveTracksList(this.state.audioLists);
    }
  }

  componentDidMount() {
    // load audiolist from localstorage
    if (this.state.audioLists.length > 0) {
      document
        .getElementsByClassName('music-player-panel')[0]
        .classList.add('active');
      document.body.classList.add('player-active');
    }
    this.addMobileListener();
    this.setDefaultAudioVolume();
    this.bindUnhandledRejection();
    if (this.props.audioLists.length >= 1) {
      this.bindEvents(this.audio);
      this.onGetAudioInstance();
      this.initLyricParser();
      if (IS_MOBILE) {
        this.bindMobileAutoPlayEvents();
      }
      if (!IS_MOBILE && isSafari()) {
        this.bindSafariAutoPlayEvents();
      }
    }
  }
}
