import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import IframeResizer from 'iframe-resizer-react';
import ZDWaveLoader from 'components/ZDWaveLoader';

export default class ReportIssueModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showModal: false,
    };

    this.defaultClick = this.defaultClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  
  defaultClick = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }
  
  render() {
    return (
      <div className="">
        <Button onClick={this.toggleModal} className="zd-report-issue-btn" style={this.props.profileEmail ? {'visibility':'visible'} : {'visibility': 'hidden'}}>Feedback</Button>
        <Modal isOpen={this.state.showModal} className="zd-feedback-modal-content zd-reprot-modal">
          <ModalHeader toggle={this.toggleModal}>Add Feedback</ModalHeader>
          <ModalBody>
            <div className="content modalscroll">
              <div className="zd-modal-wrap">
                <div className="zd-modal-content">
                  <ZDWaveLoader id="zd-wave-loader-report-issue" className="zd-wave-loader-iframe"></ZDWaveLoader>
                  <IframeResizer onLoad={
                    (e) => {
                      if(document.getElementById('zd-wave-loader-report-issue'))
                        document.getElementById('zd-wave-loader-report-issue').style.display = "none";
                    }
                  } style={{'height': '420px', 'width': '100%', 'border': 'none', 'position': 'relative', 'zIndex': 1}} src={'/report-issue/?member_name=' + encodeURIComponent(this.props.profileFullName) + '&member_email=' + encodeURIComponent(this.props.profileEmail)} />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};
