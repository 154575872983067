export const showDownloadSpinner = () => {
  document.getElementById('zd_v5_download_spinner').style.display =
    'inline-block';
  document.getElementById('zd_v5_download_count').style.display = 'none';
};

export const hideDownloadSpinner = () => {
  document.getElementById('zd_v5_download_spinner').style.display = 'none';
  document.getElementById('zd_v5_download_count').style.display =
    'inline-block';
};

export const showSpinnerInTrackClickedForDownload = id => {
  console.log('showSpinnerInTrackClickedForDownload', id);
  let downloadSection = document.getElementById(
    `track_download_row_icon_${id}`,
  );
  if (!downloadSection) {
    downloadSection = document.getElementById(`download_row_icon_${id}`);
  }
  if (!downloadSection) {
    return;
  }
  downloadSection.style.display = 'none';
  const parent = downloadSection.parentNode;
  const spinner = document.createElement('span');
  spinner.classList.add('zd-v5_spinner');
  spinner.classList.add('v5_mr');
  parent.appendChild(spinner);
};

export const hideSpinnerInTrackClickedForDownload = id => {
  let downloadSection = document.getElementById(
    `track_download_row_icon_${id}`,
  );
  if (!downloadSection) {
    downloadSection = document.getElementById(`download_row_icon_${id}`);
  }
  if (!downloadSection) {
    console.log('No download section found', id);
    return;
  }
  downloadSection.style.display = 'inline-block';
  const parent = downloadSection.parentNode;
  const spinner = parent.getElementsByClassName('zd-v5_spinner')[0];
  spinner.remove();
};
