export const MEGA_MENU_TAGS = [
  {
    tag_key: 'Acapellas',
    tag_id: 102,
    cnt: 3511,
  },
  {
    genre_id: 30,
    genre_title: 'Accapellas/DJ Tools',
  },
  {
    tag_key: 'Afro / Latin / Brazilian',
    tag_id: 65,
    cnt: 957,
  },
  {
    tag_key: 'Afro House',
    tag_id: 19,
    cnt: 9403,
  },
  {
    tag_key: 'Bass House',
    tag_id: 21,
    cnt: 6141,
  },
  {
    tag_key: 'Big Room',
    tag_id: 35,
    cnt: 12884,
  },
  {
    genre_id: 65,
    genre_title: 'Big Room/EDM',
  },
  {
    genre_id: 35,
    genre_title: 'Big Room/Future House/Electro',
  },
  {
    tag_key: 'Breaks',
    tag_id: 30,
    cnt: 1852,
  },
  {
    genre_id: 26,
    genre_title: 'Chill/Lounge/Downtempo',
  },
  {
    genre_id: 88,
    genre_title: 'Classic House',
  },
  {
    tag_key: 'Classics',
    tag_id: 143,
    cnt: 73,
  },
  {
    tag_key: 'Country',
    tag_id: 140,
    cnt: 13386,
  },
  {
    tag_key: 'Dance',
    tag_id: 10,
    cnt: 77288,
  },
  {
    genre_id: 27,
    genre_title: 'Dance Commercial/Mainstream Club',
  },
  {
    tag_key: 'Dubstep',
    tag_id: 5,
    cnt: 35,
  },
  {
    genre_id: 23,
    genre_title: 'Dubstep/D&B/Future Bass',
  },
  {
    tag_key: 'Electro House',
    tag_id: 18,
    cnt: 3933,
  },
  {
    tag_key: 'Electronica / Downtempo',
    tag_id: 33,
    cnt: 2746,
  },
  {
    genre_id: 31,
    genre_title: 'Electronica/Experimental',
  },
  {
    tag_key: 'Euro Dance / Pop Dance',
    tag_id: 17,
    cnt: 723,
  },
  {
    genre_id: 34,
    genre_title: "Funky/Jackin'/Groovy/Soulful",
  },
  {
    tag_key: 'Future House',
    tag_id: 20,
    cnt: 6429,
  },
  {
    tag_key: 'Garage / Bassline / Grime',
    tag_id: 72,
    cnt: 1723,
  },
  {
    tag_key: 'Hard Dance / Hardcore',
    tag_id: 39,
    cnt: 1707,
  },
  {
    genre_id: 83,
    genre_title: 'Hip Hop/Rap',
  },
  {
    tag_key: 'Hip-Hop / R&B',
    tag_id: 2,
    cnt: 111694,
  },
  {
    tag_key: 'House',
    tag_id: 6,
    cnt: 55774,
  },
  {
    genre_id: 37,
    genre_title: 'House/Vocal House/Bass House',
  },
  {
    tag_key: 'Indie',
    tag_id: 49,
    cnt: 23582,
  },
  {
    tag_key: 'Indie Dance / Nu Disco',
    tag_id: 12,
    cnt: 7963,
  },
  {
    tag_key: 'Melodic House & Techno',
    tag_id: 27,
    cnt: 23692,
  },
  {
    tag_key: 'Minimal',
    tag_id: 26,
    cnt: 16247,
  },
  {
    genre_id: 76,
    genre_title: 'Minimal/Deep Tech',
  },
  {
    tag_key: 'Miscellaneous',
    tag_id: 38,
    cnt: 668,
  },
  {
    tag_key: 'Organic House / Downtempo',
    tag_id: 131,
    cnt: 8731,
  },
  {
    tag_key: 'Pop',
    tag_id: 23,
    cnt: 24672,
  },
  {
    tag_key: 'Progressive House',
    tag_id: 9,
    cnt: 24620,
  },
  {
    genre_id: 25,
    genre_title: 'R&B/Soul',
  },
  {
    tag_key: 'Reggae / Dub',
    tag_id: 44,
    cnt: 10790,
  },
  {
    genre_id: 19,
    genre_title: 'Reggae/Dancehall',
  },
  {
    tag_key: 'Rock',
    tag_id: 46,
    cnt: 9639,
  },
  {
    genre_id: 8,
    genre_title: 'Rock/Alternative',
  },
  {
    tag_key: 'Salsa',
    tag_id: 139,
    cnt: 104,
  },
  {
    genre_id: 42,
    genre_title: 'Soca/Calypso',
  },
  {
    tag_key: 'Soul / Funk / Disco',
    tag_id: 40,
    cnt: 1129,
  },
  {
    tag_key: 'Soulful House',
    tag_id: 15,
    cnt: 4185,
  },
  {
    tag_key: 'Tech House',
    tag_id: 11,
    cnt: 44063,
  },
  {
    genre_id: 33,
    genre_title: 'Tech House/Deep Tech/Minimal',
  },
  {
    tag_key: 'Techno',
    tag_id: 110,
    cnt: 22537,
  },
  {
    genre_id: 36,
    genre_title: 'Techno/Melodic/Progressive House',
  },
  {
    tag_key: 'Trance',
    tag_id: 8,
    cnt: 16431,
  },
  {
    genre_id: 87,
    genre_title: 'Trap',
  },
  {
    genre_id: 56,
    genre_title: 'Trap/Twerk',
  },
  {
    tag_key: 'Tropical House',
    tag_id: 91,
    cnt: 148,
  },
  {
    genre_id: 2,
    genre_title: 'Urban',
  },
  {
    genre_id: 40,
    genre_title: 'Urban DJ Tools',
  },
];
