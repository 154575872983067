import { Content, Footer, Header, Sidebar } from 'components/Layout';

import ZDSlideDownloadManagerWidget from 'components/ZDSlideDownloadManagerWidget';
import ZDMusicPlayer from 'components/ZDMusicPlayer';

import React from 'react';
import { MdLoyalty } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { isSafari } from 'react-device-detect';
import { zdCheckAuthOnRender } from 'utils/Helpers';
import { Link, withRouter } from 'react-router-dom';
import { isEquals } from 'immutability-helper';
import CheckValidEsID from '../CheckValidEsID/CheckValidEsID';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo === null) {
      return zdCheckAuthOnRender();
    }
    this.state = {
      zdGlobalAudioList: [],
      totalDownloadItems: 0,
      totalSize: 0,
    };
  }

  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  UNSAFE_componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEquals(prevProps.headerActiveTab, this.props.headerActiveTab)) {
      this.props.setSearchPackName('');
    }
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
    this.props.getProfileHeader();
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('close');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      document.body.classList.add('zd-sidebar-open');
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
    document.body.classList.remove('zd-sidebar-open');
  }

  getTotalDownloadItemsFn = total => {
    this.setState({ totalDownloadItems: total });
  };

  downloadManagerGlobalDataFn = data => {
    this.props.sendDownloadManagerDataToApp(data);
  };

  AddMediaToPlayFn = e => {
    e.preventDefault();
    this.props.getParentAudioList(e);
  };

  showNotificationFn = data => {
    if (data) {
      setTimeout(() => {
        if (!this.notificationSystem) {
          return;
        }

        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message: data,
          level: 'info',
        });
      }, 2500);
    }
  };

  render() {
    const { children } = this.props;
    const safariCls = isSafari ? 'zd-is-safari' : 'other';
    document.body.classList.add(safariCls);
    let downloadData = this.props.downloadManagerData
      ? this.props.downloadManagerData
      : '';
    let total_size = downloadData.total_size;
    let totalDownloadItemsDisplay =
      this.state && 'totalDownloadItems' in this.state
        ? this.state.totalDownloadItems
        : 0;
    return (
      <main className={safariCls + ' cr-app'}>
        <CheckValidEsID />
        <div className="zd-nav-brand">
          <Link to="/member">
            {/* <svg
              width="96"
              height="16"
              viewBox="0 0 96 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16H12.3653V13.0747H3.90933L12.344 2.92533V0H0.434667V2.92533H8.52533L0 13.0747V16Z"
                fill="#DDDDDD"
              />
              <path d="M21.7866 0H18.3359V16H21.7866V0Z" fill="#DDDDDD" />
              <path
                d="M31.8979 7.24533V2.856H34.8313C36.4313 2.856 37.1193 3.88533 37.1193 5.05067C37.1193 6.216 36.4313 7.24533 34.8313 7.24533H31.8979ZM28.4473 16H31.8979V10.104H35.0313C38.4366 10.104 40.4233 7.97067 40.4233 5.03733C40.4233 2.104 38.4366 0 35.0313 0H28.4473V16Z"
                fill="#DDDDDD"
              />
              <path
                d="M49.3844 13.0293V2.94933H50.9844C53.6111 2.94933 56.1257 4.22933 56.1257 8C56.1257 11.7707 53.6111 13.0293 50.9844 13.0293H49.3844ZM45.9551 16H51.3257C55.7364 16 59.5924 13.44 59.5924 8C59.5924 2.56 55.7284 0 51.3257 0H45.9551V16Z"
                fill="#DDDDDD"
              />
              <path
                d="M63.6914 13.0293V16H66.0461C68.7127 16 70.3127 14.4693 70.3127 11.224V0H66.8914V11.064C66.8914 12.4107 66.5261 13.0293 65.3607 13.0293H63.6914Z"
                fill="#DDDDDD"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M95.9998 0H73.9785L95.9998 16V0Z"
                fill="#4F7BE9"
              />
            </svg> */}
            <svg
              width="96"
              height="16"
              viewBox="0 0 536 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M536 0H408.6L535.9 92V0H536Z"
                fill="#4978EF"
              />
              <path
                d="M380.2 0V55.9C380.1 67.4 370.8 76.6 359.3 76.6C352.1 76.6 345.1 73 340.8 67.5L332.8 81.3C339.5 88.2 348.9 92.4 359.3 92.4C379.9 92.4 396.5 75.7 396.5 55.2V0H380.2Z"
                fill="#DDDDDD"
              />
              <path
                d="M0 14H70.7L0 78V92H91V78H20.3L91 14V0H0V14Z"
                fill="#DDDDDD"
              />
              <path d="M124.4 0H107.9V92H124.4V0Z" fill="#DDDDDD" />
              <path
                d="M215.4 7.7C212.2 5.1 208.5 3.2 204.3 1.9C200.1 0.600002 195.6 0 190.7 0H141.2V92H157.5V61.2H190.6C195.4 61.2 200 60.6 204.2 59.5C208.4 58.4 212.1 56.6 215.3 54.1C218.5 51.6 221 48.5 222.8 44.7C224.6 40.9 225.5 36.3 225.5 31.1C225.5 25.8 224.6 21.2 222.8 17.3C221.1 13.4 218.6 10.2 215.4 7.7ZM204.4 43.2C201.3 46.2 197.1 47.7 191.9 47.7H157.6V14.3H191.9C197.1 14.3 201.3 15.8 204.4 18.7C207.5 21.6 209.1 25.8 209.1 31.1C209.1 36.2 207.5 40.3 204.4 43.2Z"
                fill="#DDDDDD"
              />
              <path
                d="M317.6 13.7C313.6 9.5 308.2 6.1 301.5 3.7C294.8 1.2 286.4 0 276.5 0H237.7V92H276.5C286.1 92 294.2 90.7 300.8 88.2C307.4 85.7 312.8 82.3 316.9 78C321 73.7 324 68.7 325.9 63.2C327.8 57.6 328.7 51.7 328.7 45.6C328.7 39.5 327.8 33.8 326.1 28.3C324.5 22.8 321.6 17.9 317.6 13.7ZM310.5 57.4C309.3 61.2 307.4 64.7 304.7 67.7C302 70.7 298.3 73.1 293.7 74.9C289.1 76.7 283.2 77.6 276.2 77.6H254V14.2H276.2C283.2 14.2 289 15.1 293.7 16.8C298.3 18.6 302 20.9 304.7 23.8C307.4 26.7 309.3 30.1 310.5 33.8C311.7 37.5 312.3 41.4 312.3 45.4C312.3 49.6 311.7 53.6 310.5 57.4Z"
                fill="#DDDDDD"
              />
              <path
                d="M317.6 13.7C313.6 9.5 308.2 6.1 301.5 3.7C294.8 1.2 286.4 0 276.5 0H237.7V92H276.5C286.1 92 294.2 90.7 300.8 88.2C307.4 85.7 312.8 82.3 316.9 78C321 73.7 324 68.7 325.9 63.2C327.8 57.6 328.7 51.7 328.7 45.6C328.7 39.5 327.8 33.8 326.1 28.3C324.5 22.8 321.6 17.9 317.6 13.7ZM310.5 57.4C309.3 61.2 307.4 64.7 304.7 67.7C302 70.7 298.3 73.1 293.7 74.9C289.1 76.7 283.2 77.6 276.2 77.6H254V14.2H276.2C283.2 14.2 289 15.1 293.7 16.8C298.3 18.6 302 20.9 304.7 23.8C307.4 26.7 309.3 30.1 310.5 33.8C311.7 37.5 312.3 41.4 312.3 45.4C312.3 49.6 311.7 53.6 310.5 57.4Z"
                fill="#DDDDDD"
              />
            </svg>
          </Link>
        </div>
        <Sidebar
          {...this.props}
          profileImageUrl={this.props.profileImageUrl}
          profileHeaderDetails={this.props.profileHeaderDetails}
          title={this.props.pageTitle}
          totalDownloadItems={totalDownloadItemsDisplay}
          totalDownloadSize={total_size}
          setGenrePreferenceStatus={this.props.setGenrePreferenceStatus}
        />
        <Content fluid onClick={this.handleContentClick}>
          <Header
            {...this.props}
            changeProfileSectiontoBack={this.props.changeProfileSection}
            profileImageUrl={this.props.profileImageUrl}
            profileHeaderDetails={this.props.profileHeaderDetails}
            title={this.props.pageTitle}
            totalDownloadItems={totalDownloadItemsDisplay}
            totalDownloadSize={total_size}
            newPageType={this.props.newPageType}
            activeGenrePreference={this.props.activeGenrePreference}
            setActiveGenrePreference={this.props.setActiveGenrePreference}
            activeTracksList={this.props.activeTracksList}
            setActiveTracksList={this.props.setActiveTracksList}
          />
          <ZDSlideDownloadManagerWidget
            ref={this.child}
            dmwNotification={this.showNotificationFn.bind(this)}
            getTotalDownloadItems={this.getTotalDownloadItemsFn}
            downloadManagerGlobalData={this.downloadManagerGlobalDataFn}
            AddMediaToPlay={this.AddMediaToPlayFn.bind(this)}
            downloadManagerData={this.props.downloadManagerData}
          />
          <div className="zd-v5-main-wrapper">
            {children}
            <Footer />
          </div>
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <ZDMusicPlayer
          {...this.props}
          dmwNotification={this.showNotificationFn.bind(this)}
          getTotalDownloadItems={this.getTotalDownloadItemsFn}
          downloadManagerGlobalData={this.downloadManagerGlobalDataFn}
          zdAudioList={this.props.zdAudioList}
          showDownload={this.props.showDownload}
          updateDownloadManager={this.props.updateDownloadManagerData}
          activeTracksList={this.props.activeTracksList}
          setActiveTracksList={this.props.setActiveTracksList}
        />
      </main>
    );
  }
}

export default withRouter(MainLayout);
