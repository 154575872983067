import React from 'react';
import { MdImportantDevices } from 'react-icons/md';
import axios from 'axios';
import { clearSearch } from './Filters';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
const escapeRegExp = require('lodash/escapeRegExp');
const trim = require('lodash/trim');

axios.defaults.timeout = 60000;

/**
 * All the helper methods
 */
export function zdUrlControl(url, history, location) {
  let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));
  let currentSearch = queryString.parse(location, {
    arrayFormat: 'index',
  });
  if (genre_preference && genre_preference.length) {
    if (Object.keys(currentSearch).length < 1) {
      currentSearch = clearSearch(currentSearch);
      genre_preference.forEach(id => {
        currentSearch['tag_id'].push(id.toString());
      });

      history.push({
        search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
      });
    }
  } else {
    history.push(url);
  }
}

export function zdOpenURL(url, history) {
  let urlObj;
  if (url instanceof URL) {
    urlObj = url;
  } else {
    var pathedUrl =
      window.location.origin.replace(/\/$/g, '') +
      '/' +
      url.replace(/^\//g, '');
    urlObj = window.zdIsValidURL(url) ? new URL(url) : new URL(pathedUrl);
  }
  var urlSearch = urlObj.search;
  var appUrl = window.zdGenerateAppURL(urlObj);

  var basePath = window.zdGetBasePath();

  var finalUrl = new URL(appUrl);
  var destinationUrl = {
    pathname: finalUrl.pathname
      .replace(new RegExp('^' + escapeRegExp(basePath)), '/')
      .replace(/^\//g, ''),
  };
  if (urlSearch) {
    destinationUrl['search'] = urlSearch;
  }
  history.push(destinationUrl);
}

export function zdCheckAuthOnAjax(error, reAuth) {
  if (!error) return;
  // if (reAuth) {
  //   window.reAuth();
  //   return;
  // }
  if (error.response) {
    if (
      error.response.data.message === 'Unauthorized' ||
      error.response.status === 401
    ) {
      localStorage.removeItem('_zd_tk');
      localStorage.removeItem('default_url');
      localStorage.removeItem('lastPlayStatus');
      localStorage.removeItem('audioLists');
      localStorage.removeItem('genrelist');
      localStorage.removeItem('breadcrumb');
      if (
        typeof process.env.REACT_APP_BILLING_PATH === 'string' &&
        process.env.REACT_APP_BILLING_PATH
      ) {
        axios
          .get(process.env.REACT_APP_BILLING_PATH + '/logout.php', {})
          .then(res => {
            window.location.href = process.env.REACT_APP_LOGIN_PATH;
          });
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_PATH;
      }
    }
  }
}

export function zdCheckAuthOnRender() {
  localStorage.removeItem('_zd_tk');
  localStorage.removeItem('default_url');
  localStorage.removeItem('lastPlayStatus');
  localStorage.removeItem('audioLists');
  localStorage.removeItem('genrelist');
  localStorage.removeItem('breadcrumb');
  localStorage.removeItem('chart_input');
  localStorage.removeItem('genre_preference');
  localStorage.removeItem('_zd_user_name');

  if (
    typeof process.env.REACT_APP_BILLING_PATH === 'string' &&
    process.env.REACT_APP_BILLING_PATH
  ) {
    axios
      .get(process.env.REACT_APP_BILLING_PATH + '/logout.php', {})
      .then(res => {
        window.location.href = process.env.REACT_APP_LOGIN_PATH;
      });
  } else {
    window.location.href = process.env.REACT_APP_LOGIN_PATH;
  }
}

export function zdPrepareAudioData(e) {
  if (!e) return;

  // var artist = e.target.getAttribute('data-artist');
  // var label_id = e.currentTarget.getAttribute('data-label_id');
  // var audio_src = track_path + source;
  // var genre = e.currentTarget.getAttribute('data-genres');
  // var source = e.currentTarget.getAttribute('data-source');
  // var genre_id = e.currentTarget.getAttribute('data-genre_id');
  let release_id = e.currentTarget.getAttribute('data-release_id');
  let track_id = e.currentTarget.getAttribute('data-id');
  let cover = e.currentTarget.getAttribute('data-thumb');
  let wavepath = e.currentTarget.getAttribute('data-wavepath');
  let track_path = e.currentTarget.getAttribute('data-trackpath');
  let release_name = e.currentTarget.getAttribute('data-release');
  let track_name = e.currentTarget.getAttribute('data-track');
  let artist_info = e.currentTarget.getAttribute('data-artist');
  let singer = artist_info;
  let status = e.currentTarget.getAttribute('data-play-status');
  let cover_type = e.currentTarget.getAttribute('data-cover_type');
  cover_type = cover_type ? cover_type : '';
  let pack_id = e.currentTarget.getAttribute('data-pack_id');
  let archive_flag = e.currentTarget.getAttribute('data-archive_flag');
  let is_feedback = e.currentTarget.getAttribute('data-feedback');
  let is_downloaded = e.currentTarget.getAttribute('data-downloaded');
  let xajax_type = e.currentTarget.getAttribute('data-xajax');
  let artist_details = JSON.parse(
    e.currentTarget.getAttribute('data-artist_details'),
  );
  let uniqueKey = Math.random();
  xajax_type = xajax_type ? xajax_type : 'AddToCart';

  let path = process.env.REACT_APP_UPLOAD_RELEASE_PATH;

  if (archive_flag === '1') {
    path = process.env.REACT_APP_ARCHIVE_PATH;
  }

  var cover_url = path + '/' + release_id + '/' + cover;

  if (cover_type === 'pack') {
    cover_url =
      process.env.REACT_APP_UPLOAD_PATH +
      '/zipdj_packs/' +
      pack_id +
      '/medium_' +
      cover;
  }
  const zdAudioListData = [
    {
      name: release_name,
      singer: singer,
      track: track_name,
      wavePath: wavepath,
      cover: cover_url,
      musicSrc: track_path,
      lyric: '',
      _status: status,
      track_id: track_id,
      is_downloaded: is_downloaded,
      is_feedback: is_feedback,
      release_id: release_id,
      xajax_type: xajax_type,
      artist_details: artist_details,
      uniqueKey,
    },
  ];

  // localStorage.setItem('playingTrack', track_id)
  return zdAudioListData;
}

export function playTrack(e) {
  e.preventDefault();
  let musicPanel = document.getElementsByClassName('music-player-panel');
  musicPanel[0].classList.add('active');
  document.body.classList.add('player-active');

  let $target = e.currentTarget;
  let prevActiveTrack = document.querySelector('.currently-playing-track');
  if (prevActiveTrack) {
    prevActiveTrack.classList.remove('currently-playing-track');
  }
  let track_id = $target.getAttribute('data-id');
  localStorage.setItem('currentTrackId', track_id);

  let playStatus = $target.getAttribute('data-current-play-status');
  let currentPlayStatus = 'play';
  let listData;
  let listElem = document.querySelectorAll(
    `[data-download-id="${$target.getAttribute('data-id')}"]`,
  );
  if (listElem[0] !== undefined) {
    listData = listElem[0].querySelectorAll(
      `[data-id="${$target.getAttribute('data-id')}"]`,
    )[0];
  }
  if (listData !== undefined) {
    listData.children[0].classList.remove('zd-icon-play');
    listData.children[0].classList.remove('zd-icon-repeat-track');
    listData.children[0].classList.remove('zd-icon-pause');
  }
  $target.children[0].classList.remove('zd-icon-play');
  $target.children[0].classList.remove('zd-icon-repeat-track');
  $target.children[0].classList.remove('zd-icon-pause');

  if (playStatus === 'play') {
    currentPlayStatus = 'pause';
    $target.children[0].classList.add('zd-icon-pause');
    if (listData !== undefined) {
      listData.children[0].classList.add('zd-icon-pause');
    }
  } else {
    currentPlayStatus = 'play';
    $target.children[0].classList.add('zd-icon-repeat-track');
    if (listData !== undefined) {
      listData.children[0].classList.add('zd-icon-repeat-track');
    }
  }
  let data_current_play_status = document.querySelectorAll(
    '[data-current-play-status="pause"]',
  );

  if (data_current_play_status && data_current_play_status.length) {
    document
      .querySelectorAll('[data-current-play-status="pause"]')
      .forEach($el => {
        $el.children[0].classList.remove('zd-icon-pause');
        $el.children[0].classList.add('zd-icon-repeat-track');
        $el.setAttribute('data-current-play-status', 'play');
      });
  }

  $target.setAttribute('data-current-play-status', currentPlayStatus);
  if (listData !== undefined) {
    listData.setAttribute('data-current-play-status', currentPlayStatus);
  }
  //   this.props.AddMediaToPlay(e);
}

export function setQueueStatus(e) {
  let musicPanel = document.getElementsByClassName('music-player-panel');
  musicPanel[0].classList.add('active');
  document.body.classList.add('player-active');

  // let currentStatus = e.currentTarget.getAttribute('data-play-status');

  e.currentTarget.setAttribute('data-play-status', 'queue');
}

/*
    Requried archive_flag, img, id, size
*/
export function generateCoverImg(data) {
  if (!data) return;

  let path = process.env.REACT_APP_UPLOAD_RELEASE_PATH;

  if (data.archive_flag) {
    path = process.env.REACT_APP_ARCHIVE_PATH;
  }
  let size = data.size ? data.size + '_' : '';
  let thumb = path + '/' + data.id + '/' + size + data.img;

  return thumb;
}

export function getArtistObj(artistData) {
  if (!artistData) return;

  if (typeof artistData == 'object') return artistData;

  let artistString = artistData ? artistData : '';

  let artistObj = [];
  if (artistString[0] === '[' && artistString[1] === '{') {
    artistObj = JSON.parse(artistString.replace(/'/g, '"'));
  }
  return artistObj;
}

export function renderDecodedHTML(rawHTML) {
  return React.createElement('span', {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });
}

export function joinArtist(artistData) {
  if (!artistData) return;

  let artistString = artistData && artistData !== '[]' ? artistData : '';

  let joinArtist = artistString;
  let artistObj = [];
  if (artistString[0] === '[' && artistString[1] === '{') {
    try {
      artistObj = JSON.parse(artistString);
    } catch (err) {
      try {
        artistObj = JSON.parse(artistString.replace(/'/g, '"'));
      } catch (err) {
        console.log(err);
      }
    }
    let artist = [];
    artistObj.map(e => {
      return artist.push(e.artist_name);
    });
    joinArtist = artist.join(', ');
    return renderDecodedHTML(joinArtist);
  }
}
export function zdsetGenreDropDownValue(value) {
  if (
    document
      .querySelector('.search-genre')
      .querySelector('.zd-nested-dropdown')
      .querySelector('.zd-primary-dropdown')
  ) {
    if (value) {
      if (value.length > 12) {
        value = value.substr(0, 9) + '...';
      }
    }
  }
}

export function zdsetGenreBtnValue(genre_name) {
  let genreName = genre_name ? genre_name : '';

  // eslint-disable-next-line eqeqeq
  genreName = genreName == 'Latin / Reggaeton' ? 'Latin' : genreName;
  if (document.querySelector('.zd-filter-category-btn')) {
    var genreBtns = document.querySelector(
      '.zd-filter-category-btn',
    ).childNodes;
    for (let i = 0; i < genreBtns.length; i++) {
      if (genreBtns[i].innerText == genreName) {
        genreBtns[i].classList.add('active');
      } else {
        genreBtns[i].classList.remove('active');
      }
    }
  }
}

export function zdsetDateDropDownValue(value) {
  if (document.querySelector('.zd-tertiary-header')) {
    if (document.querySelector('.zd-secondary-dropdown')) {
      if (value) {
        document.querySelector('.zd-secondary-dropdown').innerHTML =
          value +
          ' <span class="zd-rotate" id="zd-datelist-span"><i class="zdv3-icon-drop-down" id="datelist-drop-down"></i></span>';
      } else {
        document.querySelector('.zd-secondary-dropdown').innerHTML =
          'This week<span class="zd-rotate ml-2" id="zd-datelist-span"><i class="zdv3-icon-drop-down" id="datelist-drop-down"></i></span>';
      }
    }
  }
}

export function zdsetTrendingChartHistoryDDValue(value) {
  if (document.querySelector('.zd-tertiary-header')) {
    if (document.querySelector('.zd-secondary-dropdown')) {
      if (value) {
        document.querySelector('.zd-secondary-dropdown').innerHTML =
          value +
          ' <span class="zd-rotate" id="zd-datelist-span"><i class="zdv3-icon-drop-down" id="datelist-drop-down"></i></span>';
      } else {
        document.querySelector('.zd-secondary-dropdown').innerHTML =
          'Today <span class="zd-rotate ml-2" id="zd-datelist-span"><i class="zdv3-icon-drop-down" id="datelist-drop-down"></i></span>';
      }
    }
  }
}

export function zdsetClearGenreFilter(value) {
  if (!document.getElementById('zd-clear-genre-filter')) return;
  if (value) {
    document.getElementById('zd-clear-genre-filter').style.display = 'none';
  } else {
    document.getElementById('zd-clear-genre-filter').style.display = 'block';
  }
}

export function zdShowSearchLoader() {
  if (document.getElementById('zd-track-s-page-loader')) {
    document.getElementById('zd-track-s-page-loader').classList.add('zd-show');
    document
      .getElementById('zd-track-s-page-loader')
      .classList.remove('zd-hide');
  }
}

export function zdHideSearchLoader() {
  if (document.getElementById('zd-track-s-page-loader')) {
    document.getElementById('zd-track-s-page-loader').classList.add('zd-hide');
    document
      .getElementById('zd-track-s-page-loader')
      .classList.remove('zd-show');
  }
}

export function zdUpdateTrackNumber(hits, startIndex = 0) {
  for (let i = 0; i < hits.length; i++) {
    let _track_id = hits[i]._id;
    if (document.getElementById('zd-track-count-' + _track_id)) {
      let count = document.getElementById('zd-track-count-' + _track_id);
      count.innerText = i + 1 + startIndex;
    }
  }
}

export function zdUpdateTracksTable(data, startIndex = 0) {
  var $_no_results = document.getElementById('zd-no-results');
  if ($_no_results) {
    $_no_results.classList.add('zd-hide');
    $_no_results.classList.remove('zd-show');
  }

  if (data.length < 1) return;
  for (let j = 0; j < data.length; j++) {
    let _track_id = data[j].track_id;
    // if (document.getElementById('zd-track-count-' + _track_id)) {
    //   let count = document.getElementById('zd-track-count-' + _track_id);
    //   count.innerText = j + 1 + startIndex;
    // }

    let _play_status =
      data[j].is_track_previewed && data[j].is_track_previewed > 0;

    let _download_status =
      data[j].is_track_downloaded && data[j].is_track_downloaded > 0;
    let release_date = document.getElementById(
      'zd_track_releasedate_' + _track_id,
    );

    let favourite_status = data[j].is_track_favourite;

    if (release_date)
      release_date = document.getElementById(
        'zd_track_releasedate_' + _track_id,
      ).innerHTML;
    if (release_date && data[j].last_login_date) {
      let releaseArray = release_date.split('/');
      let releaseDate = new Date(
        '20' + releaseArray[2] + '.' + releaseArray[1] + '.' + releaseArray[0],
      );
      var last_3_days = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);
      if (data[j].last_login_date === 1) {
        if (releaseDate.getTime() > last_3_days) {
          document
            .getElementById('is_track_new_' + _track_id)
            .classList.remove('zd-hide');
        }
      } else {
        let sessionArray = data[j].last_login_date.split('/');
        let sessionDate = new Date(
          '20' +
            sessionArray[2] +
            '.' +
            sessionArray[1] +
            '.' +
            sessionArray[0],
        );

        if (
          releaseDate.getTime() > last_3_days &&
          releaseDate.getTime() > sessionDate.getTime()
        ) {
          document
            .getElementById('is_track_new_' + _track_id)
            .classList.remove('zd-hide');
        }
      }
    }
    let $_download_icon = document.getElementById(
      'download_row_icon_' + _track_id,
    );
    if ($_download_icon) {
      $_download_icon.style.display = _download_status
        ? 'none'
        : 'inline-block';
    }

    let $_track_download_icon = document.getElementById(
      'track_download_row_icon_' + _track_id,
    );
    if ($_track_download_icon) {
      $_track_download_icon.style.display = _download_status
        ? 'none'
        : 'inline-block';
    }

    let elems = document.getElementsByClassName(
      'track_download_row_icon_' + _track_id,
    );

    if (elems) {
      for (let i = 0; i < elems.length; i++) {
        elems[i].style.display = _download_status ? 'none' : 'inline-block';
      }
    }
    //to show all the selected favourite track after every render
    if (favourite_status) {
      document
        .getElementById('favourite-' + _track_id)
        .classList.remove('zdv3-icon-zdfavourite');
      document
        .getElementById('favourite-' + _track_id)
        .classList.add('zdv3-icon-filled-favourite');
    }

    let playBtn = document.querySelectorAll('#play-link-' + _track_id);
    if (playBtn.length < 1) continue;
    for (let i = 0; i < playBtn.length; i++) {
      if (_play_status) {
        playBtn[i].setAttribute('data-play-status', 'pause');
      }
    }

    let $_play_link = document.querySelectorAll(
      '#play-link-' + _track_id + ' > .zd-icon:nth-child(1)',
    );

    if ($_play_link.length < 1) continue;

    for (let i = 0; i < $_play_link.length; i++) {
      if (_play_status) {
        $_play_link[i].classList.add('zd-icon-repeat-track');
        $_play_link[i].classList.remove('zd-icon-play');
      } else {
        if (!$_play_link[i].classList.contains('zd-icon-pause')) {
          $_play_link[i].classList.remove('zd-icon-repeat-track');
        }
      }
    }
  }
  zdHideSearchLoader();
}

export function zdShowGlobalNotification(notification, title, level) {
  if (typeof title == 'undefined') {
    title = <MdImportantDevices />;
  }
  if (typeof level == 'undefined') {
    level = 'info';
  }
  window.notificationSystem.addNotification({
    title: title,
    message: notification,
    level: level,
  });
}

export function zdIsValidTagName(tag_name) {
  let tag_crawl_errors = [
    'NOT-FOUND',
    'IGNORE-DURATION',
    'IGNORE-KEYWORDS',
    'Traxsource',
    'JunoDownload',
    'JunoDownload',
    'Beatport',
    '',
  ];
  if (!tag_name) return false;
  return tag_crawl_errors.indexOf(trim(tag_name)) === -1;
}

export function zdSetBreadcrumb(type, name, parent = null, parent_id = 0) {
  let data = {};
  if (parent !== null && parent_id) {
    data = { type, name, parent, parent_id };
  } else {
    data = { type, name };
  }
  localStorage.setItem('breadcrumb', JSON.stringify(data));
}

export const addToDownloadManagerFn = (
  e,
  updateDownloadManagerDetails,
  callback,
) => {
  let track_id = e.track_id;
  let release_id = e.release_id;
  let xajax = 'AddToCart';
  let ajax_type = 'home';
  if (typeof callback == 'undefined') {
    callback = function () {};
  }

  const postData = {
    xajax: xajax,
    xajaxargs: [track_id, release_id, ajax_type],
  };

  let loginInfo = localStorage.getItem('_zd_tk');
  loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

  const api = {
    baseUrl:
      process.env.REACT_APP_API_PATH +
      '/zdmapi/download-manager/add-track-single',
    xSessionToken: loginInfo.token,
  };

  let url = api.baseUrl;

  axios
    .post(url, postData, {
      headers: {
        'X-Session-Token': api.xSessionToken,
      },
    })
    .then(function (response) {
      const data = response.data;
      if (data.error === false) {
        if (data.data) {
          updateDownloadManagerDetails(data.data);
        }

        if (document.getElementById('download_row_icon_' + track_id)) {
          document.getElementById(
            'download_row_icon_' + track_id,
          ).style.display = 'none';
        }

        if (document.getElementById('track_download_row_icon_' + track_id)) {
          document.getElementById(
            'track_download_row_icon_' + track_id,
          ).style.display = 'none';
        }

        var el = document.body.getElementsByClassName(
          'zd-download-manager-btn',
        );
        for (var i = 0; i < el.length; i++) {
          el[i].classList.add('zd-animate');
        }

        setTimeout(function () {
          var el = document.body.getElementsByClassName(
            'zd-download-manager-btn',
          );
          for (var i = 0; i < el.length; i++) {
            el[i].classList.remove('zd-animate');
          }
        }, 1000);
      }
      callback(data);
    })
    .catch(function (error) {
      console.error(error);
      zdCheckAuthOnAjax(error);
    })
    .then(function () {});
};

export const zdTrackUsersToClassic = () => {
  const loginInfo = JSON.parse(localStorage.getItem('_zd_tk'));

  if (loginInfo !== undefined) {
    const user_auth = loginInfo.userLoginId;

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/classic-visitors',
      xSessionToken: loginInfo.token,
    };

    const post_data = {
      user_auth,
    };

    axios
      .post(api.baseUrl, post_data, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(res => {})
      .catch(err => {
        console.error(err);
      });
  }
};

export function zdAddToFavourite(track_id) {
  const loginInfo = JSON.parse(localStorage.getItem('_zd_tk'));

  if (loginInfo !== undefined) {
    const user_auth = loginInfo.userLoginId;

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/add-to-favourite',
      xSessionToken: loginInfo.token,
    };

    const post_data = {
      user_auth: user_auth,
      track_id: track_id,
    };

    axios
      .post(api.baseUrl, post_data, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(res => {})
      .catch(err => {
        console.error(err);
      });
  }
}

export function zdConvertDate(release_date) {
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let date_arr = release_date.split('/');
  let new_date =
    date_arr[0] + ' ' + months[parseInt(date_arr[1]) - 1] + ' ' + date_arr[2];
  return new_date;
}

export function zdConvertTrackDate(created_at) {
  let track_created_date = created_at.slice(2, 10);
  track_created_date = track_created_date.split('-').reverse().join('/');
  return zdConvertDate(track_created_date);
}

export function zdConvertEntryDate(entry_date) {
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let date_arr = entry_date.split('-');
  let new_date =
    date_arr[2] +
    ' ' +
    months[parseInt(date_arr[1]) - 1] +
    ' ' +
    date_arr[0].slice(-2);
  return new_date;
}

export const fetchSongBinary = musicSrc => {
  axios
    .get(musicSrc, {
      responseType: 'arraybuffer',
      'Transfer-Encoding': 'chunked',
      // 'Content-Type': 'audio/mp4',
    })
    .then(res => res.data);
};

//to add and delete favourite track from favourite master
export const handleFavouriteAction = (e, track_id) => {
  e.stopPropagation();

  let loginInfo = localStorage.getItem('_zd_tk');
  loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
  const api = {
    baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/add-to-favourite-list',
    xSessionToken: loginInfo.token,
  };
  let post_data = {
    track_id: track_id,
  };

  let url = api.baseUrl;
  axios
    .post(url, post_data, {
      headers: { 'X-Session-Token': api.xSessionToken },
    })
    .then(res => {
      if (!res.data.error) {
        let enable_disable_fav = document
          .getElementById('favourite-' + track_id)
          .classList.contains('zdv3-icon-filled-favourite');
        if (enable_disable_fav) {
          document
            .getElementById('favourite-' + track_id)
            .classList.remove('zdv3-icon-filled-favourite');
          document
            .getElementById('favourite-' + track_id)
            .classList.add('zdv3-icon-zdfavourite');
        } else {
          document
            .getElementById('favourite-' + track_id)
            .classList.remove('zdv3-icon-zdfavourite');
          document
            .getElementById('favourite-' + track_id)
            .classList.add('zdv3-icon-filled-favourite');
        }
      } else {
        zdShowGlobalNotification(res.data.message);
      }
    })
    .catch(error => {
      console.error(error);
    });
};

// Get the tagname for active tag
export const zdSetGenreTitle = genre_tags_hierarchy => {
  if (genre_tags_hierarchy.length > 0) {
    let genre_title = null;
    for (let i = 0; i < genre_tags_hierarchy.length; i++) {
      if (genre_tags_hierarchy[i].primary_tag_active == 1) {
        genre_title = genre_tags_hierarchy[i].primary_tag_name;
        break;
      }
    }
    return genre_title;
  }
};
// Get the tag id for active tag
export const zdSetTagId = genre_tags_hierarchy => {
  if (genre_tags_hierarchy.length > 0) {
    let tag_id = null;
    for (let i = 0; i < genre_tags_hierarchy.length; i++) {
      if (genre_tags_hierarchy[i].primary_tag_active == 1) {
        tag_id = genre_tags_hierarchy[i].primary_tag_id;
        break;
      }
    }
    return tag_id;
  }
};

export const setlocation = () => {
  return 'red';
};

export const getFeedbackColspan = () => {
  if (window.matchMedia('(max-width: 750px)').matches) return 7;
  if (window.matchMedia('(max-width: 1224px)').matches) return 10;
  return 14;
};
export const getFeedbackColspanNewrelease = () => {
  if (window.matchMedia('(max-width: 750px)').matches) return 7;
  if (window.matchMedia('(max-width: 1224px)').matches) return 10;
  return 16;
};

///genre preference url generation

export const zdGenrePreferenceUrl = props => {
  if (!props.isBpmClearGenPre && !props.location && !props.history) return;

  if (props.isBpmClearGenPre === false) {
    props.setGenrePreferenceStatus(false);
    let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));
    let currentSearch = queryString.parse(props.location.search, {
      arrayFormat: 'index',
    });

    // Check if url has tag_id parameter & if uRL has tag_id parameter then return
    if (Object.keys(currentSearch).length > 0) {
      if (currentSearch['tag_id']) {
        return;
      }
    }

    if (genre_preference.length > 0) {
      if (Object.keys(currentSearch) && Object.keys(currentSearch).length < 1) {
        currentSearch = clearSearch(currentSearch);
        genre_preference &&
          genre_preference.length &&
          genre_preference.forEach(id => {
            currentSearch['tag_id'].push(id.toString());
          });

        props.history.push({
          search: queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          }),
        });
      }
    } else {
      currentSearch['tag_id'] = [];
      props.history.push({
        search: queryString.stringify(currentSearch, {
          arrayFormat: 'index',
        }),
      });
    }
  } else {
    props.setGenrePreferenceStatus(true);
  }
};

// finding indexs for all packs section

export const getIndexOfAllpacks = release_name => {
  var packAssetIndex = '';

  if (
    release_name.includes('ZIPDJ Trending :: Afro House') ||
    release_name.includes('ZIPDJ Trending :: Hip-Hop / R&B') ||
    release_name.includes('ZIPDJ Trending :: House') ||
    release_name.includes('ZIPDJ Trending :: Soulful House') ||
    release_name.includes('ZIPDJ Trending :: Soul / Funk / Disco')
  ) {
    packAssetIndex = 1;
  } else if (
    release_name.includes("ZIPDJ Trending :: Funky / Groove / Jackin' House") ||
    release_name.includes('ZIPDJ Trending :: Future House') ||
    release_name.includes('ZIPDJ Trending :: Pop') ||
    release_name.includes('ZIPDJ Trending :: Progressive House')
  ) {
    packAssetIndex = 2;
  } else if (
    release_name.includes('ZIPDJ Trending :: Electro House') ||
    release_name.includes('ZIPDJ Trending :: Electronica / Downtempo') ||
    release_name.includes('ZIPDJ Trending :: Reggae / Dancehall / Dub') ||
    release_name.includes('ZIPDJ Trending :: Rock') ||
    release_name.includes('ZIPDJ Trending :: Melodic House & Techno')
  ) {
    packAssetIndex = 3;
  } else if (
    release_name.includes('ZIPDJ Trending :: Dance') ||
    release_name.includes('ZIPDJ Trending :: Dancehall/Ragga') ||
    release_name.includes('ZIPDJ Trending :: Deep House') ||
    release_name.includes('ZIPDJ Trending :: Dubstep')
  ) {
    packAssetIndex = 4;
  } else if (
    release_name.includes('ZIPDJ Trending :: Bass House') ||
    release_name.includes('ZIPDJ Trending :: Big Room') ||
    release_name.includes('ZIPDJ Trending :: Nu Disco / Indie Dance') ||
    release_name.includes('ZIPDJ Trending :: Tech House') ||
    release_name.includes('ZIPDJ Trending :: Techno') ||
    release_name.includes('ZIPDJ Trending :: Trance')
  ) {
    packAssetIndex = 5;
  } else {
    packAssetIndex = 1;
  }
  return packAssetIndex;
};

export const getAllGenreOfAllpacks = release_name => {
  let str = release_name.match(
    /ZIPDJ Trending :: (([\w+ ]+(&|'|-|\/)*?)*?)Vol./,
  );
  return str && str[1];
};
