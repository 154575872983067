import React, { createContext, useReducer } from 'react';
const initialState = {
  searchText: '',
};

const handlers = {
  SETSEARCHTEXT: (state, action) => {
    return {
      ...state,
      searchText: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const SearchContext = createContext({
  ...initialState,
  setSearchText: () => Promise.resolve(),
});

export const SearchProvider = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSearchText = text => {
    dispatch({
      type: 'SETSEARCHTEXT',
      payload: text,
    });
  };

  return (
    <SearchContext.Provider
      value={{
        ...state,
        setSearchText,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
