import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import ZDWaveLoader from 'components/ZDWaveLoader';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { MdImportantDevices } from 'react-icons/md';
import {
  zdCheckAuthOnAjax,
  zdPrepareAudioData,
  zdsetGenreDropDownValue,
  zdsetGenreBtnValue,
  zdShowGlobalNotification,
} from 'utils/Helpers';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import gsap from 'gsap';
import {
  hideDownloadSpinner,
  hideSpinnerInTrackClickedForDownload,
  showDownloadSpinner,
  showSpinnerInTrackClickedForDownload,
} from './utils/utilityStyleFunctions';

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_BUGSNAG_API_KEY
      : '6EF6B30F9E557F948C402C89002C7C8A',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
});

const ChartInputPage = React.lazy(() => import('pages/ChartInputPage'));

const SubGenrePage = React.lazy(() => import('pages/SubGenrePage'));
const TopDownloadsPage = React.lazy(() => import('pages/TopDownloadsPage'));
const HomePage = React.lazy(() => import('pages/HomePage'));
const SearchResultsPage = React.lazy(() => import('pages/SearchResultsPage'));
const LabelSearchResultsPage = React.lazy(() =>
  import('pages/LabelSearchResultsPage'),
);
const ReleaseSearchResultsPage = React.lazy(() =>
  import('pages/ReleaseSearchResultsPage'),
);
const ArtistSearchResultsPage = React.lazy(() =>
  import('pages/ArtistSearchResultsPage'),
);
const TrackSearchResultsPage = React.lazy(() =>
  import('pages/TrackSearchResultsPage'),
);
const DownloadManagerPage = React.lazy(() =>
  import('pages/DownloadManagerPage'),
);
const ChartPageES = React.lazy(() => import('pages/ChartPageES'));
const AirplayPage = React.lazy(() => import('pages/AirplayPage'));
const AllTrendingChartPage = React.lazy(() =>
  import('pages/AllTrendingChartPage'),
);
const UrbanPage = React.lazy(() => import('pages/UrbanPage'));
const ElectronicPage = React.lazy(() => import('pages/ElectronicPage'));
const MainstreamPage = React.lazy(() => import('pages/MainstreamPage'));
const NewReleasesPage = React.lazy(() => import('pages/NewReleasesPage'));
const LabelViewPage = React.lazy(() => import('pages/LabelViewPage'));
const GenresHomePage = React.lazy(() => import('pages/GenresHomePage'));
const GenresPage = React.lazy(() => import('pages/GenresPage'));
const PacksPage = React.lazy(() => import('pages/PacksPage'));
const PackFeaturesPage = React.lazy(() => import('pages/PackFeaturesPage'));
const TrendingPackPage = React.lazy(() => import('pages/ZDTrendingPackPage'));
const TrendingPackView = React.lazy(() => import('pages/ZDTrendingPackView'));
const SingleReleasePage = React.lazy(() => import('pages/SingleReleasePage'));
const ZDPackListPageES = React.lazy(() => import('pages/ZDPackListPageES'));
const ZDPackViewPageES = React.lazy(() => import('pages/ZDPackViewPageES'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage'));
const LogoutPage = React.lazy(() => import('pages/LogoutPage'));
const NewPlayList = React.lazy(() => import('pages/NewPlayList'));
const PacksViewPage = React.lazy(() => import('pages/PacksViewPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
const AudioWave = React.lazy(() => import('pages/AudioWave'));
const escapeRegExp = require('lodash/escapeRegExp');
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

axios.defaults.timeout = 60000;

(() => {
  (function (send) {
    XMLHttpRequest.prototype.send = function (body) {
      if (body === '') {
        send.call(this, body);
      }
      try {
        var bodyJson = JSON.parse(body);
        if (bodyJson['size']) {
          bodyJson['track_total_hits'] = true;
          if (bodyJson['_source']) {
            if (bodyJson['_source']['excludes']) {
              bodyJson['_source']['excludes'].push('release_countries');
              bodyJson['_source']['excludes'].push('label_countries');
            } else {
              bodyJson['_source']['excludes'] = [
                'release_countries',
                'label_countries',
              ];
            }
          } else {
            bodyJson['_source'] = {
              excludes: ['release_countries', 'label_countries'],
            };
          }
          if (
            bodyJson['query'] &&
            bodyJson['query']['simple_query_string'] &&
            typeof bodyJson['query']['simple_query_string']['query'] == 'string'
          ) {
            var simple_query_string =
              bodyJson['query']['simple_query_string']['query'];
            simple_query_string = simple_query_string.replace(/-/g, ' ');
            if (
              this._zd_xhr_request_url.match(
                /\/zipdj-user-tracks-[^\/]+\/_search/,
              )
            ) {
              var simple_query_arr = simple_query_string.split(/ +/);
              var new_query = simple_query_arr.join('+');
              new_query += ' ' + simple_query_arr.join(' ');
              bodyJson['query']['simple_query_string']['query'] =
                new_query.replace(/\*$/g, '') + '*';
            } else {
              bodyJson['query']['simple_query_string']['query'] =
                simple_query_string.replace(/\*+$/g, '') + '*';
            }
          }
        }
        body = JSON.stringify(bodyJson);
      } catch (_) {}
      send.call(this, body);
    };
  })(XMLHttpRequest.prototype.send);

  XMLHttpRequest.prototype.open = (function (open) {
    return function (method, url, async) {
      this._zd_xhr_request_url = url;
      open.apply(this, arguments);
    };
  })(XMLHttpRequest.prototype.open);
})();

window.zdIsValidURL = function (string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
};

window.zdGetBasePath = function () {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

window.zdGenerateAppURL = function (url, queryVars) {
  if (url instanceof URL) {
    var parsedURL = url;
  } else if (typeof url == 'string') {
    if (!window.zdIsValidURL(url)) {
      var url = window.location.origin + '/' + url.replace(/^\//g, '');
    }
    var parsedURL = new URL(url);
  }
  if (!parsedURL) {
    return '';
  }
  var basePath = window.zdGetBasePath();
  parsedURL.href =
    window.location.origin +
    '/' +
    basePath.replace(/^\//g, '') +
    '/' +
    parsedURL.pathname
      .replace(new RegExp('^' + escapeRegExp(basePath)), '/')
      .replace(/^\//g, '');

  if (typeof queryVars == 'string' && queryVars) parsedURL.href += queryVars;

  return parsedURL.toString();
};

var zdAllowedRedirectUris = ['/label.php', '/subscription.php'];

window.zdLogin = function (data_input) {
  if (
    typeof data_input['isLoggedIn'] != 'undefined' &&
    data_input['isLoggedIn']
  ) {
    let url = localStorage.getItem('default_url');
    var login_redirect_url =
      url === '/member'
        ? process.env.REACT_APP_PATH + '/member'
        : process.env.REACT_APP_PATH + '/member' + url;
    if (
      typeof data_input['loginRedirectUrl'] != 'undefined' &&
      data_input['loginRedirectUrl']
    ) {
      login_redirect_url = data_input['loginRedirectUrl'];
    }
    window.localStorage['_zd_tk'] = JSON.stringify(data_input);
    Bugsnag.setUser(data_input['userLoginId']);

    window.location.href = login_redirect_url;
    return;
  }
  var login_redirect_url = false;
  if (
    typeof data_input['loginRedirectUrl'] != 'undefined' &&
    data_input['loginRedirectUrl']
  ) {
    login_redirect_url = data_input['loginRedirectUrl'];
  }

  delete window.localStorage['_zd_tk'];
  delete window.localStorage['default_url'];
  delete window.localStorage['genre_preference'];
  delete window.localStorage['chart_input'];

  if (
    typeof process.env.REACT_APP_BILLING_PATH === 'string' &&
    process.env.REACT_APP_BILLING_PATH
  ) {
    axios
      .get(process.env.REACT_APP_BILLING_PATH + '/logout.php', {})
      .then(res => {
        if (login_redirect_url) {
          window.location.href = login_redirect_url;
        } else {
          window.location.reload();
        }
      });
  } else {
    if (login_redirect_url) {
      window.location.href = login_redirect_url;
    } else {
      window.location.reload();
    }
  }
};
window.zdGetFinalRedirectUrl = function (redirect_history) {
  if (!redirect_history) return null;
  var final_redirect_uri = null;
  if (
    typeof redirect_history[0] != 'undefined' &&
    typeof redirect_history[0]['redirect_url_parts'] != 'undefined' &&
    typeof redirect_history[0]['redirect_url_parts']['path'] != 'undefined'
  ) {
    final_redirect_uri = redirect_history[0]['redirect_url_parts']['path'];
  }
  var final_redirect_url =
    typeof redirect_history[0] != 'undefined' &&
    typeof redirect_history[0]['redirect_url'] != 'undefined'
      ? redirect_history[0]['redirect_url']
      : null;
  if (!final_redirect_uri) return null;
  for (var i = 0; i < zdAllowedRedirectUris.length; i++) {
    var name = zdAllowedRedirectUris[i];
    if (name == final_redirect_url) {
      return final_redirect_url;
    }
  }
  return null;
};

// Bikash please check this function
window.memberPageLoad = function (login_response) {
  if (login_response['isLoggedIn']) {
    if (!login_response['isSubscribed']) {
      window.location.href = login_response['redirect_url'];
      return;
    }

    window.localStorage['genre_preference'] = JSON.stringify(
      login_response['genre_preference'],
    );
    window.localStorage['chart_input'] = login_response['chartInput'];
    window.localStorage['_zd_tk'] = JSON.stringify(login_response);
    window.localStorage['default_url'] = login_response['red_url'];

    if (login_response['isLoggedIn']) {
      let url = login_response['red_url'];
      var login_redirect_url =
        url === '/member'
          ? process.env.REACT_APP_PATH + '/member/##'
          : process.env.REACT_APP_PATH + '/member' + url + '/##';
      let chartInputflag = login_response['chartInput'];
      if (chartInputflag == 0) {
        if (window.innerWidth < 750) {
          localStorage.setItem('chart_input', 1);
          window.location.href = login_redirect_url;
        } else {
          login_redirect_url =
            process.env.REACT_APP_PATH + '/member/chart-input/##';
        }
      }
    }

    window.location.href = login_redirect_url;
    return;
  }
};

window.zdCheckLogin = function (data_input) {
  if (
    typeof data_input['isLoggedIn'] != 'undefined' &&
    data_input['isLoggedIn']
  ) {
    window.localStorage['_zd_tk'] = JSON.stringify(data_input);
    Bugsnag.setUser(data_input['userLoginId']);
  } else {
    delete window.localStorage['_zd_tk'];
    delete window.localStorage['default_url'];
    delete window.localStorage['genre_preference'];
    delete window.localStorage['chart_input'];
    window.location.reload();
  }
};

window.zdSearching = 'NO';

window.zdElementIsInViewport = function (elem) {
  if (!elem) {
    return false;
  }
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

window.zdDownloadFile = function (fileURL, fileName) {
  if (!fileURL) return;

  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = fileURL;
  a.setAttribute('download', true);

  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

window.zdFindComponentByElement = function (dom, traverseUp = 0) {
  const key = Object.keys(dom).find(key =>
    key.startsWith('__reactInternalInstance$'),
  );
  const domFiber = dom[key];
  if (domFiber == null) return null;

  // react <16
  if (domFiber._currentElement) {
    let compFiber = domFiber._currentElement._owner;
    for (let i = 0; i < traverseUp; i++) {
      compFiber = compFiber._currentElement._owner;
    }
    return compFiber._instance;
  }

  // react 16+
  const GetCompFiber = fiber => {
    //return fiber._debugOwner;  this also works, but is __DEV__ only
    let parentFiber = fiber.return;
    while (typeof parentFiber.type == 'string') {
      parentFiber = parentFiber.return;
    }
    return parentFiber;
  };
  let compFiber = GetCompFiber(domFiber);
  for (let i = 0; i < traverseUp; i++) {
    compFiber = GetCompFiber(compFiber);
  }
  return compFiber.stateNode;
};

window.zdMemberEsId = function () {
  if (typeof window.localStorage['_zd_tk'] == 'undefined') return '';
  try {
    var _zd_tk = JSON.parse(window.localStorage['_zd_tk']);
    return _zd_tk['memberEsId'];
  } catch (e) {
    var _zd_tk = {};
    return '';
  }
};

window.onscroll = function () {
  if (
    window.zdElementIsInViewport(
      document.getElementById('zd-labels-loader-marker'),
    ) &&
    document.getElementById('zd-labels-load-more')
  ) {
    document.getElementById('zd-labels-load-more').click();
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      zdAudioList: [],
      playedTracks: [],
      playlistTracks: [],
      refreshDwnW: false,
      downloadManagerData: [],
      downloadManagerTotalItems: 0,
      downloadManagerTotalSize: '',
      downloadManagerMsg: '',
      globalNotifications: '',
      profileImageUrl: '',
      profileHeaderDetails: [],
      headerTitle: '',
      showDownload: true,
      headerActiveTab: 'new-releases',
      pause: false,
      megaMenuShow: false,
      activePageName: 'new-releases',
      activeTrackId: null,
      activeReleaseId: null,
      activePackId: null,
      expandedNewReleaseList: [],
      profileDetails: [],
      totalDownloaded: 0,
      downloadLimit: 0,
      profileActiveTab: 'myprofile',
      chartInput: false,
      cratePlaylistValue: [],
      bpmRange: {
        low_range: '',
        high_range: '',
      },
      isBpmRangeActive: false,
      isBpmClearGenPre: false,
      activeGenrePreference: [],
      isBpmRangeTrackActive: false,
      newPageType: '',
      newTrandingPageType: '',
      genrePreferenceStatus: false,
      updateSearchResult: [],
      genrePreApiLoad: 'No',
      activeTracksList: '',
      fromPacks: false,
      search_pack_name: '',
      searchPack_page: 1,
      categoriesData: '',
      fromCategories: false,
      fromTrendingPackData: '',
      packData: '',
    };
    this.fetchCratePlaylistData = this.fetchCratePlaylistData.bind(this);
  }
  componentDidMount() {
    let loginInfo = localStorage.getItem('_zd_tk');

    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    let appEnv = process.env.REACT_APP_ENV;

    if (
      appEnv != 'development' &&
      (!loginInfo ||
        typeof loginInfo.token == 'undefined' ||
        !loginInfo.token ||
        typeof loginInfo.isLoggedIn == 'undefined' ||
        !loginInfo.isLoggedIn)
    ) {
      this.setState({
        isLoggedIn: false,
      });
      console.log('loginInfo', loginInfo);
      window.location.href = '/?prev_url=' + window.location.pathname;
      return;
    }

    if (loginInfo) {
      if (loginInfo.token && loginInfo.isLoggedIn) {
        this.setState({
          isLoggedIn: true,
        });
        this.updateChartInputFlag();
        //fetch all crate playlist
        this.fetchCratePlaylistData();
      }
    }

    console.log(
      '%c Welcome to ZIPDJ',
      'color: #4f7be9; font-weight: bold; font-size: 20px',
    );
  }

  updateChartInputFlag = () => {
    let chartinputflag = localStorage.getItem('chart_input');
    this.setState({
      chartInput: chartinputflag == 0 ? true : false,
    });
  };

  // Active tab for profile section
  changeProfileSection = active_tab => {
    if (active_tab !== '') {
      this.setState({
        profileActiveTab: active_tab,
      });
    } else {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let param_value = urlParams.get('q');
      this.setState({
        profileActiveTab: param_value,
      });
    }
  };

  zdSearchWidgetToggle() {
    document.body.classList.toggle('zd-search-open');
  }

  // Show mega menu to toggle megamenu
  showMegaMenu = e => {
    let mainMega = document.getElementById('mainMega');
    let activeTracksList = this.state.activeTracksList;
    if (mainMega && activeTracksList && activeTracksList.length > 0) {
      mainMega.classList.add('mainMega-mobile');
    } else {
      mainMega && mainMega.classList.remove('mainMega-mobile');
    }
    if (!this.state.megaMenuShow) {
      this.setState(
        {
          megaMenuShow: true,
        },
        () => {
          if (this.state.megaMenuShow) {
            document
              .getElementsByTagName('body')[0]
              .classList.add('body-overflow-hidden');
          }
        },
      );
    } else if (this.state.megaMenuShow) {
      const tl = gsap.timeline();
      setTimeout(() => {
        this.setState(
          {
            megaMenuShow: false,
          },
          () => {
            document
              .getElementsByTagName('body')[0]
              .classList.remove('body-overflow-hidden');
          },
        );
      }, 100);
    }
  };

  closeMegaMenu = () => {
    setTimeout(() => {
      this.setState(
        {
          megaMenuShow: false,
        },
        () => {
          document
            .getElementsByTagName('body')[0]
            .classList.remove('body-overflow-hidden');
        },
      );
    }, 100);
  };

  handleSearchGenre = () => {
    this.zdSearchWidgetToggle();
    // this.showMegaMenu();
  };

  togglePlay = val => {
    this.setState({ pause: val });
  };

  playAll = childAudioList => {
    if (
      this.state.zdAudioList.length > 1 &&
      this.state.zdAudioList[0].hasOwnProperty('play') &&
      this.state.zdAudioList[0].track_id == childAudioList[0].track_id
    ) {
      this.setState({ zdAudioList: [] });
      return;
    }
    childAudioList[0].play = true;
    this.setState({ zdAudioList: childAudioList });
  };

  getChildAudioListCallback = childAudioList => {
    var release_id =
      childAudioList.currentTarget.getAttribute('data-release_id');
    var track_id = childAudioList.currentTarget.getAttribute('data-id');
    var is_downloaded =
      childAudioList.currentTarget.getAttribute('data-downloaded');
    var is_feedback =
      childAudioList.currentTarget.getAttribute('data-feedback');

    this.setState({
      showDownload: is_downloaded == 'show',
      hasFeedback: is_feedback == 'Yes',
    });
    const playListTrackData = {
      releaseId: release_id,
      trackId: track_id,
    };

    let self = this;

    //Prepare Audio data
    let zdAudioListData = zdPrepareAudioData(childAudioList);
    self.setState({ zdAudioList: zdAudioListData }, () => {
      if (!self.state.playlistTracks.includes(track_id)) {
        self.addTrackToRecentPlayedList(playListTrackData);
      }
    });
  };

  refreshDownloadWidgetStatus = data => {
    this.setState({
      downloadManagerData: data,
    });
  };
  setHeader = title => {
    this.setState({ headerTitle: title });
  };

  setHeaderActiveTab = tab => {
    this.setState({ headerActiveTab: tab });
  };

  getGenreNameByID = data => {
    if (!data) return;
    var self = this;
    let genre_id = data.genre_id ? data.genre_id : 0;
    let type = data.type ? data.type : '';
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH + '/zdmapi/genre-name/' + genre_id,
      xSessionToken: loginInfo.token,
    };

    let url = api.baseUrl;
    axios
      .get(url, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data.error === true && (!data.genre_name || !data.genre_id)) {
          zdsetGenreDropDownValue('');
          return;
        }
        if (type === 'selectMenu') {
          zdsetGenreDropDownValue(data.genre_name);
          zdsetGenreBtnValue(data.genre_name);
        }
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  };

  addTrackToRecentPlayedList = data => {
    if (!data) return;
    let track_id = data.trackId ? data.trackId : 0;
    let release_id = data.releaseId ? data.releaseId : 0;

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/add-to-play-list',
      xSessionToken: loginInfo.token,
    };
    const post_data = {
      xajaxargs: [track_id, release_id],
    };
    let url = api.baseUrl;
    let self = this;
    axios
      .post(url, post_data, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        let playlistTracks = [...self.state.playlistTracks];
        if (!playlistTracks.includes(track_id)) {
          playlistTracks.push(track_id);
          self.setState({
            playlistTracks: playlistTracks,
          });
        }
      })
      .catch(function (error) {
        console.log(error);

        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  };

  updateDownloadManagerData = data => {
    this.setState({
      downloadManagerData: data,
      downloadManagerTotalItems: data.total_items,
    });
  };

  addToDownloadManagerParentFn = (e, ajax_type) => {
    if (!e) return;
    showDownloadSpinner();

    let self = this;
    let currentTarget = e.currentTarget;
    let track_id = currentTarget.getAttribute('data-id');
    let release_id = currentTarget.getAttribute('data-release_id');
    let xajax = currentTarget.getAttribute('data-xajax');
    xajax = xajax ? xajax : 'AddToCart';
    ajax_type = ajax_type ? ajax_type : 'home';

    const postData = {
      xajax: xajax,
      xajaxargs: [track_id, release_id, ajax_type],
    };

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo) {
      if (track_id) {
        showSpinnerInTrackClickedForDownload(track_id);
      }
      const api = {
        baseUrl:
          process.env.REACT_APP_API_PATH +
          '/zdmapi/download-manager/add-track-single',
        xSessionToken: loginInfo.token,
      };

      let url = api.baseUrl;

      axios
        .post(url, postData, {
          headers: {
            'X-Session-Token': api.xSessionToken,
          },
        })
        .then(function (response) {
          const data = response.data;

          if (data.error === false) {
            if (data.feedback === true) {
              return;
            }

            if (data.data) {
              self.setState({
                downloadManagerData: data.data,
                downloadManagerTotalItems: data.data.total_items,
              });
            }

            hideSpinnerInTrackClickedForDownload(track_id);

            // Added slide open widget animations
            // document.body.classList.add('zd-slide-open');
            if (document.getElementById('download_row_icon_' + track_id)) {
              document.getElementById(
                'download_row_icon_' + track_id,
              ).style.display = 'none';
            }

            let elems = document.getElementsByClassName(
              'download_row_icon_' + track_id,
            );

            for (let i = 0; i < elems.length; i++) {
              elems[i].style.display = 'none';
            }

            let trackElems = document.getElementsByClassName(
              'track_download_row_icon_' + track_id,
            );

            for (let i = 0; i < trackElems.length; i++) {
              trackElems[i].style.display = 'none';
            }

            if (
              document.getElementById('track_download_row_icon_' + track_id)
            ) {
              document.getElementById(
                'track_download_row_icon_' + track_id,
              ).style.display = 'none';
            }

            var el = document.body.getElementsByClassName(
              'zd-download-manager-btn',
            );
            for (var i = 0; i < el.length; i++) {
              el[i].classList.add('zd-animate');
            }

            const timer = setTimeout(() => {
              var el = document.body.getElementsByClassName(
                'zd-download-manager-btn',
              );
              for (var i = 0; i < el.length; i++) {
                el[i].classList.remove('zd-animate');
              }
            }, 1000);
            return () => clearTimeout(timer);
          }

          if (data.error === true) {
            if (data.message) {
              hideSpinnerInTrackClickedForDownload(track_id);
              zdShowGlobalNotification(data.message);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          hideSpinnerInTrackClickedForDownload(track_id);

          zdCheckAuthOnAjax(error);
        })
        .then(function () {})
        .finally(() => {
          hideDownloadSpinner();
        });
    }
  };

  updateDownloadManagerDataByPacks = data => {
    if (data.data && data.error === false) {
      this.setState({
        downloadManagerData: data.data,
        downloadManagerTotalItems: data.data.total_items,
      });
    }
  };

  //Global Download Manager data
  getDownloadManagerDataInApp = data => {
    this.setState({ downloadManagerData: data });
  };
  getdmwNotificationInApp = data => {
    zdShowGlobalNotification(data);
  };

  setGlobalNotifications = notification => {
    zdShowGlobalNotification(notification, <MdImportantDevices />, 'info');
  };

  //fetch all crate playlist
  //use in all page crate dropdown
  fetchCratePlaylistData = () => {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo) {
      const api = {
        baseUrl:
          process.env.REACT_APP_API_PATH + '/zdmapi/fetch-crate-playlist',
        xSessionToken: loginInfo.token,
      };
      let url = api.baseUrl;

      axios
        .get(url, {
          headers: { 'X-Session-Token': api.xSessionToken },
        })
        .then(response => {
          const data = response.data;
          if (data) {
            if (!data.error) {
              this.setState({ cratePlaylistValue: data.value });
            }
          }
        })

        .catch(function (error) {
          console.log(error);
        });
    }
  };

  updateProfileImageURL = url => {
    if (url === '') {
      this.setState({
        profileImageUrl: '',
      });
    } else {
      this.setState({
        profileImageUrl:
          process.env.REACT_APP_PROFILE_IMAGE_URL + url + '?v=' + Math.random(),
      });
    }
  };

  getProfileHeader = () => {
    let loginInfo = localStorage.getItem('_zd_tk');

    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo) {
      const api = {
        baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/getprofileheader',
        xSessionToken: loginInfo.token,
      };

      let self = this;
      let url = api.baseUrl;

      axios
        .get(url, {
          headers: {
            'X-Session-Token': api.xSessionToken,
          },
        })
        .then(response => {
          const data = response.data;
          if (data !== 'Error') {
            if (data) {
              if (data.member_profile_image) {
                self.setState({
                  profileImageUrl:
                    process.env.REACT_APP_PROFILE_IMAGE_URL +
                    data.member_profile_image +
                    '?v=' +
                    Math.random(),
                });
              }
            }
            self.setState({
              profileHeaderDetails: data,
              unauthorized: false,
              isLoaded: true,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          zdCheckAuthOnAjax(error);
        });
    }
  };

  getDownloadData = () => {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo) {
      const api = {
        baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/download-history',
        xSessionToken: loginInfo.token,
      };
      let url = api.baseUrl;

      var post_data = {};
      axios
        .post(url, post_data, {
          headers: { 'X-Session-Token': api.xSessionToken },
        })
        .then(res => {
          res.data.uniqueKey = Math.random();
          this.setState({
            totalDownloaded: res.data.total_downloaded,
            downloadLimit: res.data.download_limit,
          });
        })
        .catch(err => console.log(err));
    }
  };

  render() {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    return (
      <BrowserRouter basename={getBasename()}>
        {loginInfo.token ? (
          <GAListener>
            <ErrorBoundary>
              <Switch>
                <LayoutRoute
                  exact={true}
                  path="/login"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )}
                />
                <LayoutRoute
                  exact={true}
                  path="/signup"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_SIGNUP} />
                  )}
                />

                <MainLayout
                  setActivePageName={data =>
                    this.setState({ activePageName: data })
                  }
                  newPageType={this.state.newPageType}
                  newTrandingPageType={this.state.newTrandingPageType}
                  handleSearchGenre={this.handleSearchGenre}
                  activePackId={this.state.activePackId}
                  activePageName={this.state.activePageName}
                  activeReleaseId={this.state.activeReleaseId}
                  activeTrackId={this.state.activeTrackId}
                  setActiveReleaseId={data => {
                    this.setState({ activeReleaseId: data });
                  }}
                  setActiveTrackId={data => {
                    this.setState({ activeTrackId: data });
                  }}
                  playedTracks={this.state.playedTracks}
                  setPlayedTracks={data => {
                    this.setState({
                      playedTracks: [...this.state.playedTracks, data],
                    });
                  }}
                  expandedNewReleaseList={this.state.expandedNewReleaseList}
                  setExpandedNewReleaseList={data =>
                    this.setState({ expandedNewReleaseList: data })
                  }
                  pageTitle={this.state.headerTitle}
                  showDownload={this.state.showDownload}
                  breakpoint={this.props.breakpoint}
                  zdAudioList={this.state.zdAudioList}
                  setZdAudioList={data => {
                    this.setState({ zdAudioList: data });
                  }}
                  isLoggedIn={this.state.isLoggedIn}
                  getParentAudioList={this.getChildAudioListCallback}
                  sendDownloadManagerDataToApp={
                    this.getDownloadManagerDataInApp
                  }
                  downloadManagerData={this.state.downloadManagerData}
                  profileHeaderDetails={this.state.profileHeaderDetails}
                  profileImageUrl={this.state.profileImageUrl}
                  getProfileHeader={this.getProfileHeader.bind(this)}
                  getGenreNameByID={this.getGenreNameByID}
                  updateDownloadManagerData={this.updateDownloadManagerData}
                  headerActiveTab={this.state.headerActiveTab}
                  pause={this.state.pause}
                  showMegaMenu={this.showMegaMenu}
                  closeMegaMenu={this.closeMegaMenu}
                  megaMenuShow={this.state.megaMenuShow}
                  setMegaMenuShow={data => {
                    this.setState({ megaMenuShow: data });
                  }}
                  changeProfileSection={this.changeProfileSection}
                  setGenrePreferenceStatus={data => {
                    this.setState({ genrePreferenceStatus: data });
                  }}
                  activeGenrePreference={this.state.activeGenrePreference}
                  setActiveGenrePreference={data => {
                    this.setState({ activeGenrePreference: data });
                  }}
                  genrePreApiLoad={this.state.genrePreApiLoad}
                  setGenrePreApiLoad={data => {
                    this.setState({ genrePreApiLoad: data });
                  }}
                  activeTracksList={this.state.activeTracksList}
                  setActiveTracksList={data => {
                    this.setState({ activeTracksList: data });
                  }}
                  setIsBpmClearGenPre={data => {
                    this.setState({ isBpmClearGenPre: data });
                  }}
                  setSearchPackName={data => {
                    this.setState({ search_pack_name: data });
                  }}
                  isBpmRangeActive={this.state.isBpmRangeActive}
                  setIsBpmRangeActive={data => {
                    this.setState({ isBpmRangeActive: data });
                  }}
                >
                  {/* <Scrollable> */}
                  <React.Suspense fallback={<ZDWaveLoader />}>
                    <Route
                      exact={true}
                      path="/member/logout"
                      component={LogoutPage}
                    />
                    {this.state.chartInput ? (
                      <>
                        <Route
                          exact={true}
                          path="/member/chart-input"
                          render={props => (
                            <ChartInputPage
                              {...props}
                              updateChartInputFlag={this.updateChartInputFlag}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          exact={true}
                          path="/"
                          render={props => (
                            <GenresHomePage
                              {...props}
                              setPageTitle={this.setHeader.bind(this)}
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />
                        <Route
                          exact={true}
                          path="/genres"
                          render={props => (
                            <GenresHomePage
                              {...props}
                              setPageTitle={this.setHeader.bind(this)}
                              headerActiveTab={this.state.headerActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        {/* <Route
                      exact={true}
                      path="/login-modal"
                      component={AuthModalPage}
                    /> */}

                        <Route
                          exact={true}
                          path="/search"
                          render={props => (
                            <SearchResultsPage
                              updateSearchResult={this.state.updateSearchResult}
                              setUpdateSearchResult={data => {
                                this.setState({ updateSearchResult: data });
                              }}
                              act
                              megaMenuShow={this.state.megaMenuShow}
                              handleSearchGenre={this.handleSearchGenre}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="search"
                              pageTitle="Search"
                              setPageTitle={this.setHeader}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                            />
                          )}
                        />
                        <Route
                          exact={true}
                          path="/member/new-playlist"
                          render={props => <NewPlayList />}
                        />

                        <Route
                          exact={true}
                          path="/member/topdownloads"
                          render={props => (
                            <TopDownloadsPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="top-downloads-home"
                              pageTitle="Top Downloads"
                              setPageTitle={this.setHeader}
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member"
                          render={props => (
                            <HomePage
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              AddMediaToPlay={this.getChildAudioListCallback}
                              {...props}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="home-page"
                              pageTitle="Home"
                              setPageTitle={this.setHeader}
                              playAll={this.playAll}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              showMegaMenu={this.showMegaMenu}
                              megaMenuShow={this.state.megaMenuShow}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/genres/subgenre/genres/:id/:id"
                          render={props => (
                            <SubGenrePage
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              AddMediaToPlay={this.getChildAudioListCallback}
                              {...props}
                              childRef={ref => (this.child = ref)}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="top-downloads"
                              pageTitle="Genre"
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/labels"
                          render={props => (
                            <LabelSearchResultsPage
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="labels"
                              pageTitle="Labels"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/releases"
                          render={props => (
                            <ReleaseSearchResultsPage
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="releases"
                              pageTitle="Releases"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/artists"
                          render={props => (
                            <ArtistSearchResultsPage
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="artists"
                              pageTitle="Artists"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/tracks"
                          render={props => (
                            <TrackSearchResultsPage
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="artists"
                              pageTitle="Artists"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/label/view/:id/:id"
                          render={props => (
                            <LabelViewPage
                              {...props}
                              pause={this.state.pause}
                              togglePlay={this.togglePlay}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="label-view"
                              pageTitle="Labels"
                              playAll={this.playAll}
                              AddMediaToPlay={this.getChildAudioListCallback}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/genres/index"
                          render={props => (
                            <GenresPage
                              {...props}
                              setPageTitle={this.setHeader}
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              pageTitle="Genres"
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packsets/PacksView/:id"
                          render={props => (
                            <PacksViewPage
                              {...props}
                              packData={this.state.packData}
                              setPackData={data => {
                                this.setState({ packData: data });
                              }}
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packsets-view"
                              pageTitle="packsets-view"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              searchPack_page={this.state.searchPack_page}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              categoriesData={this.state.categoriesData}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              fromCategories={this.state.fromCategories}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packsets"
                          render={props => (
                            <PacksPage
                              {...props}
                              packData={this.state.packData}
                              setPackData={data => {
                                this.setState({ packData: data });
                              }}
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packsets"
                              pageTitle="Packset"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              searchPack_page={this.state.searchPack_page}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              categoriesData={this.state.categoriesData}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              fromCategories={this.state.fromCategories}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/trending-packs"
                          render={props => (
                            <TrendingPackPage
                              packData={this.state.packData}
                              setPackData={data => {
                                this.setState({ packData: data });
                              }}
                              fromTrendingPackData={
                                this.state.fromTrendingPackData
                              }
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              searchPack_page={this.state.searchPack_page}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="trending-packs"
                              pageTitle="trending-packs"
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                              fromPacks={this.state.fromPacks}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/trending-packs-view"
                          render={props => (
                            <TrendingPackView
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="trending-packs"
                              pageTitle="trending-packs"
                              AddMediaToPlay={this.getChildAudioListCallback}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              fromPacks={this.state.fromPacks}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packfeatures"
                          render={props => (
                            <PackFeaturesPage
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packfeatures"
                              pageTitle="Labels"
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packs"
                          render={props => (
                            <ZDPackListPageES
                              packData={this.state.packData}
                              setPackData={data => {
                                this.setState({ packData: data });
                              }}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packs"
                              pageTitle="ZIPDJ ESSENTIALS"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                              searchPack_page={this.state.searchPack_page}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              fromCategories={this.state.fromCategories}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packs/featured/:feature_id/:id"
                          render={props => (
                            <ZDPackListPageES
                              packData={this.state.packData}
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              setPackData={data => { 
                                this.setState({ packData: data }); 
                              }}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packs"
                              pageTitle="ZIPDJ ESSENTIALS"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              fromCategories={this.state.fromCategories}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packs/:category_id/:id"
                          render={props => (
                            <ZDPackListPageES
                              setFromTrendingPackData={data => {
                                this.setState({ fromTrendingPackData: data });
                              }}
                              packData={this.state.packData}
                              setPackData={data => { 
                                this.setState({ packData: data }); 
                              }}
                              setCategoriesData={data => {
                                this.setState({ categoriesData: data });
                              }}
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="packs"
                              pageTitle="ZIPDJ Packs"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              setFromCategories={data => {
                                this.setState({ fromCategories: data });
                              }}
                              fromCategories={this.state.fromCategories}
                              setSearchPackPage={data => {
                                this.setState({ searchPack_page: data });
                              }}
                              setFromPacks={data => {
                                this.setState({ fromPacks: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/packs/view/:pack_id/:id"
                          render={props => (
                            <ZDPackViewPageES
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="pack_view"
                              pageTitle="ZIPDJ Packs"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />
                        <Route
                          exact={true}
                          path="/member/trending/view/:pack_id/:id"
                          render={props => (
                            <ZDPackViewPageES
                              search_pack_name={this.state.search_pack_name}
                              setSearchPackName={data => {
                                this.setState({ search_pack_name: data });
                              }}
                              fromPacks={this.state.fromPacks}
                              activePackId={this.state.activePackId}
                              setActivePackId={data => {
                                this.setState({ activePackId: data });
                              }}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="trending_view"
                              pageTitle="ZIPDJ Trending Packs"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/download-manager"
                          render={props => (
                            <DownloadManagerPage
                              {...props}
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              refreshDownloadWidget={
                                this.refreshDownloadWidgetStatus
                              }
                              downloadManagerDataFromWidget={
                                this.state.downloadManagerData
                              }
                              dmwNotificationFromPage={
                                this.getdmwNotificationInApp
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/all"
                          render={props => (
                            <AllTrendingChartPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              trendingPage="all"
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              pageType="trending"
                              pageTitle="Trending Charts"
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              setNewTrandingPageType={data => {
                                this.setState({ newTrandingPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />
                        <Route
                          exact={true}
                          path="/member/airplay"
                          render={props => (
                            <AirplayPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              trendingPage="airplay"
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              pageType="trending"
                              pageTitle="Trending Charts"
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              setNewTrandingPageType={data => {
                                this.setState({ newTrandingPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/urban"
                          render={props => (
                            <UrbanPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              trendingPage="urban"
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              pageType="trending"
                              pageTitle="Trending Charts"
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              setNewTrandingPageType={data => {
                                this.setState({ newTrandingPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/electronic"
                          render={props => (
                            <ElectronicPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              trendingPage="electronic"
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              pageType="trending"
                              pageTitle="Trending Charts"
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              setNewTrandingPageType={data => {
                                this.setState({ newTrandingPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/mainstream"
                          render={props => (
                            <MainstreamPage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              trendingPage="mainstream"
                              setPageTitle={this.setHeader}
                              ref={this.child}
                              pageType="trending"
                              pageTitle="Trending Charts"
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              setNewTrandingPageType={data => {
                                this.setState({ newTrandingPageType: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/newrelease"
                          render={props => (
                            <NewReleasesPage
                              {...props}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              viewMode="release"
                              setPageTitle={this.setHeader}
                              childRef={ref => (this.child = ref)}
                              isLoggedIn={this.state.isLoggedIn}
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              pageType="new-releases"
                              pageTitle="New Releases"
                              headerActiveTab={this.state.headerActiveTab}
                              // -------
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/new-release-2"
                          render={props => (
                            <NewReleasesPage
                              {...props}
                              viewMode="release2"
                              setPageTitle={this.setHeader}
                              childRef={ref => (this.child = ref)}
                              isLoggedIn={this.state.isLoggedIn}
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              pageType="new-releases2"
                              pageTitle="New Releases"
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/single"
                          render={props => (
                            <SingleReleasePage
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              {...props}
                              viewMode="release"
                              setPageTitle={this.setHeader}
                              childRef={ref => (this.child = ref)}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="new-releases"
                              pageTitle="New Releases"
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/newrelease/track-view"
                          render={props => (
                            <NewReleasesPage
                              {...props}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              setMegaMenuShow={data => {
                                this.setState({ megaMenuShow: data });
                              }}
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              setNewpageType={data => {
                                this.setState({ newPageType: data });
                              }}
                              viewMode="list"
                              setPageTitle={this.setHeader}
                              childRef={ref => (this.child = ref)}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="new-releases-track-view"
                              headerActiveTab={this.state.headerActiveTab}
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              pageTitle="New Releases"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              showMegaMenu={this.showMegaMenu}
                              handleSearchGenre={this.handleSearchGenre}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              genrePreferenceStatus={
                                this.state.genrePreferenceStatus
                              }
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/genre-view"
                          render={props => (
                            <NewReleasesPage
                              {...props}
                              viewMode="genre-view"
                              setPageTitle={this.setHeader}
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              childRef={ref => (this.child = ref)}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="new-releases-genre-view"
                              pageTitle="Genre View"
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              getGenreNameByID={this.getGenreNameByID}
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              bpmRange={this.state.bpmRange}
                              setBpmRange={data => {
                                this.setState({ bpmRange: data });
                              }}
                              isBpmRangeActive={this.state.isBpmRangeActive}
                              setIsBpmRangeActive={data => {
                                this.setState({ isBpmRangeActive: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                            />
                          )}
                        />
                        <Route
                          exact={true}
                          path="/member/profile"
                          render={props => (
                            <ProfilePage
                              {...props}
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              ref={this.child}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="profile"
                              pageTitle="My Profile"
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              profileFullName={
                                this.state.profileHeaderDetails
                                  ? this.state.profileHeaderDetails
                                      .member_firstname +
                                    ' ' +
                                    this.state.profileHeaderDetails
                                      .member_lastname
                                  : ''
                              }
                              profileEmail={
                                this.state.profileHeaderDetails
                                  ? this.state.profileHeaderDetails.member_email
                                  : ''
                              }
                              profileImageUrl={this.state.profileImageUrl}
                              updateProfileImageURL={this.updateProfileImageURL}
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              changeProfileSection={this.changeProfileSection}
                              profileActiveTab={this.state.profileActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeGenrePreference={
                                this.state.activeGenrePreference
                              }
                              setActiveGenrePreference={data => {
                                this.setState({ activeGenrePreference: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/subscriptions/:active_tab"
                          render={props => (
                            <ProfilePage
                              {...props}
                              setPageTitle={this.setHeader}
                              isLoggedIn={this.state.isLoggedIn}
                              pageType="subscriptions"
                              profileHeaderDetails={
                                this.state.profileHeaderDetails
                              }
                              profileFullName={
                                this.state.profileHeaderDetails
                                  ? this.state.profileHeaderDetails
                                      .member_firstname +
                                    ' ' +
                                    this.state.profileHeaderDetails
                                      .member_lastname
                                  : ''
                              }
                              profileEmail={
                                this.state.profileHeaderDetails
                                  ? this.state.profileHeaderDetails.member_email
                                  : ''
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              profileDetails={this.state.profileDetails}
                              profileImageUrl={this.state.profileImageUrl}
                              updateProfileImageURL={this.updateProfileImageURL}
                              pageTitle="Manage Subscriptions"
                              totalDownloaded={this.state.totalDownloaded}
                              downloadLimit={this.state.downloadLimit}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/charts/dance"
                          render={props => (
                            <ChartPageES
                              {...props}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              activeGenrePreference={
                                this.state.activeGenrePreference
                              }
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              setPageTitle={this.setHeader}
                              chartActive="dance"
                              chartType="comm_dance"
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                              activeTracksList={this.state.activeTracksList}
                              setActiveTracksList={data => {
                                this.setState({ activeTracksList: data });
                              }}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/charts/urban"
                          render={props => (
                            <ChartPageES
                              {...props}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              activeGenrePreference={
                                this.state.activeGenrePreference
                              }
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              chartActive="urban"
                              setPageTitle={this.setHeader}
                              chartType="urban"
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/charts/house"
                          render={props => (
                            <ChartPageES
                              {...props}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              activeGenrePreference={
                                this.state.activeGenrePreference
                              }
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              chartActive="house"
                              setPageTitle={this.setHeader}
                              chartType="house"
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/charts/club"
                          render={props => (
                            <ChartPageES
                              {...props}
                              isBpmClearGenPre={this.state.isBpmClearGenPre}
                              setIsBpmClearGenPre={data => {
                                this.setState({ isBpmClearGenPre: data });
                              }}
                              isBpmRangeTrackActive={
                                this.state.isBpmRangeTrackActive
                              }
                              setIsBpmRangeTrackActive={data => {
                                this.setState({ isBpmRangeTrackActive: data });
                              }}
                              genrePreApiLoad={this.state.genrePreApiLoad}
                              setGenrePreApiLoad={data => {
                                this.setState({ genrePreApiLoad: data });
                              }}
                              setGenrePreferenceStatus={data => {
                                this.setState({ genrePreferenceStatus: data });
                              }}
                              activeGenrePreference={
                                this.state.activeGenrePreference
                              }
                              activePageName={this.state.activePageName}
                              setActivePageName={data =>
                                this.setState({ activePageName: data })
                              }
                              activeReleaseId={this.state.activeReleaseId}
                              activeTrackId={this.state.activeTrackId}
                              setActiveReleaseId={data => {
                                this.setState({ activeReleaseId: data });
                              }}
                              setActiveTrackId={data => {
                                this.setState({ activeTrackId: data });
                              }}
                              playedTracks={this.state.playedTracks}
                              expandedNewReleaseList={
                                this.state.expandedNewReleaseList
                              }
                              setExpandedNewReleaseList={data =>
                                this.setState({ expandedNewReleaseList: data })
                              }
                              chartActive="club"
                              setPageTitle={this.setHeader}
                              chartType="dance"
                              ref={this.child}
                              isLoggedIn={this.state.isLoggedIn}
                              headerActiveTab={this.state.headerActiveTab}
                              getParentAudioList={
                                this.getChildAudioListCallback
                              }
                              addToDownloadManagerParent={
                                this.addToDownloadManagerParentFn
                              }
                              updateDownloadManagerData={
                                this.updateDownloadManagerData
                              }
                              updateDownloadManagerDataByPacks={
                                this.updateDownloadManagerDataByPacks
                              }
                              setHeaderActiveTab={this.setHeaderActiveTab}
                              cratePlaylistValue={this.state.cratePlaylistValue}
                              fetchPlaylistData={this.fetchCratePlaylistData}
                            />
                          )}
                        />

                        <Route
                          exact={true}
                          path="/member/audio"
                          render={props => <AudioWave {...props} />}
                        />
                      </>
                    )}
                  </React.Suspense>

                  <NotificationSystem
                    dismissible={false}
                    allowHTML={true}
                    ref={notificationSystem =>
                      (window.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                  />
                  {/* </Scrollable> */}
                </MainLayout>
                <Redirect to="/" />
              </Switch>
            </ErrorBoundary>
          </GAListener>
        ) : (
          <>
            {process.env.REACT_APP_ENV === 'development' ? (
              <Redirect to="/login" />
            ) : (
              <Redirect to="/" />
            )}
            <LayoutRoute
              exact={true}
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
          </>
        )}
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }
  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
