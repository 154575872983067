/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { IoMdStar } from 'react-icons/io';
import axios from 'axios';
import { zdCheckAuthOnAjax, zdShowGlobalNotification } from 'utils/Helpers';

axios.defaults.timeout = 60000;

export default class ZDFeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      commentErr: '',
      playIt: 2,
      releaseId: '',
      trackId: '',
      trackIdErr: '',
      rating: 10,
      ratingErr: '',
      modal: false,
      showModal: false,
      feedbackHead: '',
      feedbackOption: [],
      feedbackDetails: [],
      errorStatus: false,
      isDownloaded: false,
      defaultComment: {
        2: 'Sorry, I need to think about playing this track.',
        4: 'Nothing groundbreaking but will re-listen.',
        6: "It's okay, I will look to play it soon.",
        8: 'I like this and will play it out in my shows.',
        10: 'Great release. I will definitely support this all the way.',
      },
    };

    this.defaultClick = this.defaultClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addToDownloadManagerFn = this.addToDownloadManagerFn.bind(this);
    this.getFeedbackForm = this.getFeedbackForm.bind(this);
  }

  defaultClick = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleRatingClick = e => {
    var n = e.currentTarget.getAttribute('data-hover_id');
    var count = 1;

    for (var k = 1; k < 6; k++) {
      if (document.querySelector('.zd-reviews-star' + k)) {
        document
          .querySelector('.zd-reviews-star' + k)
          .classList.remove('zd-reviews-star-hover1');
        document
          .querySelector('.zd-reviews-star' + k)
          .classList.remove('zd-reviews-star-hover2');
        document
          .querySelector('.zd-reviews-star' + k)
          .classList.remove('zd-reviews-star-hover3');
        document
          .querySelector('.zd-reviews-star' + k)
          .classList.remove('zd-reviews-star-hover4');
        document
          .querySelector('.zd-reviews-star' + k)
          .classList.remove('zd-reviews-star-hover5');
        document
          .querySelector('.zd-feedback-span-message' + k)
          .classList.remove('active-span');
        document
          .querySelector('.zd-feedback-span-message' + k)
          .classList.remove('active-span');
        document
          .querySelector('.zd-feedback-span-message' + k)
          .classList.remove('active-span');
        document
          .querySelector('.zd-feedback-span-message' + k)
          .classList.remove('active-span');
        document
          .querySelector('.zd-feedback-span-message' + k)
          .classList.remove('active-span');
      }
    }

    for (var i = 0; i < n; i++) {
      document
        .querySelector('.zd-reviews-star' + count)
        .classList.add('zd-reviews-star-hover' + n);
      count++;
    }
    document
      .querySelector('.zd-feedback-span-message' + i)
      .classList.add('active-span');
    let rating_value =
      document.querySelector('.active-span').dataset.rating_value;
    this.setState({ rating: rating_value });
  };

  handleTrackMixSelectValue = e => {
    this.setState({ trackId: e.target.value });
  };

  onPlayItChanged = e => {
    this.setState({ playIt: e.target.value });
  };

  onCommentChange = e => {
    this.setState({ comment: e.target.value });
  };

  handleFeedbackSubmission = e => {
    e.preventDefault();

    let self = this;

    // eslint-disable-next-line eqeqeq
    if (self.state.trackId == 0) {
      self.setState({ trackIdErr: 'Please select your favorite mix.' });
      return false;
    } else {
      self.setState({ trackIdErr: '' });
    }

    // eslint-disable-next-line eqeqeq
    if (self.state.rating == '') {
      self.setState({ ratingErr: 'Please add ratings to this release.' });
      return false;
    } else {
      self.setState({ ratingErr: '' });
    }

    const feedbackdata = self.state.feedbackDetails;

    let release_id = feedbackdata.release_id;
    let rating = self.state.rating;
    let data_id = self.state.trackId;
    let comment = self.state.comment;
    let default_comment =
      this.state.feedbackDetails.feedback_comment[rating].length > 2
        ? this.state.feedbackDetails.feedback_comment[rating]
        : this.state.defaultComment[rating];

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/feedback',
      xSessionToken: loginInfo.token,
    };

    const post_data = {
      release_id: release_id,
      rating: rating,
      data_id: data_id,
      comment: comment,
      default_comment: default_comment,
    };

    let url = api.baseUrl;
    axios
      .post(url, post_data, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data) {
          if (document.getElementById('zd-feedback-modal-' + release_id)) {
            document.getElementById(
              'zd-feedback-modal-' + release_id,
            ).style.display = 'none';
          }

          self.setState({
            showModal: false,
          });

          if (self.props.trackFeedbackStatus === 'true') {
            var add_data = {
              release_id: release_id,
              track_id: data_id,
            };
            self.addToDownloadManagerFn(add_data, function (data) {
              if (data.error) {
                zdShowGlobalNotification(data.message);
              }
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  addToDownloadManagerFn(e, callback) {
    let self = this;
    let track_id = e.track_id;
    let release_id = e.release_id;
    let xajax = 'AddToCart';
    let ajax_type = 'home';

    if (typeof callback == 'undefined') {
      callback = function () {};
    }

    const postData = {
      xajax: xajax,
      xajaxargs: [track_id, release_id, ajax_type],
    };

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/add-track-single',
      xSessionToken: loginInfo.token,
    };

    let url = api.baseUrl;

    axios
      .post(url, postData, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data.error === false) {
          if (data.data) {
            self.props.updateDownloadManagerDetails(data.data);
          }

          if (document.getElementById('download_row_icon_' + track_id)) {
            document.getElementById(
              'download_row_icon_' + track_id,
            ).style.display = 'none';
          }

          if (document.getElementById('track_download_row_icon_' + track_id)) {
            document.getElementById(
              'track_download_row_icon_' + track_id,
            ).style.display = 'none';
          }

          var el = document.body.getElementsByClassName(
            'zd-download-manager-btn',
          );
          for (var i = 0; i < el.length; i++) {
            el[i].classList.add('zd-animate');
          }

          setTimeout(function () {
            var el = document.body.getElementsByClassName(
              'zd-download-manager-btn',
            );
            for (var i = 0; i < el.length; i++) {
              el[i].classList.remove('zd-animate');
            }
          }, 1000);
        }
        callback(data);
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  }

  getFeedbackTrackMixOptions() {
    var data = this.state.feedbackOption;
    var keys = Object.keys(data);
    var values = Object.values(data);
    let items = [];

    for (var i = 0; i < keys.length; i++) {
      if (keys[i] !== 0) {
        if (values[i] !== 'Select Mix') {
          items.push(
            <option key={i} value={keys[i]}>
              {values[i]}
            </option>,
          );
        }
      }
    }
    return items;
  }

  getFeedbackForm(release_id) {
    let self = this;

    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/feedback-form',
      xSessionToken: loginInfo.token,
    };

    const post_data = {
      release_id: release_id,
    };
    let url = api.baseUrl;
    axios
      .post(url, post_data, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data) {
          let feedbackDetails = data.feedback_details;
          self.setState({
            showModal: true,
            releaseId: release_id,
            feedbackDetails: data.feedback_details,
            feedbackHead: feedbackDetails.feedback_head,
            feedbackOption: feedbackDetails.options,
          });
          if (self.props.trackId) {
            self.setState({
              trackId: self.props.trackId,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  showFeedbackModal = e => {
    let self = this;
    var release_id = e.currentTarget.getAttribute('data-release_id');
    if (this.props.trackFeedbackStatus === 'false') {
      self.getFeedbackForm(release_id);
    } else {
      var track_id = e.currentTarget.getAttribute('data-id');
      var data = {
        release_id: release_id,
        track_id: track_id,
      };
      this.addToDownloadManagerFn(data, function (data) {
        if (data.error) {
          if (data.message === 'Please give your feedback!') {
            self.getFeedbackForm(release_id);
          } else {
            zdShowGlobalNotification(data.message);
          }
        }
      });
    }
  };

  render() {
    var data = this.state.feedbackOption;
    var optionlen = Object.keys(data).length;
    var id =
      this.props.trackFeedbackStatus === 'true'
        ? 'track_download_row_icon_' + this.props.trackId
        : 'zd-feedback-modal-' + this.props.dataId;

    return (
      <div className="zd-feedback-modal">
        {this.props.trackFeedbackStatus === 'true' ? (
          this.props.is_labels ? (
            <a
              onClick={this.showFeedbackModal}
              className="zd-download-btn btn btn-action btn-link icon zip-download-2 downBtn"
              data-id={this.props.trackId}
              data-name="Feedback"
              data-feedback="Yes"
              id={id}
              data-release_id={this.props.dataId}
            >
              <i className="zd-icon-download-2"></i>
            </a>
          ) : (
            <a
              onClick={this.showFeedbackModal}
              className={
                'zd-download-btn feedback favourite track_download_row_icon_' +
                this.props.trackId
              }
              data-id={this.props.trackId}
              data-name="Feedback"
              data-feedback="Yes"
              id={id}
              style={
                !this.props.isDownloaded
                  ? { display: 'inline-block' }
                  : { display: 'none' }
              }
              data-release_id={this.props.dataId}
            >
              <i className="zd-player-icon-download"></i>
            </a>
          )
        ) : (
          <a
            onClick={this.showFeedbackModal}
            title="Feedback"
            className="zd-download-btn feedback favourite"
            data-id="12"
            data-name="Feedback"
            data-feedback="Yes"
            id={id}
            data-release_id={this.props.dataId}
          >
            <i className="ico-comment"></i>
          </a>
        )}
        <Modal
          isOpen={this.state.showModal}
          className="zd-feedback-modal-content"
        >
          <ModalHeader toggle={this.toggleModal}>Add Feedback</ModalHeader>
          <ModalBody className="zd-feedback-modal-body-content">
            <div className="content modalscroll">
              <div className="zd-modal-wrap">
                <div className="zd-modal-content">
                  <Form
                    className="form-horizontal zd-profile-form zd-modal-form"
                    method="post"
                  >
                    <FormGroup row={true}>
                      <Col colSpan="2">
                        <h6>{this.state.feedbackHead}</h6>
                      </Col>
                    </FormGroup>
                    <FormGroup row={true}>
                      <Col sm="12" md="5">
                        <Label className="form-label">
                          Favorite Mix:{' '}
                          <span className="req" style={{ color: 'red' }}>
                            *
                          </span>
                        </Label>
                      </Col>
                      <Col sm="12" md="7" className="f-left">
                        <Input
                          type="select"
                          defaultValue={this.props.trackId}
                          name="select"
                          id="zd-feedback-favorite-mix"
                          className="zd-feedback-favorite-mix"
                          onChange={this.handleTrackMixSelectValue}
                        >
                          <option value={0}>Select Mix</option>
                          {optionlen > 0 ? (
                            this.getFeedbackTrackMixOptions()
                          ) : (
                            <option>Select</option>
                          )}
                        </Input>
                        <p className="text-danger zd-modal-form-error-msg">
                          {this.state.trackIdErr}
                        </p>
                      </Col>
                    </FormGroup>

                    <FormGroup row={true}>
                      <Col sm="12" md="5">
                        <Label className="form-label">
                          Rating:
                          <span className="req" style={{ color: 'red' }}>
                            *
                          </span>
                        </Label>
                      </Col>
                      <Col sm="12" md="7" className="f-left">
                        <IoMdStar
                          data-hover_id={1}
                          className="zd-reviews-star1 zd-reviews-star-hover5"
                          size={28}
                          onClick={this.handleRatingClick}
                        />
                        <IoMdStar
                          data-hover_id={2}
                          className="zd-reviews-star2 zd-reviews-star-hover5"
                          size={28}
                          onClick={this.handleRatingClick}
                        />
                        <IoMdStar
                          data-hover_id={3}
                          className="zd-reviews-star3 zd-reviews-star-hover5"
                          size={28}
                          onClick={this.handleRatingClick}
                        />
                        <IoMdStar
                          data-hover_id={4}
                          className="zd-reviews-star4 zd-reviews-star-hover5"
                          size={28}
                          onClick={this.handleRatingClick}
                        />
                        <IoMdStar
                          data-hover_id={5}
                          className="zd-reviews-star5 zd-reviews-star-hover5"
                          size={28}
                          onClick={this.handleRatingClick}
                        />
                        <span
                          className="zd-feedback-span-message zd-feedback-span-message1"
                          data-rating_value="2"
                        >
                          {this.state.feedbackDetails.feedback_comment !==
                            undefined &&
                          this.state.feedbackDetails.feedback_comment[2]
                            .length > 0
                            ? this.state.feedbackDetails.feedback_comment[2]
                            : this.state.defaultComment['2']}
                        </span>
                        <span
                          className="zd-feedback-span-message zd-feedback-span-message2"
                          data-rating_value="4"
                        >
                          {this.state.feedbackDetails.feedback_comment !==
                            undefined &&
                          this.state.feedbackDetails.feedback_comment[4]
                            .length > 0
                            ? this.state.feedbackDetails.feedback_comment[4]
                            : this.state.defaultComment['4']}
                        </span>
                        <span
                          className="zd-feedback-span-message zd-feedback-span-message3"
                          data-rating_value="6"
                        >
                          {this.state.feedbackDetails.feedback_comment !==
                            undefined &&
                          this.state.feedbackDetails.feedback_comment[6]
                            .length > 0
                            ? this.state.feedbackDetails.feedback_comment[6]
                            : this.state.defaultComment['6']}
                        </span>
                        <span
                          className="zd-feedback-span-message zd-feedback-span-message4"
                          data-rating_value="8"
                        >
                          {this.state.feedbackDetails.feedback_comment !==
                            undefined &&
                          this.state.feedbackDetails.feedback_comment[8]
                            .length > 0
                            ? this.state.feedbackDetails.feedback_comment[8]
                            : this.state.defaultComment['8']}
                        </span>
                        <span
                          className="zd-feedback-span-message zd-feedback-span-message5 active-span"
                          data-rating_value="10"
                        >
                          {this.state.feedbackDetails.feedback_comment !==
                            undefined &&
                          this.state.feedbackDetails.feedback_comment[10]
                            .length > 0
                            ? this.state.feedbackDetails.feedback_comment[10]
                            : this.state.defaultComment['10']}
                        </span>
                        <p className="text-danger zd-modal-form-error-msg">
                          {this.state.ratingErr}
                        </p>
                      </Col>
                    </FormGroup>

                    <FormGroup row={true}>
                      <Col sm="12" md="5">
                        <Label className="form-label">Will you play it?</Label>
                      </Col>
                      <Col sm="12" md="7" className="f-left">
                        <Label className="form-radio">
                          <Input
                            type="radio"
                            name="playit"
                            value="2"
                            defaultChecked="defaultChecked"
                            onChange={this.onPlayItChanged}
                          />
                          <i className="form-icon"></i>
                          Yes
                        </Label>
                        <Label className="form-radio">
                          <Input
                            type="radio"
                            name="playit"
                            value="0"
                            onChange={this.onPlayItChanged}
                          />
                          <i className="form-icon"></i>
                          No
                        </Label>
                        <Label className="form-radio">
                          <Input
                            type="radio"
                            name="playit"
                            value="0"
                            onChange={this.onPlayItChanged}
                          />
                          <i className="form-icon"></i>Maybe
                        </Label>
                        <p className="text-danger zd-modal-form-error-msg">
                          {this.state.clubNightErr}
                        </p>
                      </Col>
                    </FormGroup>

                    <FormGroup row={true}>
                      <Col sm="12" md="5">
                        <Label className="form-label">Comment</Label>
                      </Col>
                      <Col sm="12" md="7" className="f-left">
                        <Input
                          type="textarea"
                          placeholder="Insert personal DJ/quote/comment here (this will be tweeted so be polite)"
                          name="text"
                          id="exampleText"
                          onChange={this.onCommentChange}
                        />
                        {/*<p className="text-danger zd-modal-form-error-msg">{this.state.commentErr}</p>*/}
                      </Col>
                    </FormGroup>

                    <FormGroup row={true}>
                      <Col sm={{ size: 10, offset: 4 }}>
                        <Button
                          color="primary"
                          onClick={this.handleFeedbackSubmission}
                        >
                          Submit Feedback
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
