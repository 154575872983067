import zipDJLogoImage from 'assets/img/logo/zipdj-logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { MdWarning } from 'react-icons/md';

axios.defaults.timeout = 60000;

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      username: '',
      email: '',
      password: '',
      cpassword: '',
      cbox: '',
      token: '',
      isLoggedIn: false,
      errorMessage: '',
    };
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  componentDidMount() {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo) {
      if (loginInfo._zd_tk && loginInfo.isLoggedIn) {
        this.props.history.push('/member');
      } else {
        // return;
        localStorage.removeItem('_zd_tk');
        localStorage.removeItem('_zd_user_name');
        localStorage.removeItem('default_url');
        window.location.href = process.env.REACT_APP_LOGIN_PATH;
      }
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    document
      .getElementsByClassName('zd-login-spinner')[0]
      .classList.remove('zd-hide-spinner');

    if (this.isLogin) {
      let me = this;
      const fields = {
        username: me.state.username,
        password: me.state.password,
        cbox: me.state.cbox,
      };

      axios
        .post(process.env.REACT_APP_API_PATH + '/zdmapi/auth/login', fields)
        .then(res => {
          if (res.data.error === false) {
            if (res.data['user_perm'] != 'Member') {
              localStorage.removeItem('_zd_tk');
              return;
            }
            this.setState({
              token: res.data.auth_token,
              loggedIn: true,
            });
            localStorage.setItem('default_url', res.data.red_url);
            const userInfo = {
              token: res.data.auth_token,
              isLoggedIn: true,
            };
            localStorage.setItem('_zd_tk', JSON.stringify(userInfo));
            let auth_data = res.data;
            if (
              typeof process.env.REACT_APP_BILLING_PATH === 'string' &&
              process.env.REACT_APP_BILLING_PATH
            ) {
              axios
                .post(process.env.REACT_APP_BILLING_PATH + '/dologin.php', {
                  username: fields['username'],
                  password: fields['password'],
                  rememberme: 'on',
                })
                .then(res => {
                  window.zdJsonpLogin(auth_data);
                });
            } else {
              window.memberPageLoad(auth_data);
            }

            if (document.getElementsByClassName('zd-login-spinner')[0]) {
              document
                .getElementsByClassName('zd-login-spinner')[0]
                .classList.add('zd-hide-spinner');
            }
          } else {
            this.setState({ errorMessage: res.data.message });
            document
              .getElementsByClassName('zd-login-spinner')[0]
              .classList.add('zd-hide-spinner');
            setTimeout(() => {
              if (!this.notificationSystem) {
                return;
              }

              this.notificationSystem.addNotification({
                title: <MdWarning />,
                message: res.data.message,
                level: 'info',
              });
            }, 1500);
          }
        });
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      nameLabel,
      nameInputProps,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={zipDJLogoImage}
              className="rounded"
              style={{ width: 135, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        {this.isSignup && (
          <FormGroup>
            <Label for={nameLabel}>{nameLabel}</Label>
            <Input
              {...nameInputProps}
              onChange={e => this.setState({ name: e.target.value })}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input
            {...usernameInputProps}
            onChange={e => this.setState({ username: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input
            {...passwordInputProps}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input
              {...confirmPasswordInputProps}
              onChange={e => this.setState({ cpassword: e.target.value })}
            />

            <div>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={e => this.setState({ cbox: e.target.value })}
                />{' '}
                Agree the terms and policy
              </Label>
            </div>
          </FormGroup>
        )}

        {this.state.errorMessage && (
          <Alert color="danger">
            {' '}
            <MdWarning /> {this.state.errorMessage}{' '}
          </Alert>
        )}

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />

        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          disabled={!this.state.password || !this.state.username}
          block
          onClick={this.handleSubmit}
        >
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            className="zd-hide-spinner zd-login-spinner"
            aria-hidden="true"
          />

          {this.renderButtonText()}
        </Button>

        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="/account/login.php">Signup</a>
            )}
          </h6>
        </div>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  nameInputProps: PropTypes.object,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  nameLabel: 'Name',
  nameInputProps: {
    type: 'text',
    placeholder: 'Your full name',
  },
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => {},
};

export default withRouter(AuthForm);
