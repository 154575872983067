import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import ReactGA from 'react-ga';

const hasGAId = !!process.env.REACT_APP_GOOGLE_ANALYTICS;

if (hasGAId) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    if (hasGAId) {
      this.sendPageView();
      this.props.history.listen(this.sendPageView);
    }
  }

  sendPageView() {
    var page_path = window.location.pathname + window.location.search;
    ReactGA.set({ page: page_path });
    ReactGA.pageview(page_path);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(GAListener);
