import React from 'react';

const ZDMediaQueueIcon = ({className}) => {
    const IconClassNames = (className) ? `zd-media-queue-icon ${className}` : 'zd-media-queue-icon';
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32" className={IconClassNames} stroke="currentColor" fill="currentColor">
    <path d="M16 10.033h13v1.467h-13z"></path>
    <path d="M11 16.533h18v1.467h-18z"></path>
    <path d="M11 23.033h18v1.467h-18z"></path>
    <path d="M7.607 5.634v10.005h-1.467v-10.005z"></path>
    <path d="M11.876 11.369h-10.005v-1.467h10.005z"></path>
    </svg>
  );
};
export default ZDMediaQueueIcon;
