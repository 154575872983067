import React, { useState, useEffect } from 'react';
// import BottomBarSelector from "../megaMenuComponents/BottomBarSelector";
import AllTag from './AllTag';
import './BottomBar.css';
import axios from 'axios';
import gsap, { Power4 } from 'gsap';
import { removeTags } from '../../utils/GlobalVariables';
import { MEGA_MENU_TAGS } from '../../@const/megamenuTags';

axios.defaults.timeout = 60000;

const BottomBar = props => {
  let opacity = 1;

  const [isMobile, setIsMobile] = useState(false);
  // const [showAllTags, setShowAllTags] = useState(false);
  const [allTagActive, setAllTagActive] = useState(false);
  const [bottomBarHeight, setBottomBarHeight] = useState('submenu');
  const [subText, setSubText] = useState('DEFAULT VIEW');
  const [genreTags, setGenreTags] = useState(MEGA_MENU_TAGS);
  const [Allgenre, setAllgenre] = useState();
  // const [tags, setTags] = useState([]);

  // const [showTags, setShowTags] = useState([]);

  const [dynamicTags, setDynamicTags] = useState([]);

  const [selectedLetterIndex, setSelectedLetterIndex] = useState(0);

  useEffect(() => {
    // Calling Function to check Display Size

    displaySizeCheck();

    // Calling The async function
    immediate();
  }, []);

  // All The Functions That need to be called on useEffect

  const immediate = async () => {
    // Calling API CAll Function
    await apiCallToGetTags();

    // Run Animations
    await animation();

    // await initialTags();
  };

  // ! Axios call To get all Tags from database
  const apiCallToGetTags = () => {
    // const URL = process.env.REACT_APP_API_PATH + '/zdmapi/get-all-genres';
    // let loginInfo = localStorage.getItem('_zd_tk');
    // loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    // const post_data = {
    //   type: 'all',
    // };

    // axios
    //   .post(URL, post_data, {
    //     headers: { 'X-Session-Token': loginInfo.token },
    //   })
    //   .then(response => {

      const response = {data: {
        "error": false,
        "combine_genre_tag": [
            {
                "tag_key": "Acapellas",
                "tag_id": 102,
                "cnt": 3676
            },
            {
                "genre_id": 59,
                "genre_title": "Accapellas"
            },
            {
                "genre_id": 30,
                "genre_title": "Accapellas/DJ Tools"
            },
            {
                "genre_id": 44,
                "genre_title": "Adult Contemporary"
            },
            {
                "tag_key": "Afro / Latin / Brazilian",
                "tag_id": 65,
                "cnt": 967
            },
            {
                "tag_key": "Afro House",
                "tag_id": 19,
                "cnt": 10345
            },
            {
                "tag_key": "Afrobeats / Amapiano",
                "tag_id": 130,
                "cnt": 3438
            },
            {
                "tag_key": "Ambient",
                "tag_id": 106,
                "cnt": 32
            },
            {
                "tag_key": "Bachata",
                "tag_id": 137,
                "cnt": 112
            },
            {
                "tag_id": 107,
                "tag_key": "Bass"
            },
            {
                "tag_key": "Bass House",
                "tag_id": 21,
                "cnt": 6595
            },
            {
                "tag_key": "Big Room",
                "tag_id": 35,
                "cnt": 13082
            },
            {
                "genre_id": 65,
                "genre_title": "Big Room/EDM"
            },
            {
                "genre_id": 35,
                "genre_title": "Big Room/Future House/Electro"
            },
            {
                "tag_key": "Breaks",
                "tag_id": 30,
                "cnt": 1899
            },
            {
                "tag_key": "Broken Beat / Nu-Jazz",
                "tag_id": 120,
                "cnt": 155
            },
            {
                "genre_id": 26,
                "genre_title": "Chill/Lounge/Downtempo"
            },
            {
                "genre_id": 88,
                "genre_title": "Classic House"
            },
            {
                "tag_key": "Classics",
                "tag_id": 143,
                "cnt": 93
            },
            {
                "tag_key": "Country",
                "tag_id": 140,
                "cnt": 13940
            },
            {
                "tag_key": "Cumbia",
                "tag_id": 136,
                "cnt": 33
            },
            {
                "tag_key": "DJ Tools",
                "tag_id": 52,
                "cnt": 1944
            },
            {
                "tag_key": "Dance",
                "tag_id": 10,
                "cnt": 79968
            },
            {
                "genre_id": 27,
                "genre_title": "Dance Commercial/Mainstream Club"
            },
            {
                "tag_key": "Deep House",
                "tag_id": 13,
                "cnt": 29111
            },
            {
                "genre_id": 52,
                "genre_title": "Deep House/Indie Dance/Nu Disco"
            },
            {
                "tag_key": "Dirty Dubstep / Trap / Grime",
                "tag_id": 43,
                "cnt": 122
            },
            {
                "genre_id": 89,
                "genre_title": "Disco / Old School"
            },
            {
                "tag_id": 50,
                "tag_key": "Disco/Nu-Disco"
            },
            {
                "tag_id": 105,
                "tag_key": "Downtempo"
            },
            {
                "tag_key": "Drum & Bass",
                "tag_id": 37,
                "cnt": 3102
            },
            {
                "tag_key": "Dubstep",
                "tag_id": 5,
                "cnt": 89
            },
            {
                "genre_id": 23,
                "genre_title": "Dubstep/D&B/Future Bass"
            },
            {
                "tag_key": "Electro",
                "tag_id": 61,
                "cnt": 1727
            },
            {
                "tag_key": "Electro House",
                "tag_id": 18,
                "cnt": 3952
            },
            {
                "tag_key": "Electronica",
                "tag_id": 34,
                "cnt": 10739
            },
            {
                "tag_key": "Electronica / Downtempo",
                "tag_id": 33,
                "cnt": 2747
            },
            {
                "genre_id": 31,
                "genre_title": "Electronica/Experimental"
            },
            {
                "tag_key": "Euro Dance / Pop Dance",
                "tag_id": 17,
                "cnt": 725
            },
            {
                "genre_id": 28,
                "genre_title": "Euro/Freestyle"
            },
            {
                "tag_key": "Experimental / Electronic",
                "tag_id": 59,
                "cnt": 115
            },
            {
                "tag_key": "Funky / Groove / Jackin' House",
                "tag_id": 14,
                "cnt": 17488
            },
            {
                "genre_id": 71,
                "genre_title": "Funky/Groove/Jackin' House"
            },
            {
                "genre_id": 34,
                "genre_title": "Funky/Jackin'/Groovy/Soulful"
            },
            {
                "tag_key": "Future House",
                "tag_id": 20,
                "cnt": 6480
            },
            {
                "tag_key": "Garage / Bassline / Grime",
                "tag_id": 72,
                "cnt": 1839
            },
            {
                "tag_key": "Hard Dance / Hardcore",
                "tag_id": 39,
                "cnt": 1866
            },
            {
                "genre_id": 81,
                "genre_title": "Hard Dance/Hardcore"
            },
            {
                "tag_id": 83,
                "tag_key": "Hip Hop / R&B"
            },
            {
                "genre_id": 83,
                "genre_title": "Hip Hop/Rap"
            },
            {
                "tag_key": "Hip-Hop / R&B",
                "tag_id": 2,
                "cnt": 116305
            },
            {
                "tag_key": "Holiday",
                "tag_id": 145,
                "cnt": 319
            },
            {
                "tag_key": "House",
                "tag_id": 6,
                "cnt": 57972
            },
            {
                "genre_id": 37,
                "genre_title": "House/Vocal House/Bass House"
            },
            {
                "tag_key": "Indie",
                "tag_id": 49,
                "cnt": 23592
            },
            {
                "tag_key": "Indie Dance / Nu Disco",
                "tag_id": 12,
                "cnt": 8430
            },
            {
                "genre_id": 69,
                "genre_title": "Indie Dance/Nu Disco"
            },
            {
                "tag_id": 32,
                "tag_key": "Jackin House"
            },
            {
                "tag_id": 125,
                "tag_key": "Jazz"
            },
            {
                "tag_key": "Latin / Reggaeton",
                "tag_id": 141,
                "cnt": 13653
            },
            {
                "tag_key": "Lounge / Chill Out",
                "tag_id": 55,
                "cnt": 1983
            },
            {
                "tag_key": "Melodic House & Techno",
                "tag_id": 27,
                "cnt": 25547
            },
            {
                "tag_key": "Merengue",
                "tag_id": 138,
                "cnt": 48
            },
            {
                "tag_key": "Minimal",
                "tag_id": 26,
                "cnt": 17785
            },
            {
                "genre_id": 76,
                "genre_title": "Minimal/Deep Tech"
            },
            {
                "tag_key": "Miscellaneous",
                "tag_id": 38,
                "cnt": 693
            },
            {
                "tag_id": 31,
                "tag_key": "Nu Disco / Indie Dance"
            },
            {
                "tag_key": "Organic House / Downtempo",
                "tag_id": 131,
                "cnt": 9452
            },
            {
                "tag_key": "Pop",
                "tag_id": 23,
                "cnt": 25879
            },
            {
                "tag_id": 73,
                "tag_key": "Pop Trance"
            },
            {
                "tag_id": 47,
                "tag_key": "Progressive"
            },
            {
                "tag_key": "Progressive House",
                "tag_id": 9,
                "cnt": 26284
            },
            {
                "tag_key": "Psy-Trance",
                "tag_id": 48,
                "cnt": 614
            },
            {
                "genre_id": 25,
                "genre_title": "R&B/Soul"
            },
            {
                "tag_id": 44,
                "tag_key": "Reggae / Dancehall / Dub"
            },
            {
                "tag_key": "Reggae / Dub",
                "tag_id": 44,
                "cnt": 11687
            },
            {
                "genre_id": 19,
                "genre_title": "Reggae/Dancehall"
            },
            {
                "genre_id": 85,
                "genre_title": "Reggae/Dancehall/Dub"
            },
            {
                "genre_id": 86,
                "genre_title": "Reggaeton/Moombahton"
            },
            {
                "tag_key": "Rock",
                "tag_id": 46,
                "cnt": 9755
            },
            {
                "genre_id": 8,
                "genre_title": "Rock/Alternative"
            },
            {
                "tag_key": "Salsa",
                "tag_id": 139,
                "cnt": 108
            },
            {
                "tag_key": "Soca / Calypso",
                "tag_id": 142,
                "cnt": 1251
            },
            {
                "genre_id": 42,
                "genre_title": "Soca/Calypso"
            },
            {
                "tag_key": "Soul / Funk / Disco",
                "tag_id": 40,
                "cnt": 1184
            },
            {
                "tag_key": "Soulful House",
                "tag_id": 15,
                "cnt": 4321
            },
            {
                "tag_key": "Tech House",
                "tag_id": 11,
                "cnt": 46794
            },
            {
                "genre_id": 33,
                "genre_title": "Tech House/Deep Tech/Minimal"
            },
            {
                "tag_key": "Techno",
                "tag_id": 7,
                "cnt": 29
            },
            {
                "tag_key": "Techno (Deep)",
                "tag_id": 114,
                "cnt": 5753
            },
            {
                "tag_key": "Techno (Peak Time)",
                "tag_id": 110,
                "cnt": 23402
            },
            {
                "genre_id": 36,
                "genre_title": "Techno/Melodic/Progressive House"
            },
            {
                "genre_id": 45,
                "genre_title": "Top 40"
            },
            {
                "tag_key": "Trance",
                "tag_id": 8,
                "cnt": 16919
            },
            {
                "genre_id": 87,
                "genre_title": "Trap"
            },
            {
                "tag_key": "Trap / Future Bass",
                "tag_id": 25,
                "cnt": 2601
            },
            {
                "genre_id": 64,
                "genre_title": "Trap/Future Bass"
            },
            {
                "genre_id": 56,
                "genre_title": "Trap/Twerk"
            },
            {
                "tag_key": "Tropical House",
                "tag_id": 91,
                "cnt": 150
            },
            {
                "genre_id": 2,
                "genre_title": "Urban"
            },
            {
                "genre_id": 40,
                "genre_title": "Urban DJ Tools"
            }
        ],
        "tags": [
            {
                "tag_key": "Acapellas",
                "tag_id": 102,
                "cnt": 3676
            },
            {
                "tag_key": "Afro / Latin / Brazilian",
                "tag_id": 65,
                "cnt": 967
            },
            {
                "tag_key": "Afro House",
                "tag_id": 19,
                "cnt": 10345
            },
            {
                "tag_key": "Afrobeats / Amapiano",
                "tag_id": 130,
                "cnt": 3438
            },
            {
                "tag_key": "Ambient",
                "tag_id": 106,
                "cnt": 32
            },
            {
                "tag_key": "Bachata",
                "tag_id": 137,
                "cnt": 112
            },
            {
                "tag_key": "Bass House",
                "tag_id": 21,
                "cnt": 6595
            },
            {
                "tag_key": "Big Room",
                "tag_id": 35,
                "cnt": 13082
            },
            {
                "tag_key": "Breaks",
                "tag_id": 30,
                "cnt": 1899
            },
            {
                "tag_key": "Broken Beat / Nu-Jazz",
                "tag_id": 120,
                "cnt": 155
            },
            {
                "tag_key": "Classics",
                "tag_id": 143,
                "cnt": 93
            },
            {
                "tag_key": "Country",
                "tag_id": 140,
                "cnt": 13940
            },
            {
                "tag_key": "Cumbia",
                "tag_id": 136,
                "cnt": 33
            },
            {
                "tag_key": "Dance",
                "tag_id": 10,
                "cnt": 79968
            },
            {
                "tag_key": "Deep House",
                "tag_id": 13,
                "cnt": 29111
            },
            {
                "tag_key": "Dirty Dubstep / Trap / Grime",
                "tag_id": 43,
                "cnt": 122
            },
            {
                "tag_key": "DJ Tools",
                "tag_id": 52,
                "cnt": 1944
            },
            {
                "tag_key": "Drum & Bass",
                "tag_id": 37,
                "cnt": 3102
            },
            {
                "tag_key": "Dubstep",
                "tag_id": 5,
                "cnt": 89
            },
            {
                "tag_key": "Electro",
                "tag_id": 61,
                "cnt": 1727
            },
            {
                "tag_key": "Electro House",
                "tag_id": 18,
                "cnt": 3952
            },
            {
                "tag_key": "Electronica",
                "tag_id": 34,
                "cnt": 10739
            },
            {
                "tag_key": "Electronica / Downtempo",
                "tag_id": 33,
                "cnt": 2747
            },
            {
                "tag_key": "Euro Dance / Pop Dance",
                "tag_id": 17,
                "cnt": 725
            },
            {
                "tag_key": "Experimental / Electronic",
                "tag_id": 59,
                "cnt": 115
            },
            {
                "tag_key": "Funky / Groove / Jackin' House",
                "tag_id": 14,
                "cnt": 17488
            },
            {
                "tag_key": "Future House",
                "tag_id": 20,
                "cnt": 6480
            },
            {
                "tag_key": "Garage / Bassline / Grime",
                "tag_id": 72,
                "cnt": 1839
            },
            {
                "tag_key": "Hard Dance / Hardcore",
                "tag_id": 39,
                "cnt": 1866
            },
            {
                "tag_key": "Hip-Hop / R&B",
                "tag_id": 2,
                "cnt": 116305
            },
            {
                "tag_key": "Hip-Hop / R&B",
                "tag_id": 53,
                "cnt": 26
            },
            {
                "tag_key": "Holiday",
                "tag_id": 145,
                "cnt": 319
            },
            {
                "tag_key": "House",
                "tag_id": 6,
                "cnt": 57972
            },
            {
                "tag_key": "Indie",
                "tag_id": 49,
                "cnt": 23592
            },
            {
                "tag_key": "Indie Dance / Nu Disco",
                "tag_id": 12,
                "cnt": 8430
            },
            {
                "tag_key": "Indie Dance / Nu Disco",
                "tag_id": 16,
                "cnt": 10679
            },
            {
                "tag_key": "Latin / Reggaeton",
                "tag_id": 141,
                "cnt": 13653
            },
            {
                "tag_key": "Lounge / Chill Out",
                "tag_id": 55,
                "cnt": 1983
            },
            {
                "tag_key": "Melodic House & Techno",
                "tag_id": 27,
                "cnt": 25547
            },
            {
                "tag_key": "Merengue",
                "tag_id": 138,
                "cnt": 48
            },
            {
                "tag_key": "Minimal",
                "tag_id": 26,
                "cnt": 17785
            },
            {
                "tag_key": "Miscellaneous",
                "tag_id": 38,
                "cnt": 693
            },
            {
                "tag_key": "Organic House / Downtempo",
                "tag_id": 131,
                "cnt": 9452
            },
            {
                "tag_key": "Pop",
                "tag_id": 23,
                "cnt": 25879
            },
            {
                "tag_key": "Progressive House",
                "tag_id": 9,
                "cnt": 26284
            },
            {
                "tag_key": "Psy-Trance",
                "tag_id": 48,
                "cnt": 614
            },
            {
                "tag_key": "Reggae / Dub",
                "tag_id": 44,
                "cnt": 11687
            },
            {
                "tag_key": "Rock",
                "tag_id": 46,
                "cnt": 9755
            },
            {
                "tag_key": "Salsa",
                "tag_id": 139,
                "cnt": 108
            },
            {
                "tag_key": "Soca / Calypso",
                "tag_id": 142,
                "cnt": 1251
            },
            {
                "tag_key": "Soul / Funk / Disco",
                "tag_id": 40,
                "cnt": 1184
            },
            {
                "tag_key": "Soulful House",
                "tag_id": 15,
                "cnt": 4321
            },
            {
                "tag_key": "Tech House",
                "tag_id": 11,
                "cnt": 46794
            },
            {
                "tag_key": "Techno",
                "tag_id": 7,
                "cnt": 29
            },
            {
                "tag_key": "Techno (Deep)",
                "tag_id": 114,
                "cnt": 5753
            },
            {
                "tag_key": "Techno (Peak Time)",
                "tag_id": 110,
                "cnt": 23402
            },
            {
                "tag_key": "Trance",
                "tag_id": 8,
                "cnt": 16919
            },
            {
                "tag_key": "Trap / Future Bass",
                "tag_id": 25,
                "cnt": 2601
            },
            {
                "tag_key": "Tropical House",
                "tag_id": 91,
                "cnt": 150
            }
        ],
        "data": [
            ""
        ],
        "message": "Tags fetch successfully!"
        }}
        if (response.data.tags.length > 0) {
          setGenreTags(response.data.combine_genre_tag);

          // setTags(response.data.tags);
          // Set Dynamic Tags

          for (let i = 65; i <= 90; i++) {
            let isActive = false;
            let hasTags = false;
            if (i === 65) {
              isActive = true;
            }
            // Getting The Char Code Value i.e. [A-Z]
            let heading = String.fromCharCode(i);
            const fetchedTags = response.data.combine_genre_tag.filter(tag => {
              if (tag.tag_key) {
                if (
                  tag.tag_key.startsWith(heading) &&
                  !removeTags.includes(tag.tag_id)
                ) {
                  return tag;
                }
              } else {
                if (
                  tag.genre_title.startsWith(heading) &&
                  !removeTags.includes(tag.genre_id)
                ) {
                  return tag;
                }
              }
            });

            if (fetchedTags.length > 0) {
              hasTags = true;
            }
            props.setDynamicTags(prevDynamicTags => [
              ...prevDynamicTags,
              {
                heading: heading,
                isActive: isActive,
                hasTags: hasTags,
                children: fetchedTags,
              },
            ]);
          }
        }
      // })
      // .catch(err => {
      //   console.log(err);
      // });
  };

  // Toggle between showing all tags and Particular Tags
  // const allTagHandeler = e => {
  //   let modalIndexElement = document.getElementById('bottombar');
  //   let topPosition = modalIndexElement.offsetTop;

  //   setAllTagActive(!allTagActive);
  //   if (subText === 'VIEW ALL') {
  //     setSubText('DEFAULT VIEW');
  //   } else {
  //     setSubText('VIEW ALL');
  //   }
  //   bottomBarHeight === ''
  //     ? setBottomBarHeight('submenu')
  //     : setBottomBarHeight('');

  //   setTimeout(() => {
  //     document.getElementsByClassName('zd-megagenre-parent')[0].scrollTo({
  //       top: topPosition,
  //       behavior: 'smooth',
  //     });
  //   }, 100);
  // };

  // Setting The Display Size
  const displaySizeCheck = () => {
    if (window.innerWidth <= 1224 || window.innerWidth >= 2200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener('resize', () => {
    displaySizeCheck();
  });

  // Visibility Of All Tags Handler (Opacity of Bottom Tags)
  const genersVisibilityHandler = () => {
    props.setShowAllTags(!props.showAllTags);
    const tl = gsap.timeline();
    if (!props.showAllTags) {
      tl.from('.bottombar', {
        opacity: 0,
        y: 40,
        ease: Power4.easeIn,
      }).from('.tag-container', {
        opacity: 0,
      });
    } else {
      tl.set('.bottombar', { clearProps: 'all' });
      tl.set('.tag-container', { clearProps: 'all' });
    }
  };

  // Initial Tags That starts with letter A
  // const initialTags = () => {
  //   setShowTags(
  //     tags.filter(tag => {
  //       return tag.tag_key.startsWith('A');
  //     }),
  //   );
  // };

  // Const Animations
  const animation = () => {
    // Starting of Animation
    if (!isMobile) {
      const tl = gsap.timeline();
      tl.from('.bottombar', {
        opacity: 0,
        y: 50,
      })
        .from('.letter-align', {
          opacity: 0,
          x: 20,
          stagger: {
            amount: 0.4,
          },
        })
        .from('.bottombar__tag', {
          opacity: 0,
          x: 20,
          stagger: {
            amount: 0.4,
          },
        });
    }
  };

  // Show Perticular Tags According to the letter selected
  const handleClick = e => {
    e.preventDefault();
    const tl = gsap.timeline();
    tl.to('.bottombar__tags', 0.4, {
      y: 20,
      opacity: 0,
    });
    const wasActive = props.dynamicTags.findIndex(tag => tag.isActive === true);
    const selectedIndex = props.dynamicTags.findIndex(
      tag => tag.heading === e.target.innerHTML,
    );
    let newArr = [...props.dynamicTags];
    newArr[wasActive].isActive = false;
    newArr[selectedIndex].isActive = true;
    props.setDynamicTags(newArr);
    tl.to('.bottombar__tags', {
      y: 0,
      opacity: 1,
    });
    setSelectedLetterIndex(selectedIndex);
  };

  const handleChange = e => {
    let newFliterData = props.dynamicTags.map(element => {
      return {
        ...element,
        children: element.children.filter(
          subElement =>
            (subElement &&
              subElement.tag_key &&
              subElement.tag_key
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (subElement &&
              subElement.genre_title &&
              subElement.genre_title
                .toLowerCase()
                .includes(e.target.value.toLowerCase())),
        ),
      };
    });

    if (e.target.value) {
      setAllgenre(newFliterData);
    } else {
      setAllgenre();
    }
  };

  return !isMobile ? (
    <div id="bottombar">
      {allTagActive ? (
        <div className="selector">
          <div className="letter">
            {props.dynamicTags.map(tag => {
              return (
                <div
                  className="letter-align"
                  style={{ opacity: opacity }}
                  key={tag.heading}
                >
                  {tag.hasTags ? (
                    <h5
                      onMouseEnter={handleClick}
                      className={`hastag ${tag.isActive && 'active'}`}
                    >
                      {tag.heading}
                    </h5>
                  ) : (
                    <h5 style={{ opacity: 0.3 }}>{tag.heading}</h5>
                  )}
                </div>
              );
            })}
          </div>
          <div className="bottombar__tags">
            {props.dynamicTags.length > 0 &&
            props.dynamicTags[selectedLetterIndex] !== undefined
              ? props.dynamicTags[selectedLetterIndex].children.map(tag => (
                  <div
                    onClick={props.megaTagFilter}
                    key={tag.tag_id}
                    data-id={tag.tag_id}
                    title={tag.tag_key}
                    className="zd-cursor bottombar__tag"
                  >
                    {' '}
                    <p>
                      <span
                        className={
                          genreTags.find(element => element == tag.tag_id)
                            ? 'border_bottom_bar_left'
                            : ''
                        }
                      >
                        <span>
                          {tag.tag_key ? tag.tag_key : tag.genre_title}
                        </span>
                      </span>
                    </p>{' '}
                  </div>
                ))
              : []}
          </div>
        </div>
      ) : (
        <AllTag
          genreTags={genreTags}
          megaTagFilter={props.megaTagFilter}
          searchTerm={props.searchTerm}
          tags={props.dynamicTags}
          key={allTagActive}
          filteredTags={Allgenre}
        />
      )}
    </div>
  ) : (
    <div className={props.showAllTags ? 'showTag bottombar' : 'bottombar'}>
      <div className={props.showAllTags ? 'showTag' : ''}>
        <AllTag
          genreTags={genreTags}
          megaTagFilter={props.megaTagFilter}
          searchTerm={props.searchTerm}
          tags={props.dynamicTags}
          key={allTagActive}
          filteredTags={Allgenre}
        />
      </div>
    </div>
  );
};
export default BottomBar;
