export let tag_ids = {
  102: [122, 52],
  12: [16, 31],
  31: [16, 12],
  5: [43],
  33: [34, 59],
  32: [14, 29, 89],
  16: [12, 31],
  55: [106],
  7: [56, 110, 114],
  25: [58],
  21: [107, 42],
  97: [101],
  94: [120, 125],
  10: [86],
  66: [54],
  41: [63, 72, 98],
  82: [88],
  39: [95, 126, 115, 71],
  4: [2, 53],
  87: [118],
  131: [134],
  9: [47],
  48: [92],
  15: [69],
  38: [60],
  68: [78],
  11: [22],
  26: [36],
  40: [74],
  79: [109],
  30: [67],
  37: [75],
};

export let megamenudata = [
  {
    genre_id: 7,
    genre_title: 'Country',
    children: null,
  },
  {
    genre_id: 1,
    genre_title: 'Dance',
    children: [
      {
        genre_id: 27,
        genre_title: 'Dance Commercial / Mainstream Club',
        children: null,
        tags: null,
      },
      {
        genre_id: 23,
        genre_title: 'Dubstep / D&B / Future Bass',
        children: null,
        tags: null,
      },
      {
        genre_id: 30,
        genre_title: 'Accapellas / DJ Tools',
        children: null,
        tags: null,
      },
      {
        genre_id: 31,
        genre_title: 'Electronica / Experimental',
        children: null,
        tags: null,
      },
      {
        genre_id: 26,
        genre_title: 'Chill / Lounge / Downtempo',
        children: null,
        tags: null,
      },
    ],
  },

  {
    genre_id: 20,
    genre_title: 'House +',
    children: [
      {
        genre_id: 35,
        genre_title: 'Big Room / Future House / Electro',
        children: null,
        tags: [
          {
            tag_id: 65,
            tag_title: 'Big Room / EDM',
          },
          {
            tag_id: 66,
            tag_title: 'Electro House',
          },
          {
            tag_id: 67,
            tag_title: 'Future House',
          },
        ],
      },
      {
        genre_id: 33,
        genre_title: 'Tech House / Deep Tech / Minimal',
        children: null,
        tags: [
          {
            tag_id: 76,
            tag_title: 'Minimal / Deep Tech',
          },
          {
            tag_id: 77,
            tag_title: 'Tech House',
          },
        ],
      },
      {
        genre_id: 52,
        genre_title: 'Deep House / Indie Dance / Nu Disco',
        children: null,
        tags: [
          {
            tag_id: 68,
            tag_title: 'Deep House',
          },
          {
            tag_id: 69,
            tag_title: 'Indie Dance / Nu Disco',
          },
          {
            tag_id: 70,
            tag_title: 'Tropical House',
          },
        ],
      },
      {
        genre_id: 36,
        genre_title: 'Techno / Melodic / Progressive House',
        children: null,
        tags: [
          {
            tag_id: 78,
            tag_title: 'Melodic House & Techno',
          },
          {
            tag_id: 79,
            tag_title: 'Techno',
          },
          {
            tag_id: 80,
            tag_title: 'Progressive House',
          },
        ],
      },
      {
        genre_id: 34,
        genre_title: "Funky / Jackin' / Groovy / Soulful",
        children: null,
        tags: [
          {
            tag_id: 71,
            tag_title: "Funky / Groove / Jackin' House",
          },
          {
            tag_id: 72,
            tag_title: 'Soulful House',
          },
        ],
      },
      {
        genre_id: 21,
        genre_title: 'Trance',
        children: null,
        tags: [
          {
            tag_id: 81,
            tag_title: 'Hard Dance / Hardcore',
          },
          // {
          //   tag_id: 82,
          //   tag_title: 'Trance',
          // },
        ],
      },
      {
        genre_id: 37,
        genre_title: 'House / Vocal House / Bass House',
        children: null,
        tags: [
          {
            tag_id: 73,
            tag_title: 'Afro House',
          },
          {
            tag_id: 74,
            tag_title: 'Bass House',
          },
          {
            tag_id: 75,
            tag_title: 'House',
          },
        ],
      },
    ],
  },
];

export const trackKeys = [
  {
    id: 1,
    name: '1A',
    camelot: 'A♭m',
  },
  {
    id: 2,
    name: '1B',
    camelot: 'B',
  },
  {
    id: 3,
    name: '2A',
    camelot: 'E♭m',
  },
  {
    id: 4,
    name: '2B',
    camelot: 'F#',
  },
  {
    id: 5,
    name: '3A',
    camelot: 'B♭m',
  },
  {
    id: 6,
    name: '3B',
    camelot: 'D♭',
  },
  {
    id: 7,
    name: '4A',
    camelot: 'Fm',
  },
  {
    id: 8,
    name: '4B',
    camelot: 'A♭',
  },
  {
    id: 9,
    name: '5A',
    camelot: 'Cm',
  },
  {
    id: 10,
    name: '5B',
    camelot: 'E♭',
  },
  {
    id: 11,
    name: '6A',
    camelot: 'Gm',
  },
  {
    id: 12,
    name: '6B',
    camelot: 'B♭',
  },
  {
    id: 13,
    name: '7A',
    camelot: 'Dm',
  },
  {
    id: 14,
    name: '7B',
    camelot: 'F',
  },
  {
    id: 15,
    name: '8A',
    camelot: 'Am',
  },
  {
    id: 16,
    name: '8B',
    camelot: 'C',
  },
  {
    id: 17,
    name: '9A',
    camelot: 'Em',
  },
  {
    id: 18,
    name: '9B',
    camelot: 'G',
  },
  {
    id: 19,
    name: '10A',
    camelot: 'Bm',
  },
  {
    id: 20,
    name: '10B',
    camelot: 'D',
  },
  {
    id: 21,
    name: '11A',
    camelot: 'F#m',
  },
  {
    id: 22,
    name: '11B',
    camelot: 'A',
  },
  {
    id: 23,
    name: '12A',
    camelot: 'D♭m',
  },
  {
    id: 24,
    name: '12B',
    camelot: 'E',
  },
];

export const removeGenreIds = [
  87, 118, 30, 44, 35, 65, 23, 28, 34, 37, 33, 31, 19,
];
export const removeTags = [
  42, 101, 80, 45, 100, 54, 14, 29, 89, 63, 72, 98, 88, 95, 126, 56, 110, 113,
  114, 93, 3, 118, 134, 24, 85, 69, 60, 25, 78, 115, 22, 86, 57, 36, 74, 122,
  109, 67, 75, 125, 107, 50, 105, 83, 73, 122, 52, 143, 43, 59, 103, 32, 58,
  106, 70, 56, 68, 122, 39, 47, 34, 28, 46, 26,
];
// export const removeTagsAndGenre = [
//   77,122,59,22,44,130,106,107,42,121,94,62,137,136,52,45,117,13,90,43,89,50,105,37,61,34,54,66,14,63,98,81,90,69,32,125,128,10,55,97,31,134,73,47,4,60,93,8,68,25,64,78 ,115,85
// ];
export const removeTagsAndGenre = [
  'Accapellas/DJ Tools',
  // 'Afro House',
  'Afro / Latin / Brazilian',
  'Acapellas',
  'Acid',
  'Afrobeats',
  'Big Room/Future House/Electro',
  'Big Room/EDM',
  'Bass House',
  'Big Room',
  'Breaks',
  'Chill/Lounge/Downtempo',
  'Country',
  'Classics',
  'Classic House',
  'Coldwave / Synth',
  'Dance',
  'Dance Commercial/Mainstream Club',
  'Dubstep/D&B/Future Bass',
  'Dubstep',
  'Electronica/Experimental',
  'Electro House',
  'Electronica / Downtempo',
  'Euro Dance / Pop Dance',
  'Future House',
  "Funky/Jackin'/Groovy/Soulful",
  "Funky / Groove / Jackin’ HouseFunky/Groove/Jackin' House",
  'Furure Bass',
  'G-House',
  'Garage / Bassline / Grime',
  'House/Vocal House/Bass House',
  'House',
  'Hard Dance / Hardcore',
  'Hip Hop/Rap',
  'Hardstyle',
  'Hip-Hop / R&B',
  'Indie Dance / Nu Disco',
  'Indie',
  'Latin',
  'Leftfield',
  'Leftfield Bass',
  'Minimal/Deep Tech',
  'Melodic House & Techno',
  'Moombahton',
  'Miscellaneous',
  'Minimal',
  'Organic House / Downtempo',
  'Progressive House',
  'Pop',
  'R&B/Soul',
  'Reggae/Dancehall',
  'Rock/Alternative',
  'Reggae / Dub',
  'Rock',
  'Roots / Lovers Rock',
  'Soulful House',
  'Soca/Calypso',
  'Salsa',
  'Soul / Funk / Disco',
  'Tech House/Deep Tech/Minimal',
  'Tech House',
  'Tropical House',
  'Techno/Melodic/Progressive House',
  'Trance',
  'Techno',
  'Trap/Twerk',
  'Trap',
  'Urban DJ Tools',
  'Uplifting',
];

export const modalIndex = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

/////////
export const genresData = [
  {
    id: 1,
    tag_id: 30,
    tag_title: 'Accapellas / DJ Tools',
    imgUrl: 'genre_102',
    children: null,
    tags: null,
  },
  {
    id: 2,
    tag_id: 73,
    tag_title: 'Afro House',
    imgUrl: 'genre_2',
  },
  {
    id: 3,
    cnt: 726,
    tag_id: 65,
    tag_title: 'Afro / Latin / Brazilian',
    imgUrl: 'genre_62',
  },
  {
    id: 4,
    cnt: 149,
    tag_id: 102,
    tag_title: 'Acapellas',
    imgUrl: 'genre_42',
  },
  {
    id: 5,
    cnt: 58,
    tag_id: 28,
    tag_title: 'Acid',
    imgUrl: 'genre_28',
  },
  {
    id: 6,
    cnt: 367,
    tag_id: 130,
    tag_title: 'Afrobeats',
    imgUrl: 'genre_130',
  },
  {
    id: 7,
    genre_id: 35,
    tag_title: 'Big Room / Future House / Electro',
    imgUrl: 'genre_109',
    children: null,
  },
  {
    id: 8,
    tag_id: 65,
    tag_title: 'Big Room / EDM',
    imgUrl: 'genre_35',
  },
  {
    id: 9,
    cnt: 1600,
    tag_id: 21,
    tag_title: 'Bass House',
    imgUrl: 'genre_21',
  },
  {
    id: 10,
    cnt: 2871,
    tag_id: 35,
    tag_title: 'Big Room',
    imgUrl: 'genre_36',
  },
  {
    id: 11,
    cnt: 984,
    tag_id: 30,
    tag_title: 'Breaks',
    imgUrl: 'genre_56',
  },
  {
    id: 12,
    genre_id: 26,
    tag_title: 'Chill / Lounge / Downtempo',
    imgUrl: 'genre_2',
    children: null,
    tags: null,
  },
  {
    id: 13,
    genre_id: 7,
    tag_title: 'Country',
    imgUrl: 'genre_134',
    children: null,
  },
  {
    id: 14,
    genre_id: 57,
    imgUrl: 'genre_50',
    tag_title: 'Classics',
    children: null,
  },
  {
    id: 15,
    cnt: 136,
    tag_id: 51,
    tag_title: 'Classic House',
    imgUrl: 'genre_51',
  },
  {
    id: 16,
    cnt: 23,
    tag_id: 83,
    tag_title: 'Coldwave / Synth',
    imgUrl: 'genre_83',
  },
  {
    id: 17,
    genre_id: 1,
    tag_title: 'Dance',
    imgUrl: 'genre_10',
  },
  {
    id: 18,
    genre_id: 27,
    tag_title: 'Dance Commercial / Mainstream Club',
    imgUrl: 'genre_45',
    children: null,
    tags: null,
  },
  {
    id: 19,
    genre_id: 23,
    tag_title: 'Dubstep / D&B / Future Bass',
    imgUrl: 'genre_63',
    children: null,
    tags: null,
  },
  {
    id: 20,
    genre_id: 52,
    tag_title: 'Deep House / Indie Dance / Nu Disco',
    imgUrl: 'genre_29',
    children: null,
  },
  {
    id: 21,
    tag_id: 68,
    tag_title: 'Deep House',
    imgUrl: 'genre_13',
  },
  {
    id: 22,
    cnt: 45,
    tag_id: 117,
    tag_title: 'Dark Disco',
    imgUrl: 'genre_117',
  },
  {
    id: 23,
    cnt: 232,
    tag_id: 90,
    tag_title: 'Deep Tech',
    imgUrl: 'genre_90',
  },
  {
    id: 24,
    cnt: 121,
    tag_id: 43,
    tag_title: 'Dirty Dubstep / Trap / Grime',
    imgUrl: 'genre_43',
  },
  {
    id: 25,
    cnt: 629,
    tag_id: 52,
    tag_title: 'DJ Tools',
    imgUrl: 'genre_52',
  },
  {
    id: 26,
    cnt: 562,
    tag_id: 37,
    tag_title: 'Drum & Bass',
    imgUrl: 'genre_37',
  },
  {
    id: 27,
    cnt: 376,
    tag_id: 5,
    tag_title: 'Dubstep',
    imgUrl: 'genre_5',
  },
  {
    id: 28,
    cnt: 104,
    tag_id: 59,
    tag_title: 'Experimental / Electronic',
    imgUrl: 'genre_59',
  },
  {
    id: 29,
    tag_id: 66,
    tag_title: 'Electro House',
    imgUrl: 'genre_18',
  },
  {
    id: 30,
    cnt: 2694,
    tag_id: 33,
    tag_title: 'Electronica / Downtempo',
    imgUrl: 'genre_33',
  },
  {
    id: 31,
    cnt: 435,
    tag_id: 17,
    tag_title: 'Euro Dance / Pop Dance',
    imgUrl: 'genre_17',
  },
  {
    id: 32,
    tag_id: 67,
    tag_title: 'Future House',
    imgUrl: 'genre_67',
  },
  {
    id: 33,
    genre_id: 34,
    tag_title: 'Funky / Jackin / Groovy / Soulful',
    imgUrl: 'genre_32',
    children: null,
  },
  {
    id: 34,
    tag_id: 71,
    tag_title: "Funky / Groove / Jackin' House",
    imgUrl: 'genre_14',
  },
  {
    id: 35,
    cnt: 322,
    tag_id: 58,
    tag_title: 'Future Bass',
    imgUrl: 'genre_58',
  },
  {
    id: 36,
    cnt: 42,
    tag_id: 99,
    tag_title: 'G-House',
    imgUrl: 'genre_99',
  },
  {
    id: 37,
    cnt: 239,
    tag_id: 41,
    tag_title: 'Garage / Bassline / Grime',
    imgUrl: 'genre_41',
  },
  {
    id: 38,
    genre_id: 37,
    tag_title: 'House / Vocal House / Bass House',
    imgUrl: 'genre_67',
    children: null,
  },
  {
    id: 39,
    tag_id: 75,
    tag_title: 'House',
    imgUrl: 'genre_6',
  },
  {
    id: 40,
    tag_id: 81,
    tag_title: 'Hard Dance / Hardcore',
    imgUrl: 'genre_7',
  },
  {
    id: 41,
    genre_id: 24,
    tag_title: 'Hip Hop / Rap',
    imgUrl: 'genre_25',
    children: null,
  },
  {
    id: 42,
    cnt: 89,
    tag_id: 96,
    tag_title: 'Hardstyle',
    imgUrl: 'genre_96',
  },
  {
    id: 43,
    tag_id: 4,
    tag_title: 'Hip-Hop / R&B',
    imgUrl: 'genre_4',
  },
  {
    id: 44,
    tag_id: 69,
    tag_title: 'Indie Dance / Nu Disco',
    imgUrl: 'genre_69',
  },
  {
    id: 45,
    tag_id: 49,
    tag_title: 'Indie',
    imgUrl: 'genre_49',
  },
  {
    id: 46,
    genre_id: 10,
    imgUrl: 'genre_75',
    tag_title: 'Latin',
  },
  {
    id: 47,
    tag_id: 79,
    tag_title: 'Leftfield',
    imgUrl: 'genre_79',
  },
  {
    id: 48,
    tag_id: 82,
    tag_title: 'Leftfield Bass',
    imgUrl: 'genre_82',
  },
  {
    id: 49,
    tag_id: 76,
    tag_title: 'Minimal / Deep Tech',
    imgUrl: 'genre_86',
  },
  {
    id: 50,
    tag_id: 78,
    tag_title: 'Melodic House & Techno',
    imgUrl: 'genre_74',
  },
  {
    id: 51,
    tag_id: 70,
    tag_title: 'Moombahton',
    imgUrl: 'genre_114',
  },
  {
    id: 52,
    tag_id: 38,
    tag_title: 'Miscellaneous',
    imgUrl: 'genre_38',
  },
  {
    id: 53,
    tag_id: 26,
    tag_title: 'Minimal',
    imgUrl: 'genre_26',
  },
  {
    id: 54,
    tag_id: 131,
    tag_title: 'Organic House / Downtempo',
    imgUrl: 'genre_131',
  },
  {
    id: 55,
    tag_id: 9,
    tag_title: 'Progressive House',
    imgUrl: 'genre_9',
  },
  {
    id: 56,
    genre_id: 14,
    tag_title: 'Pop',
    imgUrl: 'genre_23',
    children: [],
  },
  {
    id: 57,
    genre_id: 25,
    tag_title: 'R&B / Soul',
    imgUrl: 'genre_61',
  },
  {
    id: 58,
    genre_id: 19,
    tag_title: 'Reggae / Dancehall',
    imgUrl: 'genre_3',
  },
  {
    id: 59,
    tag_id: 46,
    tag_title: 'Rock / Alternative',
    imgUrl: 'genre_46',
  },
  {
    id: 60,
    tag_id: 44,
    tag_title: 'Reggae / Dub',
    imgUrl: 'genre_44',
  },
  {
    id: 61,
    tag_id: 46,
    tag_title: 'Rock',
    imgUrl: 'genre_46',
  },
  {
    id: 62,
    tag_id: 64,
    tag_title: 'Roots / Lovers Rock',
    imgUrl: 'genre_64',
  },
  {
    id: 63,
    tag_id: 72,
    tag_title: 'Soulful House',
    imgUrl: 'genre_110',
  },
  {
    id: 64,
    genre_id: 42,
    tag_title: 'Soca / Calypso',
    imgUrl: 'genre_54',
    children: null,
    tags: null,
  },
  {
    id: 65,
    tag_id: 139,
    tag_title: 'Salsa',
    imgUrl: 'genre_139',
  },
  {
    id: 66,
    tag_id: 40,
    tag_title: 'Soul / Funk / Disco',
    imgUrl: 'genre_40',
  },
  {
    id: 67,
    genre_id: 33,
    tag_title: 'Tech House / Deep Tech / Minimal',
    imgUrl: 'genre_33',
    children: null,
  },
  {
    id: 68,
    tag_id: 77,
    tag_title: 'Tech House',
    imgUrl: 'genre_18',
  },
  {
    id: 69,
    tag_id: 70,
    tag_title: 'Tropical House',
    imgUrl: 'genre_70',
  },
  {
    id: 70,
    genre_id: 36,
    tag_title: 'Techno / Melodic / Progressive House',
    imgUrl: 'genre_51',
    children: null,
  },
  {
    id: 71,
    genre_id: 21,
    tag_title: 'Trance',
    imgUrl: 'genre_8',
    children: null,
  },
  {
    id: 72,
    tag_id: 79,
    tag_title: 'Techno',
    imgUrl: 'genre_79',
  },
  {
    id: 73,
    genre_id: 56,
    tag_title: 'Trap / Twerk',
    imgUrl: 'genre_25',
    children: null,
    tags: [],
  },
  {
    id: 74,
    tag_id: 68,
    tag_title: 'Trap',
    imgUrl: 'genre_68',
  },
  {
    id: 75,
    genre_id: 2,
    tag_title: 'Urban',
    imgUrl: 'genre_31',
  },
  {
    id: 76,
    genre_id: 40,
    tag_title: 'Urban DJ Tools',
    imgUrl: 'genre_30',
    children: null,
    tags: null,
  },
  {
    id: 77,
    tag_id: 84,
    tag_title: 'Uplifting',
    imgUrl: 'genre_13',
  },
];

// const genresData = [
//   {
//     genre_id: 30,
//     genre_title: 'Accapellas / DJ Tools',
//     children: null,
//     tags: null,
//   },
//   {
//     tag_id: 73,
//     tag_title: 'Afro House',
//   },
//   {
//     cnt: 726,
//     tag_id: 65,
//     tag_title: 'Afro / Latin / Brazilian',
//   },
//   {
//     cnt: 149,
//     tag_id: 102,
//     tag_title: 'Acapellas',
//   },
//   {
//     cnt: 58,
//     tag_id: 28,
//     tag_title: 'Acid',
//   },
//   {
//     cnt: 367,
//     tag_id: 130,
//     tag_title: 'Afrobeats',
//   },
//   {
//     genre_id: 35,
//     genre_title: 'Big Room / Future House / Electro',
//     children: null,
//   },
//   {
//     tag_id: 65,
//     tag_title: 'Big Room / EDM',
//   },
//   {
//     cnt: 1600,
//     tag_id: 21,
//     tag_key: 'Bass House',
//   },
//   {
//     cnt: 2871,
//     tag_id: 35,
//     tag_title: 'Big Room',
//   },
//   {
//     cnt: 984,
//     tag_id: 30,
//     tag_title: 'Breaks',
//   },
//   {
//     genre_id: 26,
//     genre_title: 'Chill / Lounge / Downtempo',
//     children: null,
//     tags: null,
//   },
//   {
//     genre_id: 7,
//     genre_title: 'Country',
//     children: null,
//   },
//   {
//     genre_id: 57,
//     imgURL: 'Classics.png',
//     title: 'Classics',
//     children: [],
//   },
//   {
//     cnt: 136,
//     tag_id: 51,
//     tag_title: 'Classic House',
//   },
//   {
//     cnt: 23,
//     tag_id: 83,
//     tag_title: 'Coldwave / Synth',
//   },
//   {
//     genre_id: 1,
//     genre_title: 'Dance',
//   },
//   {
//     genre_id: 27,
//     genre_title: 'Dance Commercial / Mainstream Club',
//     children: null,
//     tags: null,
//   },
//   {
//     genre_id: 23,
//     genre_title: 'Dubstep / D&B / Future Bass',
//     children: null,
//     tags: null,
//   },
//   {
//     genre_id: 52,
//     genre_title: 'Deep House / Indie Dance / Nu Disco',
//     children: null,
//   },
//   {
//     tag_id: 68,
//     tag_title: 'Deep House',
//   },
//   {
//     cnt: 45,
//     tag_id: 117,
//     tag_title: 'Dark Disco',
//   },
//   {
//     cnt: 232,
//     tag_id: 90,
//     tag_title: 'Deep Tech',
//   },
//   {
//     cnt: 121,
//     tag_id: 43,
//     tag_title: 'Dirty Dubstep / Trap / Grime',
//   },
//   {
//     cnt: 629,
//     tag_id: 52,
//     tag_title: 'DJ Tools',
//   },
//   {
//     cnt: 562,
//     tag_id: 37,
//     tag_title: 'Drum & Bass',
//   },
//   {
//     cnt: 376,
//     tag_id: 5,
//     tag_title: 'Dubstep',
//   },
//   {
//     cnt: 104,
//     tag_id: 59,
//     tag_title: 'Experimental / Electronic',
//   },
//   {
//     tag_id: 66,
//     tag_title: 'Electro House',
//   },
//   {
//     cnt: 2694,
//     tag_id: 33,
//     tag_title: 'Electronica / Downtempo',
//   },
//   {
//     cnt: 435,
//     tag_id: 17,
//     tag_title: 'Euro Dance / Pop Dance',
//   },
//   {
//     tag_id: 67,
//     tag_title: 'Future House',
//   },
//   {
//     genre_id: 34,
//     genre_title: "Funky / Jackin' / Groovy / Soulful",
//     children: null,
//   },
//   {
//     tag_id: 71,
//     tag_title: "Funky / Groove / Jackin' House",
//   },
//   {
//     cnt: 322,
//     tag_id: 58,
//     tag_title: 'Future Bass',
//   },
//   {
//     cnt: 42,
//     tag_id: 99,
//     tag_title: 'G-House',
//   },
//   {
//     cnt: 239,
//     tag_id: 41,
//     tag_key: 'Garage / Bassline / Grime',
//   },
//   {
//     genre_id: 37,
//     genre_title: 'House / Vocal House / Bass House',
//     children: null,
//   },
//   {
//     tag_id: 75,
//     tag_title: 'House',
//   },
//   {
//     tag_id: 81,
//     tag_title: 'Hard Dance / Hardcore',
//   },
//   {
//     genre_id: 24,
//     genre_title: 'Hip Hop / Rap',
//     children: null,
//   },
//   {
//     cnt: 89,
//     tag_id: 96,
//     tag_title: 'Hardstyle',
//   },
//   {
//     tag_id: 4,
//     tag_title: 'Hip-Hop / R&B',
//   },
//   {
//     tag_id: 69,
//     tag_title: 'Indie Dance / Nu Disco',
//   },
//   {
//     tag_id: 49,
//     tag_title: 'Indie',
//   },
//   {
//     genre_id: 10,
//     imgURL: 'Latin.png',
//     genre_title: 'Latin',
//   },
//   {
//     tag_id: 79,
//     tag_title: 'Leftfield',
//   },
//   {
//     tag_id: 82,
//     tag_title: 'Leftfield Bass',
//   },
//   {
//     tag_id: 76,
//     tag_title: 'Minimal / Deep Tech',
//   },
//   {
//     tag_id: 78,
//     tag_title: 'Melodic House & Techno',
//   },
//   {
//     tag_id: 70,
//     tag_title: 'Moombahton',
//   },
//   {
//     tag_id: 38,
//     tag_title: 'Miscellaneous',
//   },
//   {
//     tag_id: 26,
//     tag_title: 'Minimal',
//   },
//   {
//     tag_id: 131,
//     tag_title: 'Organic House / Downtempo',
//   },
//   {
//     tag_id: 9,
//     tag_title: 'Progressive House',
//   },
//   {
//     genre_id: 14,
//     genre_title: 'Pop',
//     children: [],
//   },
//   {
//     genre_id: 25,
//     genre_title: 'R&B / Soul',
//   },
//   {
//     genre_id: 19,
//     genre_title: 'Reggae / Dancehall',
//   },
//   {
//     tag_id: 46,
//     tag_title: 'Rock / Alternative',
//   },
//   {
//     tag_id: 44,
//     tag_title: 'Reggae / Dub',
//   },
//   {
//     tag_id: 46,
//     tag_title: 'Rock',
//   },
//   {
//     tag_id: 64,
//     tag_title: 'Roots / Lovers Rock',
//   },
//   {
//     tag_id: 72,
//     tag_title: 'Soulful House',
//   },
//   {
//     genre_id: 42,
//     genre_title: 'Soca / Calypso',
//     children: null,
//     tags: null,
//   },
//   {
//     tag_id: 139,
//     tag_title: 'Salsa',
//   },
//   {
//     tag_id: 40,
//     tag_title: 'Soul / Funk / Disco',
//   },
//   {
//     genre_id: 33,
//     genre_title: 'Tech House / Deep Tech / Minimal',
//     children: null,
//   },
//   {
//     tag_id: 77,
//     tag_title: 'Tech House',
//   },
//   {
//     tag_id: 70,
//     tag_title: 'Tropical House',
//   },
//   {
//     genre_id: 36,
//     genre_title: 'Techno / Melodic / Progressive House',
//     children: null,
//   },
//   {
//     genre_id: 21,
//     genre_title: 'Trance',
//     children: null,
//   },
//   {
//     tag_id: 79,
//     tag_title: 'Techno',
//   },
//   {
//     genre_id: 56,
//     genre_title: 'Trap / Twerk',
//     children: null,
//     tags: [],
//   },
//   {
//     tag_id: 68,
//     tag_title: 'Trap',
//   },
//   {
//     genre_id: 2,
//     genre_title: "Urban",
//   },
//   {
//     genre_id: 40,
//     genre_title: 'Urban DJ Tools',
//     children: null,
//     tags: null,
// },
// {
//   tag_id: 84,
//   tag_title: "Uplifting"
// }
// ];

export const keyData = {
  0: [
    {
      id: '1',
      name: '1A',
      code: 'Abm',
    },
    {
      id: '2',
      name: '2A',
      code: 'Ebm',
    },
    {
      id: '3',
      name: '3A',
      code: 'Bbm',
    },
    {
      id: '4',
      name: '4A',
      code: 'Fm',
    },
    {
      id: '5',
      name: '5A',
      code: 'Cm',
    },
    {
      id: '6',
      name: '6A',
      code: 'Gm',
    },
    {
      id: '7',
      name: '7A',
      code: 'Dm',
    },
    {
      id: '8',
      name: '8A',
      code: 'Am',
    },
    {
      id: '9',
      name: '9A',
      code: 'Em',
    },
    {
      id: '10',
      name: '10A',
      code: 'Bm',
    },
    {
      id: '11',
      name: '11A',
      code: 'F#m',
    },
    {
      id: '12',
      name: '12A',
      code: 'Dbm',
    },
  ],
  1: [
    {
      id: '21',
      name: '1B',
      code: 'B',
    },
    {
      id: '22',
      name: '2B',
      code: 'F#',
    },
    {
      id: '23',
      name: '3B',
      code: 'Db',
    },
    {
      id: '24',
      name: '4B',
      code: 'Ab',
    },
    {
      id: '25',
      name: '5B',
      code: 'Eb',
    },
    {
      id: '26',
      name: '6B',
      code: 'Bb',
    },
    {
      id: '27',
      name: '7B',
      code: 'F',
    },
    {
      id: '28',
      name: '8B',
      code: 'C',
    },
    {
      id: '29',
      name: '9B',
      code: 'G',
    },
    {
      id: '30',
      name: '10B',
      code: 'D',
    },
    {
      id: '31',
      name: '11B',
      code: 'A',
    },
    {
      id: '32',
      name: '12B',
      code: 'E',
    },
  ],
};
