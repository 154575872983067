/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ZDFeedbackModal from 'components/Feedback/ZDFeedbackModal';

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
  }

  updateDownloadManagerDetails = data => {
    this.props.player.props.updateDownloadManager(data);
  };

  render() {
    let player = this.props.player;
    let currentAudio = null;
    let audioLists = this.props.player.state.audioLists
      ? this.props.player.state.audioLists
      : [];
    let playId = this.props.player.state.playId
      ? this.props.player.state.playId
      : null;
    for (let i = 0; i < audioLists.length; i++) {
      if (audioLists[i].id == playId) {
        currentAudio = audioLists[i];
        break;
      }
    }
    if (!currentAudio) {
      return <span></span>;
    }
    if (currentAudio && currentAudio.is_downloaded === 'hide') {
      return (
        <span style={{ opacity: 0.25 }}>
          <i className="zd-player-icon-download"></i>
        </span>
      );
    }
    if (currentAudio && currentAudio.is_feedback === 'Yes') {
      return (
        <ZDFeedbackModal
          dataId={currentAudio.release_id}
          trackFeedbackStatus="true"
          trackId={currentAudio.track_id}
          updateDownloadManagerDetails={this.updateDownloadManagerDetails.bind(
            this,
          )}
          isDownloaded={false}
        />
      );
    }
    return (
      <a
        onClick={e => {
          e.preventDefault();
          let targetField = e.currentTarget;
          if (targetField.classList.contains('disabled')) {
            return;
          }
          player.onAudioDownload(e);
          currentAudio.is_downloaded = 'hide';
        }}
        className={
          currentAudio && currentAudio.is_downloaded !== 'hide'
            ? 'zd-download-btn zd-cursor mt-1'
            : 'zd-download-btn disabled'
        }
      >
        <i className="zdv3-icon-download"></i>
      </a>
    );
  }
}

export default DownloadButton;
