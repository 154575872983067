import React from 'react';
import { Form, Input } from 'reactstrap';
import SearchAutoComplete from 'components/SearchAutoComplete';
import { zdOpenURL } from 'utils/Helpers';
import { saveSearchHistory } from '../utils/saveSearchHistory';

class SearchInput extends React.Component {
  performSearch = event => {
    let searchQuery = event.target.value;
    if (window.zdSearching !== 'NO') {
      clearTimeout(window.zdSearching);
    }

    searchQuery = searchQuery.trim();
    if (searchQuery.length > 0) {
      searchQuery += ' ';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param_value = urlParams.get('sort');

    window.zdSearching = setTimeout(() => {
      if (param_value) {
        this.props.history.push(`/search?q=${searchQuery}`);
        console.log('searchQuery', searchQuery);
      } else {
        let searchBoxes = document.querySelectorAll('.sk-search-box');
        if (searchBoxes.length < 1) return;
        for (let i = 0; i < searchBoxes.length; i++) {
          let searchBox = searchBoxes[i];
          let searchBoxCmp = window.zdFindComponentByElement(searchBox);
          searchBoxCmp.searchQuery(searchQuery);
        }
        window.zdSearching = 'NO';
        saveSearchHistory();
      }
    }, 300);
  };

  componentDidMount() {
    let search_value = '';
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let query = params.get('q');
    if (query) {
      search_value = query;
    }
    let $searchInput = document.querySelector('.cr-default-search');
    if ($searchInput) $searchInput.value = search_value;
  }

  onSuggestionSelected = (event, suggestion, suggestionValue) => {
    let zdSearchForm = document.querySelectorAll('.zd-search-form');
    if (zdSearchForm.length < 1) {
      return;
    }
    let zdSearchInput = document.querySelectorAll(
      '.zd-search-form input[name="q"]',
    );
    if (zdSearchInput.length < 1) {
      return;
    }
    zdOpenURL(
      '/search?q=' + encodeURIComponent(suggestion.suggestionValue),
      this.props.history,
    );
  };

  render() {
    let isSearchPage = window.location.pathname.match(/\/search\/?$/)
      ? true
      : false;

    let searchInput = (
      <SearchAutoComplete
        onSuggestionSelected={this.onSuggestionSelected}
        {...this.props}
      />
    );

    if (isSearchPage) {
      let search_value = '';
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let query = params.get('q');
      if (query) {
        search_value = query;
      }
      let $searchInput = document.querySelector('.cr-default-search');
      if ($searchInput) $searchInput.value = search_value.trim();

      searchInput = (
        <Input
          type="search"
          className="cr-search-form__input cr-default-search"
          placeholder={'Search...'}
          name="q"
          onChange={this.performSearch}
        />
      );
    }

    return (
      <Form
        inline
        className="cr-search-form zd-search-form"
        method="GET"
        action={`${process.env.PUBLIC_URL}/search`}
        onSubmit={e => e.preventDefault()}
      >
        {/* <MdSearch
          size="20"
          className="cr-search-form__icon-search text-secondary"
        /> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="none"
          
          viewBox="0 0 15 15"
        >
          <path
            stroke="#6b7883"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M14 14l-3.489-3.489m0 0a5.571 5.571 0 10-7.88-7.88 5.571 5.571 0 007.88 7.88z"
          ></path>
        </svg> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="none"
          className="cr-search-form__icon-search text-secondary"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#6b7883"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M14 15l-3.489-3.489m0 0a5.571 5.571 0 10-7.88-7.88 5.571 5.571 0 007.88 7.88z"
          ></path>
        </svg> */}
        <i className="cr-search-form__icon-search text-secondary zdv3-icon-search"></i>
        {searchInput}
        <button
          className="zd-v5-search-mobile-close"
          onClick={e => {
            e.preventDefault();
            this.props.handleSearchGenre();
          }}
        >
          Close
        </button>
      </Form>
    );
  }
}

export default SearchInput;
