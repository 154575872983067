import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { debounce } from 'throttle-debounce';

axios.defaults.timeout = 60000;

var zdAutoCompleteTheme = {
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'cr-search-form__input form-control react-autosuggest__input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title',
};

class SearchAutoComplete extends React.Component {
  state = {
    value: '',
    suggestions: [],
  };

  UNSAFE_componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested,
    );
  }

  renderSuggestion = suggestion => {
    return <span>{suggestion.name}</span>;
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    axios
      .post(
        process.env.REACT_APP_ELASTICSEARCH_BASE_URL +
          '/zipdj-user-tracks-' +
          window.zdMemberEsId() +
          ',zipdj-user-artists-' +
          window.zdMemberEsId() +
          ',zipdj-user-labels-' +
          window.zdMemberEsId() +
          ',zipdj-user-releases-' +
          window.zdMemberEsId() +
          '/_search',
        {
          query: {
            bool: {
              should: [
                {
                  simple_query_string: {
                    query: value,
                    fields: ['_all'],
                  },
                },
                {
                  multi_match: {
                    query: value,
                    type: 'phrase_prefix',
                    fields: ['keywords^10', 'name'],
                  },
                },
              ],
            },
          },
          aggs: {
            terms: {
              terms: {
                field: '_index',
                size: 10,
              },
              aggs: {
                top_hits: {
                  top_hits: {
                    sort: [
                      {
                        _score: {
                          order: 'desc',
                        },
                      },
                    ],
                    size: 3,
                  },
                },
              },
            },
          },
        },
        {
          headers: {
            Authorization:
              'ApiKey ' + process.env.REACT_APP_ELASTICSEARCH_API_KEY,
          },
        },
      )
      .then(res => {
        const results = res.data.aggregations.terms.buckets.map(h => {
          let result = {};
          result['group'] = h.key.replace(/^zipdj-user-([^-]+)-.*$/g, '$1');
          result['items'] = [];
          let hits = h.top_hits.hits.hits;
          for (var i = 0; i < hits.length; i++) {
            result['items'].push({
              name: hits[i]._source['name'],
              id: hits[i]._id,
            });
          }
          return result;
        });
        this.setState({ suggestions: results });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestionValue = suggestion => {
    return suggestion.name;
  };

  renderSectionTitle = section => {
    const title = section.group.split('zipdj-');
    return <strong>{title[1]}</strong>;
  };

  getSectionSuggestions = section => {
    return section.items;
  };

  constructor(props) {
    super(props);
    this.props = props;
  }
  onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.history.push(`/search?q=${e.target.value}%20`);
    }
  };
  render() {
    let { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Search for Tracks, Artists, Labels...',
      name: 'q',
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      type: 'search',
    };

    return (
      <Autosuggest
        theme={zdAutoCompleteTheme}
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        inputProps={inputProps}
        {...this.props}
      />
    );
  }
}
export default SearchAutoComplete;
