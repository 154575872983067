import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import '../../assets/zdv3-icons/zdv3-icon.scss';
import queryString from 'query-string';
import '../../styles/components/Scssfile/header.scss';
import axios from 'axios';
import {
  zdCheckAuthOnAjax,
  zdsetGenreBtnValue,
  zdsetGenreDropDownValue,
} from '../../utils/Helpers';
import { zdTrackTagFilter } from '../../utils/Filters';
import Main from '../megaMenuPages/Main';
import { tag_ids, megamenudata, removeTags } from '../../utils/GlobalVariables';
import genre from '../../assets/img/genre.svg';

axios.defaults.timeout = 60000;

class MegaGenreDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // megaMenuShow: false,
      genreView: false,
      disabled: true,
      dropdownOpen: false,
      modal: false,
      genreTags: [],
      groupTags: [],
      isLoaded: false,
      megaMenuData: megamenudata,
      isMobile: false,
         };
    window.addEventListener('resize', () => {
      this.setScreen();
    });

    // Creating a reference for mega menu
    this.box = React.createRef();
  }

  handleOutsideClick = event => {
    if (
      this.box &&
      this.box.current &&
      !this.box.current.contains(event.target)
    ) {
      let zdGenreFilterButton = document.getElementById(
        'zd-megamenu-genre-filter',
      );
      let zdGenreFilterText = document.getElementById('zd-lg-filter');
      let zdGenreFilterIcon = document.getElementById('zd-megamenu-icon');

      if (
        zdGenreFilterButton &&
        zdGenreFilterButton.toString() !== event.target.toString() &&
        zdGenreFilterText &&
        zdGenreFilterText.toString() !== event.target.toString() &&
        zdGenreFilterIcon !== event.target
      ) {
        this.props.handleSearchGenre();
      }
    }
  };

  componentDidMount() {
    // Adding a click event listener for mega menu
    document.addEventListener('click', this.handleOutsideClick);

    this.setScreen();
    let currentSearch = queryString.parse(window.location.search, {
      arrayFormat: 'index',
    });
    let id =
      currentSearch['all_genre_id'] && currentSearch['all_genre_id'][0]
        ? currentSearch['all_genre_id'][0]
        : null;
    if (id !== null) {
      let data = {
        genre_id: id,
        type: 'selectMenu',
      };
      this.getGenreNameByID(data);
    }

    let tag_id =
      currentSearch['tag_id'] && currentSearch['tag_id'][0]
        ? currentSearch['tag_id'][0]
        : null;

    if (tag_id !== null) {
      let data = {
        tag_id: tag_id,
        type: 'selectMenu',
      };
      this.getTagName(data);
    }

 

  }

  setScreen = () => {
    if (window.innerWidth <= 751) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  getGenre = e => {
    var datagenre_id = e.currentTarget.getAttribute('data-id');
    const data = {
      genre_id: datagenre_id,
      type: 'selectMenu',
    };
    this.getGenreNameByID(data);
  };

  getGenreNameByID = data => {
    if (!data) return;
    var self = this;
    let genre_id = data.genre_id ? data.genre_id : 0;
    let type = data.type ? data.type : '';
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH + '/zdmapi/genre-name/' + genre_id,
      xSessionToken: loginInfo.token,
    };

    let url = api.baseUrl;
    axios
      .get(url, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data.error === true && (!data.genre_name || !data.genre_id)) {
          zdsetGenreDropDownValue('');
          return;
        }
        if (type === 'selectMenu') {
          zdsetGenreDropDownValue(data.genre_name);
          self.setState({
            genre: data.genre_name,
          });
          zdsetGenreBtnValue(data.genre_name);
        }
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  };

  componentDidUpdate(prevProps) {
    const pages = ['New Releases', 'Top Downloads', 'Trending Chart'];

    const checkTitle = title => title === this.props.title;
    if (prevProps.title !== this.props.title) {
      if (pages.some(checkTitle)) {
        this.setState({
          disabled: false,
          genreView: false,
        });
      } else if (this.props.title === 'HOME') {
        this.setState({
          disabled: false,
          genreView: true,
        });
      } else if (this.props.title === 'Genre View') {
        this.setState({
          disabled: false,
          genreView: true,
        });
      } else {
        this.setState({
          disabled: true,
          genreView: false,
        });
      }
    }
  }

  // EventListenener to Active on Click Outside of the component

  groupTags = data => {
    let wordsArray = data;

    wordsArray = wordsArray.map((tag, i) => {
      return tag !== null &&
        !tag.tag_key.includes('NOT') &&
        !tag.tag_key.includes('IGNORE') &&
        !removeTags.includes(tag.tag_id)
        ? tag
        : { tag_key: '', tag_id: null };
    });
    let regex = /^\S/;
    let grouped = wordsArray.reduce(
      function (acc, curr) {
        let isSpecial = regex.test(curr.tag_key);
        if (!isSpecial) {
          // acc.special.push(curr);
        } else if (acc.hasOwnProperty(curr.tag_key.toLowerCase().charAt(0))) {
          acc[curr.tag_key.toLowerCase().charAt(0)].push(curr);
        } else {
          acc[curr.tag_key.toLowerCase().charAt(0)] = [curr];
        }
        return acc;
      },
      {
        // special: [],
      },
    );
    this.setState({
      groupTags: grouped,
    });
  };

  getTags = () => {
    var self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/genretags',
      xSessionToken: loginInfo.token,
    };
    const post_data = {
      type: 'all',
    };
    let url = api.baseUrl;
    axios
      .post(url, post_data, {
        headers: { 'X-Session-Token': api.xSessionToken },
      })
      .then(function (response) {
        const data = response.data;
        if (data) {
          self.groupTags(data.tags);
          self.setState({
            genreTags: data.tags,
            unauthorized: false,
            isLoaded: true,
          });
        } else {
          self.setState({
            genreTags: [],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

    // if (document.querySelectorAll('.zd-table-head')[0]) {
    //   document
    //     .querySelectorAll('.zd-table-head')[0]
    //     .classList.toggle('zd-hide');
    // }
    // if (document.querySelectorAll('.zd-table-head')[1]) {
    //   document
    //     .querySelectorAll('.zd-table-head')[1]
    //     .classList.toggle('zd-hide');
    // }
  };

  toggleModal = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        if (this.state.modal) {
          this.getTags();

          document
            .getElementsByClassName('modalscroll')[0]
            .addEventListener('scroll', e => {
              let scrollPos =
                document.getElementsByClassName('modalscroll')[0].scrollTop;
              let divs = document
                .getElementsByClassName('modalscroll')[0]
                .getElementsByClassName('parent-genre-index');

              for (let i = 0; i < divs.length; i++) {
                if (
                  scrollPos >= divs[i].offsetTop - 25 &&
                  scrollPos <= divs[i].offsetTop + 20
                ) {
                  let index = document.getElementsByClassName('index-selected');
                  for (let j = 0; j < index.length; j++) {
                    index[j].classList.remove('index-selected');
                  }

                  if (document.getElementById(`index-${divs[i].id}`)) {
                    document
                      .getElementById(`index-${divs[i].id}`)
                      .classList.add('index-selected');
                  }
                }
              }
            });
        }
      },
    );
    if (this.state.dropdownOpen) {
      this.setState({
        dropdownOpen: false,
      });
    }
  };

  renderGenreCard = genreTitle => {
    return this.state.megaMenuData.map((genre, i) => {
      if (genre.genre_title === genreTitle) {
        if (genre.children !== null) {
          return (
            <ListGroup key={i}>
              <ListGroupItem key={i} tag="li" action>
                <span
                  className="zd-megamenu-genre-title-a text-uppercase"
                  data-value={genre.genre_title}
                  data-id={genre.genre_id}
                  onClick={this.megagenreDropDownFilter.bind(this)}
                  data-parent-genre={
                    genre.genre_title === 'House +'
                      ? 'House'
                      : genre.genre_title
                  }
                  data-parent-genre-id={genre.genre_id}
                  data-genre={
                    genre.genre_title === 'House +'
                      ? 'House'
                      : genre.genre_title
                  }
                >
                  {genre.genre_title}
                </span>
                <ListGroup className="zd-megagenre-innre-ul">
                  {genre.children.map((_genre, j) => {
                    if (_genre.children === null) {
                      return (
                        <ListGroupItem key={j} tag="li" action>
                          <span
                            data-value={_genre.genre_title}
                            data-id={_genre.genre_id}
                            className="zd-megamenu-subgenre-title-a"
                            onClick={this.megagenreDropDownFilter.bind(this)}
                            data-parent-genre-id={genre.genre_id}
                            data-parent-genre={
                              genre.genre_title === 'House +'
                                ? 'House'
                                : genre.genre_title
                            }
                            data-genre={_genre.genre_title}
                          >
                            {_genre.genre_title}
                          </span>
                          <ListGroup>
                            {_genre.tags !== null
                              ? _genre.tags.map((tag, k) => {
                                  if (tag.tag_title !== null) {
                                    return (
                                      <ListGroupItem key={k} tag="li" action>
                                        <span
                                          data-value={tag.tag_title}
                                          data-id={tag.tag_id}
                                          data-tag_id={tag.tag_id}
                                          className="zd-megamenu-tag-title-a"
                                          data-parent-genre={
                                            genre.genre_title === 'House +'
                                              ? 'House'
                                              : genre.genre_title
                                          }
                                          data-parent-genre-id={genre.genre_id}
                                          data-genre={tag.tag_title}
                                          onClick={this.megagenreDropDownFilter.bind(
                                            this,
                                          )}
                                        >
                                          {tag.tag_title}
                                        </span>
                                      </ListGroupItem>
                                    );
                                  }
                                })
                              : ''}
                          </ListGroup>
                        </ListGroupItem>
                      );
                    }
                  })}
                </ListGroup>
              </ListGroupItem>
            </ListGroup>
          );
        }
      }
    });
  };

  megagenreDropDownFilter(e) {
    this.props.setGenrePreferenceStatus(true);

    e.preventDefault();
    this.toggle();
    this.props.genreDropDownFilter(e);
    this.getGenre(e);
    this.props.showMegaMenu();
    // this.props.handleSearchGenre();
  }

  megagenreTagFilter(e) {
    e.preventDefault();
    this.toggle();

    let id = e.currentTarget.getAttribute('data-id');
    let data = {
      tag_id: id,
      type: 'selectMenu',
    };

    let tag_name = e.currentTarget.getAttribute('data-value');
    let genre_parent_id = e.currentTarget.getAttribute('data-parent-genre-id');
    let genre_parent = e.currentTarget.getAttribute('data-parent-genre');

    zdTrackTagFilter(id, tag_name, genre_parent_id, genre_parent, this.props);
    this.getTagName(data);
  }

  megaLabelFilter = e => {
    e.preventDefault();
    this.toggle();
    this.props.labelFilter(e);
  };

  megaTagModalFilter = (e, name) => {
    e.preventDefault();
    this.props.setGenrePreferenceStatus(this.state.disabled);

    const { history } = this.props;

    let id = e.currentTarget.getAttribute('data-id');
    document
      .getElementsByTagName('body')[0]
      .classList.toggle('body-overflow-hidden');
    let data = {
      tag_id: id,
      type: 'selectMenu',
    };
    this.setState({
      modal: !this.state.modal,
      // megaMenuShow: !this.state.megaMenuShow,
    });
    if (this.state.dropdownOpen) {
      this.setState({
        dropdownOpen: false,
      });
    }

    if (id) {
      var currentSearch = queryString.parse(this.props.location.search, {
        arrayFormat: 'index',
      });
      currentSearch = this.clearSearch(currentSearch);
      currentSearch[name].push(id);
      let tag_id_filter = name + '[0]=' + id;
      if (tag_ids[id] !== undefined) {
        tag_ids[id].every((element, key) => {
          currentSearch[name].push(element);
          tag_id_filter += '&' + name + '[' + (key + 1) + ']=' + element;
          return true;
        });
      }

      if (this.props.headerActiveTab == 'top-downloads') {
        history.push(
          `/member/topdownloads/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'new-release' &&
        this.props.newPageType == 'new-releases-track-view'
      ) {
        history.push(
          `/member/newrelease/track-view?${queryString.stringify(
            currentSearch,
            {
              arrayFormat: 'index',
            },
          )}`,
          this.props.showMegaMenu(),
        );
      } else if (this.props.headerActiveTab == 'new-release') {
        history.push(
          `/member/newrelease/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'trending-charts' &&
        this.props.newTrandingPageType == 'all'
      ) {
        history.push(
          `/member/all/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'trending-charts' &&
        this.props.newTrandingPageType == 'airplay'
      ) {
        history.push(
          `/member/airplay/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'trending-charts' &&
        this.props.newTrandingPageType == 'electronic'
      ) {
        history.push(
          `/member/electronic/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'trending-charts' &&
        this.props.newTrandingPageType == 'mainstream'
      ) {
        history.push(
          `/member/mainstream/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else if (
        this.props.headerActiveTab == 'trending-charts' &&
        this.props.newTrandingPageType == 'urban'
      ) {
        history.push(
          `/member/urban/?${queryString.stringify(currentSearch, {
            arrayFormat: 'index',
          })}`,
          this.props.showMegaMenu(),
        );
      } else {
        history.push(
          `/member/newrelease/track-view?${tag_id_filter}`,
          this.props.showMegaMenu(),
        );
      }
    }

    this.getTagName(data);
    localStorage.removeItem('breadcrumb');
  };

  clearSearch(currentSearch) {
    currentSearch['artist_id'] = [];
    currentSearch['label_name'] = [];
    currentSearch['lm_label_name'] = [];
    currentSearch['track_release_id'] = [];
    currentSearch['tag_id'] = [];
    currentSearch['genre_id'] = [];
    currentSearch['all_genre_id'] = [];
    currentSearch['lm_label_name'] = [];

    return currentSearch;
  }

  scrollModalIndexToTop = e => {
    let letter = e.target.getAttribute('data-letter');
    let modalIndexElement = document.getElementById(letter);
    let topPosition = modalIndexElement.offsetTop - 25;

    document.getElementsByClassName('modalscroll')[0].scrollTo({
      top: topPosition,
      behavior: 'smooth',
    });
  };

  getTagName = data => {
    if (!data) return;
    var self = this;
    let tag_id = data.tag_id ? data.tag_id : 0;
    let type = data.type ? data.type : '';
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    const postData = {
      tag_id: tag_id,
    };
    const api = {
      baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/get-tag-name',
      xSessionToken: loginInfo.token,
    };

    let url = api.baseUrl;
    axios
      .post(url, postData, {
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data.error === true && (!data.tag_name || !data.tag_id)) {
          zdsetGenreDropDownValue('');
          return;
        }
        if (type === 'selectMenu') {
          zdsetGenreDropDownValue(data.tag_name);
          self.setState({
            genre: data.tag_name,
          });
          zdsetGenreBtnValue(data.tag_name);
        }
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
      })
      .then(function () {});
  };

  render() {
        window.onclick = e => {
      if (this.props.megaMenuShow && !this.state.isMobile) {
        let mainMegaMenuComponent = document.getElementById('mainMega');
        let sidebarComponent = document.querySelector('.cr-sidebar');
        let angelDropDown = document.querySelector('.zip-angle-down');
        let headerComponent = document.querySelector('.cr-header');
        let logoComponent = document.querySelector('.zd-nav-brand');
        let mobileScreenSearchBtn =
          document.querySelector('.zd-nav-search-btn');
        const ifChild =
          mainMegaMenuComponent && mainMegaMenuComponent.contains(e.target);
        const isInsideDropDownElement =
          angelDropDown && angelDropDown.contains(e.target);
        const isSearchBtn =
          mobileScreenSearchBtn && mobileScreenSearchBtn.contains(e.target);
        if (
          !isSearchBtn &&
          !ifChild &&
          !isInsideDropDownElement &&
          ((sidebarComponent && sidebarComponent.contains(e.target)) ||
            (headerComponent && headerComponent.contains(e.target)) ||
            (logoComponent && logoComponent.contains(e.target)))
        ) {
          this.props.showMegaMenu();
        }
      }
    };
    // console.log(this.props.isFiltered,"this.props.isFiltered",this.state.genre)
    if (
      this.props.location.pathname == '/member/genres/index' ||
      // this.props.location.pathname == '/member/profile' ||
      this.props.headerActiveTab === 'search'
         ) {
      return (
        <div className="zd-nested-dropdown mr_12 d-none">
          {!this.state.isMobile ? (
            <button
              // Removing a class zd-primary-dropdown
              id="zd-lg-filter"
              className={
                this.props.isGenreActive
                  ? ` zd-top-dropdown zd_new_top_genre  zd-dropdown key-btn-clr  dropdown_key top-downloads-filter-btn zd-btn-4f7be9`
                  : ` zd-top-dropdown zd_new_top_genre zd-dropdown key-btn-clr  dropdown_key top-downloads-filter-btn zt-btn-25262a `
              }
              onClick={this.props.showMegaMenu}
            >
              <span
                id="zd-megamenu-genre-filter"
                className={
                  this.props.isGenreActive
                    ? `zd-text-genre-filter mr-1 zd-nowrap`
                    : `zd-text-genre-filter mr-1 zd-nowrap`
                }
              >
                {this.props.isGenreActive && this.state.genre
                  ? 'Genres'
                  : 'Genres'}
              </span>
              {!this.props.megaMenuShow ? (
                <i
                  id="zd-megamenu-icon"
                  style={{ marginBottom: '2px' }}
                  className={
                    this.props.isGenreActive
                      ? 'zdv3-icon-filled-downWard-arrow'
                      : 'zdv3-icon-filled-downWard-arrow'
                  }
                ></i>
              ) : (
                <i
                  className={
                    this.props.isGenreActive
                      ? 'zdv3-icon-up-arrow filter-applied'
                      : 'zdv3-icon-up-arrow'
                  }
                ></i>
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      );
    }

    return (
      <div
        className={`zd-nested-dropdown mr_12 ${
          this.props.title == 'HOME' ||
          this.props.title == 'PACKSET' ||
          this.props.title == 'DANCE' ||
          this.props.title == 'Genres' ||
          this.props.title == 'DOWNLOAD MANAGER' ||
          this.props.location.pathname === '/account/clientarea.php'||
          this.props.location.pathname === '/member/profile'
          
            ? 'd-none'
            : ''
        }`}
      >
        {!this.state.isMobile ? (
          <button
            id="zd-lg-filter"
            className={
              this.props.isGenreActive
                ? `zd-top-dropdown zd_new_top_genre zd-dropdown key-btn-clr  dropdown_key top-downloads-filter-btn zd-btn-4f7be9`
                : `zd-top-dropdown zd_new_top_genre zd-dropdown key-btn-clr  dropdown_key top-downloads-filter-btn zt-btn-25262a `
            }
            onClick={this.props.showMegaMenu}
          >
            <img src={genre} width={24} height={24} />
            <span
              id="zd-megamenu-genre-filter"
              className={
                this.props.isGenreActive
                  ? `zd-text-genre-filter mr-1 zd-nowrap`
                  : `zd-text-genre-filter mr-1 zd-nowrap`
              }
            >
              {this.props.isGenreActive && this.state.genre ? 'Genres' : 'Genres'}
            </span>
            {/* {!this.props.megaMenuShow ? (
              <i
                id="zd-megamenu-icon"
                style={{ marginBottom: '2px' }}
                className={
                  this.props.isFiltered
                    ? 'zdv3-icon-filled-downWard-arrow'
                    : 'zdv3-icon-filled-downWard-arrow'
                }
              ></i>
            ) : (
              <i
                className={
                  this.props.isFiltered
                    ? 'zdv3-icon-up-arrow filter-applied'
                    : 'zdv3-icon-up-arrow'
                }
              ></i>
            )} */}
          </button>
        ) : (
          <button
            id="zd-sm-filter"
            className={
              this.props.isGenreActive
                ? 'zip-angle-down zd-primary-dropdown-disabled text-uppercase zd-dropdown zd-text-ellipsis zd-search-genre zd-btn-4f7be9'
                : 'zip-angle-down zd-mega-dropdown text-uppercase zd-dropdown zd-text-ellipsis zd-search-genre zt-btn-25262a'
            }
            onClick={this.props.showMegaMenu}
          >
            <img src={genre} width={24} height={24} />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                fill="#6b7883"
                fillRule="evenodd"
                d="M2.25 4.75a2 2 0 114 0 2 2 0 01-4 0zM7.67 4a3.501 3.501 0 00-6.92.75 3.5 3.5 0 006.92.75H13A.75.75 0 0013 4H7.67zm-5.42 7.25A.75.75 0 013 10.5h5.33a3.501 3.501 0 016.92.75 3.5 3.5 0 01-6.92.75H3a.75.75 0 01-.75-.75zm7.5 0a2 2 0 104 0 2 2 0 00-4 0z"
                clipRule="evenodd"
              ></path>
            </svg> */}
          </button>
        )}

        {this.props.megaMenuShow ? (
          <div tag="div" className="zd-megagenre-parent" ref={this.box}>
            <div
              id="megamenuclose"
              className="zd-cursor"
              onClick={this.props.showMegaMenu}
            >
              {this.state.isMobile !== true ? (
                <span className="close-Text-Mega">CLOSE</span>
              ) : null}
              <i className="zdv3-icon-close"></i>
            </div>

            <div xs="2" className="zd-megamenu-row">
              <Main
                {...this.props}
                // setGenreStatus={data =>
                //   this.props.setGenrePreferenceStatus(data)
                // }
                megagenreDropDownFilter={this.megagenreDropDownFilter.bind(
                  this,
                )}
                megaTagFilter={this.megaTagModalFilter}
                toggle={this.toggle}
                activeTracksList={this.props.activeTracksList}
                setActiveTracksList={this.props.setActiveTracksList}
                // setGenrePreferenceStatus={this.props.setGenrePreferenceStatus}
              />
            </div>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}
export default MegaGenreDropDown;
