import Avatar from 'components/Avatar';
import { ProfileUserCard } from 'components/Card';
import SearchInput from 'components/SearchInput';
import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import ZDDefaultProfileIMG from 'assets/img/profile-icon.svg';
import ReportIssueModal from 'components/ReportIssueModal';
import MegaGenreDropDown from 'components/DropDown/MegaGenreDropDown';
import '../../components/../styles/components/Scssfile/header.scss';
import DropDownGenrePre from '../DropDown/DropDownGenrePre';
import queryString from 'query-string';
import { MdSearch } from 'react-icons/md';
import {
  zdsetClearGenreFilter,
  zdsetDateDropDownValue,
  zdsetGenreDropDownValue,
} from 'utils/Helpers';

import { zdGenreFilter, zdTrackGenreFilter } from 'utils/Filters';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import genre from '../../assets/img/genre.svg';

const zdProfileNavs = [
  {
    to: '/member/profile?q=myprofile',
    name: 'My Account',
    exact: false,
    classname: '',
    badge: '',
    value: 'myprofile',
  },
  {
    to: '/member/profile?q=download_history',
    name: 'Download History',
    exact: false,
    classname: '',
    value: 'download_history',
    badge: '',
  },
  {
    to: '/member/logout',
    name: 'Sign out',
    exact: false,
    classname: '',
    badge: '',
    value: 'logout',
  },
];
const has = require('lodash/has');
const isEqual = require('lodash/isEqual');
const bem = bn.create('header');

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
      profileHeaderDetails: [],
      unauthorized: false,
      isLoaded: false,
      defaultImageUrl: ZDDefaultProfileIMG,
      disabled: true,
      genreView: false,
      isFiltered: false,
      isGenreActive:false,
      newReleaseActive: false,
      topDownloadsActive: false,
      trendingChartsActive: false,
      topChartsActive: false,
      isSearchBarFocused: false,
      genrePreferance: null,
      genre_sectons: ['new-release', 'top-downloads', 'trending-charts'],
      pageTypeFilter: '',
      trendingPageType: null,
      query_url: ' ',
    };

    this.title = document.title;
  }

  clearFilter = e => {
    // e.preventDefault();
    zdsetClearGenreFilter(true);
    zdsetGenreDropDownValue('');
    zdsetDateDropDownValue('');
    this.props.history.push({
      search: '',
    });
    this.props.setIsBpmRangeActive(false);
    localStorage.removeItem('breadcrumb');
  };

  updateFilters() {
    let currentSearch = queryString.parse(window.location.search, {
      arrayFormat: 'index',
    });
    this.setState({
      isFiltered:
        has(currentSearch, 'label_name') ||
        has(currentSearch, 'lm_label_name') ||
        has(currentSearch, 'track_release_id') ||
        has(currentSearch, 'genre_id') ||
        has(currentSearch, 'artist_id') ||
        has(currentSearch, 'tag_id') ||
        has(currentSearch, 'all_genre_id') ||
        has(currentSearch, 'sort'),
    });
    this.setState({
      isGenreActive:
        has(currentSearch, 'genre_id') ||
        has(currentSearch, 'tag_id') ||
        has(currentSearch, 'all_genre_id')
    });
    
  }
  clearSearch(currentSearch) {
    currentSearch['artist_id'] = [];
    currentSearch['label_name'] = [];
    currentSearch['lm_label_name'] = [];
    currentSearch['track_release_id'] = [];
    currentSearch['tag_id'] = [];
    currentSearch['genre_id'] = [];
    currentSearch['lm_label_name'] = [];
    currentSearch['all_genre_id'] = [];

    return currentSearch;
  }

  genreFilter = e => {
    // zdShowSearchLoader();
    e.preventDefault();
    zdsetClearGenreFilter(false);
    let genre_id = e.currentTarget.getAttribute('data-id');
    let genre_parent = e.currentTarget.getAttribute('data-parent-genre');
    let genre_parent_id = e.currentTarget.getAttribute('data-parent-genre-id');
    let genre_name = e.currentTarget.getAttribute('data-genre');
    const data = {
      genre_id: genre_id,
      type: 'selectMenu',
    };
    this.props.getGenreNameByID(data);
    if (genre_id) {
      !this.state.genreView
        ? zdGenreFilter(
            genre_id,
            genre_name,
            genre_parent_id,
            genre_parent,
            this.props,
          )
        : zdTrackGenreFilter(
            genre_id,
            genre_name,
            genre_parent_id,
            genre_parent,
            this.props,
          );
    }
  };

  setActiveTab = activeTab => {
    // Making active for relative path To make the nav link active
    if (activeTab === 'new-release') {
      this.setState({
        newReleaseActive: true,
        topDownloadsActive: false,
        trendingChartsActive: false,
        topChartsActive: false,
      });
    } else if (activeTab === 'top-downloads') {
      this.setState({
        newReleaseActive: false,
        topDownloadsActive: true,
        trendingChartsActive: false,
        topChartsActive: false,
      });
    } else if (activeTab === 'top-chart') {
      this.setState({
        newReleaseActive: false,
        topDownloadsActive: false,
        trendingChartsActive: false,
        topChartsActive: true,
      });
    } else if (activeTab === 'trending-charts') {
      this.setState({
        newReleaseActive: false,
        topDownloadsActive: false,
        trendingChartsActive: true,
        topChartsActive: false,
      });
    } else {
      this.setState({
        newReleaseActive: false,
        topDownloadsActive: false,
        trendingChartsActive: false,
        topChartsActive: false,
      });
    }
  };

  componentDidMount() {
    if (this.props.headerActiveTab !== undefined) {
      this.setActiveTab(this.props.headerActiveTab);
    }
    this.updateFilters();
  }

  componentDidUpdate(prevProps) {
    const pages = ['New Releases', 'Top Downloads', 'Trending Chart'];
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.updateFilters();
    }
    const checkTitle = title => title === this.props.title;

    if (prevProps.title !== this.props.title) {
      if (pages.some(checkTitle)) {
        this.setState({
          disabled: false,
          genreView: false,
        });
      } else if (this.props.title === 'HOME') {
        this.setState({
          disabled: false,
          genreView: true,
        });
      } else if (this.props.title === 'Genre View') {
        this.setState({
          disabled: false,
          genreView: true,
        });
      } else {
        this.setState({
          disabled: true,
          genreView: false,
        });
      }
    }

    if (prevProps.headerActiveTab !== this.props.headerActiveTab) {
      this.setActiveTab(this.props.headerActiveTab);
    }
  }

  zdDownloadManagerToggle() {
    document.body.classList.toggle('zd-slide-open');
  }

  zdSearchWidgetToggle() {
    document.body.classList.toggle('zd-search-open');
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState(
      {
        isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
      },
      () => {},
    );
    // let e = document.getElementById("zd-header-user-name");
    let event = document.getElementById('zdv3-icon-drop-down-popup');
    if (!this.state.isOpenUserCardPopover) {
      event.classList.remove('zdv3-icon-drop-down');
      event.classList.add('zdv3-icon-up-arrow');
    } else {
      event.classList.add('zdv3-icon-drop-down');
      event.classList.remove('zdv3-icon-up-arrow');
    }
    let image_src = this.state.defaultImageUrl;
    if (this.props.profileImageUrl) {
      image_src = document.getElementById('zd-header-profile-image').src;
    }

    setTimeout(function () {
      if (document.getElementById('zd-popover-body-image')) {
        document.getElementById('zd-popover-body-image').src = image_src;
      }
    }, 300);
  };

  closePopover = () => {
    this.setState(
      {
        isOpenUserCardPopover: false,
      },
      () => {},
    );
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleProfileUrl = () => {
    this.props.changeProfileSectiontoBack('ready');

    this.props.history.push('/member/profile');
  };

  handleSearchGenre = () => {
    this.zdSearchWidgetToggle();
    this.props.showMegaMenu();
  };

  changeProfileTab = e => {
    let active_tab = e.currentTarget.getAttribute('data-name');
    this.props.changeProfileSection(active_tab);
  };
  handleGenre = () => {
    this.props.setGenrePreApiLoad('yes');
    this.props.setGenrePreferenceStatus(false);
    this.props.setIsBpmClearGenPre(false);
    localStorage.removeItem('breadcrumb');
  };

  render() {
    this.title = document.title;
    let download_size =
      (this.props.totalDownloadSize
        ? Number(this.props.totalDownloadSize).toFixed(1)
        : 0) + ' MB';

    let genre_preference = JSON.parse(localStorage.getItem('genre_preference'));

    let query_url = '';
    let activeGenrePreference =
      this.props.activeGenrePreference.length > 0
        ? this.props.activeGenrePreference
        : genre_preference;

    if (activeGenrePreference && activeGenrePreference.length > 0) {
      activeGenrePreference &&
        activeGenrePreference.length &&
        activeGenrePreference.map((tag_id, index) => {
          if (index !== 0) {
            query_url = query_url + '&tag_id[' + index + ']=' + tag_id;
          } else {
            query_url = query_url + 'tag_id[' + index + ']=' + tag_id;
          }
        });
    }

    return (
      <>
        <Navbar light expand className={bem.b('bg-dark')}>
          <Nav navbar className="zd-filter-middle zd-middle-nav-items mx-auto">
            <NavItem className="d-flex">
              <BSNavLink
                tag={NavLink}
                to={`/member/newrelease?${query_url}`}
                className="zd-header-link"
                exact={false}
                active={this.state.newReleaseActive}
                activeClassName="current-active"
              >
                <span onClick={this.handleGenre}>New Releases</span>
              </BSNavLink>
              <BSNavLink
                tag={NavLink}
                to={`/member/topdownloads?${query_url}`}
                exact={true}
                className="zd-header-link"
                active={this.state.topDownloadsActive}
                activeClassName="current-active"
              >
                <span onClick={this.handleGenre}>Top Downloads</span>
              </BSNavLink>

              <BSNavLink
                tag={NavLink}
                to={`/member/all?${query_url}`}
                className="zd-header-link"
                active={this.state.trendingChartsActive}
                activeClassName="current-active"
                exact={true}
              >
                <span onClick={this.handleGenre}> Trending Charts</span>
              </BSNavLink>

              <BSNavLink
                tag={NavLink}
                to={`/member/charts/dance?${query_url}`}
                className="zd-header-link"
                active={this.state.topChartsActive}
                activeClassName="current-active"
                exact={false}
              >
                Top Charts
              </BSNavLink>
            </NavItem>
          </Nav>

          <Nav id="search-genres" className="search-genre zd-head-bound">
            <span
              style={{
                fontSize: '12px',
                display: this.state.isFiltered ? 'block' : 'none',
                color: '#4f7be9',
              }}
              className="pl-1 my-auto zd-cursor zd-tag-reset"
              title="Reset"
              onClick={this.clearFilter}
            >
              <i className="zdv3-icon-reset mr-2"></i>
            </span>
            <NavItem className=" d-flex zd-search-genre-btn">
              <span
                style={
                  this.state.disabled
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'all' }
                }
              >
                <MegaGenreDropDown
                  {...this.props}
                  dropDownValue={this.props.dropDownValue}
                  genreDropDownFilter={this.genreFilter}
                  tagFilter={this.props.tagFilter}
                  labelFilter={this.props.labelFilter}
                  isFiltered={this.state.isFiltered}
                  isGenreActive={this.state.isGenreActive}
                  color={this.state.disabled ? '#303440' : null}
                  handleSearchGenre={this.props.handleSearchGenre}
                  activeTracksList={this.props.activeTracksList}
                  setActiveTracksList={this.props.setActiveTracksList}
                />
              </span>
            </NavItem>
          </Nav>
          {/* <Nav className="zd-genre_menu"
          onClick={this.props.handleSearchGenre}
          >
            <img src={genre} width={24} height={24} />
            Genre</Nav> */}
          <Nav
            navbar
            className={`zd-header-search-wrap zd-hide-mobile w-50 ${
              this.state.isSearchBarFocused ? 'searchbar-focused' : ''
            }`}
            onFocus={() => this.setState({ isSearchBarFocused: true })}
            onBlur={() => this.setState({ isSearchBarFocused: false })}
          >
            <NavItem>
              <SearchInput {...this.props} />
            </NavItem>
          </Nav>

          <Nav navbar className={`${bem.e('nav-right')} d-inline-flex`}>
            <NavItem className="d-inline-flex zd-show-mobile">
              <BSNavLink
                className="position-relative zd-nav-search-btn zd-v5-mob-search"
                // onClick={this.zdSearchWidgetToggle}
                onClick={this.props.handleSearchGenre}
              >
                <div className="">{<MdSearch />}</div>
              </BSNavLink>
            </NavItem>
            {/* ------------------------------profile on header-------------- */}
            <NavItem className="d-inline-flex zd-show-mobile">
              <BSNavLink className="position-relative zd-nav-search-btn">
                <div className="zd-sm-header-profile">
                  <div
                    className="zd-sm-sidebar-profile-img"
                    onClick={this.handleProfileUrl}
                  >
                    {this.props.profileImageUrl ? (
                      <img src={this.props.profileImageUrl} alt="User Name" />
                    ) : (
                      <i className="zdv3-icon-profile"></i>
                    )}
                  </div>
                </div>
              </BSNavLink>
            </NavItem>

            <NavItem className="d-inline-flex zd-download-manager-btn ">
              <BSNavLink className="position-relative zd-head-feedback-text zd-hide">
                <div className="d-inline-block position-relative">
                  {
                    <ReportIssueModal
                      profileFullName={
                        this.props.profileHeaderDetails
                          ? this.props.profileHeaderDetails.member_firstname +
                            ' ' +
                            this.props.profileHeaderDetails.member_lastname
                          : ''
                      }
                      profileEmail={
                        this.props.profileHeaderDetails
                          ? this.props.profileHeaderDetails.member_email
                          : ''
                      }
                    />
                  }
                </div>
              </BSNavLink>

              <BSNavLink
                className="position-relative pt-0"
                onClick={this.zdDownloadManagerToggle}
              >
                <div className="zd-download-manager">
                  <span className="zd-header-download-size">
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.79781 2C5.6125 2 4.54003 2.7029 4.06705 3.78975L2.04647 8.43278L2.00003 19.0219C1.99279 20.6718 3.32826 22.0131 4.97814 22.0131H19.0219C20.6666 22.0131 22 20.6797 22 19.035V8.45011L19.9785 3.79244C19.5062 2.70415 18.4329 2 17.2466 2H6.79781ZM20.5705 9.14753H14.8525V12.0065C14.8525 12.796 14.2125 13.4361 13.423 13.4361H10.5639C9.77445 13.4361 9.13444 12.796 9.13444 12.0065V9.14753H3.41642V19.2732C3.41642 19.9969 4.00309 20.5836 4.7268 20.5836H19.2601C19.9838 20.5836 20.5705 19.9969 20.5705 19.2732V9.14753ZM13.423 12.0065H10.5639V9.14753H13.423V12.0065ZM20.131 7.71803L18.6227 4.28413C18.3946 3.76483 17.881 3.42951 17.3138 3.42951H14.0503L14.8289 7.71803H20.131ZM9.13997 7.71803H3.90031L5.4086 4.28413C5.63669 3.76483 6.15023 3.42951 6.71741 3.42951H9.91847L9.13997 7.71803ZM11.3584 3.42951H12.6103L13.3889 7.71803H10.5799L11.3584 3.42951Z" fill="#ABB1B8"/>
                      </svg> */}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill="#6b7883"
                        fill-rule="evenodd"
                        d="M5.697.983a2.9 2.9 0 00-2.821 2.23l-1.86 7.83c-.053.219-.08.444-.08.67v3.363a2.9 2.9 0 002.9 2.9H15.04a2.9 2.9 0 002.9-2.9v-3.363c0-.226-.027-.451-.079-.67L16 3.212a2.9 2.9 0 00-2.822-2.23h-7.48zm-1.07 2.645a1.1 1.1 0 011.07-.845h7.481c.51 0 .952.35 1.07.845l1.86 7.83.005.02H13.58a2 2 0 00-1.69.93l-.342.54a2 2 0 01-1.69.93h-.84a2 2 0 01-1.689-.93l-.342-.54a2 2 0 00-1.69-.93H2.763l.004-.02 1.861-7.83z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span
                      className="position-absolute badge badge-primary rubberBand "
                      id="zd_v5_download_count"
                    >
                      <small>
                        {this.props && 'totalDownloadItems' in this.props
                          ? this.props.totalDownloadItems
                          : '0'}
                      </small>
                    </span>
                    <span
                      className="zd_v5_spinner_wrapper"
                      id="zd_v5_download_spinner"
                      style={{
                        display: 'none',
                      }}
                    >
                      <span className="zd-v5_spinner"></span>
                    </span>
                    <span className="download-size">{download_size}</span>
                  </span>
                </div>
              </BSNavLink>
            </NavItem>

            <NavItem className="zd-profile-dropdown">
              <BSNavLink id="Popover2">
                <span
                  id="zd-header-user-name"
                  className={
                    this.state.isOpenUserCardPopover
                      ? 'can-click zd-username-menu active'
                      : 'can-click zd-username-menu'
                  }
                  onClick={this.toggleUserCardPopover}
                >
                  <span className="zd-header-profile-icon">
                    {!this.props.profileImageUrl ? (
                      <i className="zdv3-icon-profile"></i>
                    ) : (
                      <Avatar
                        className="can-click"
                        id="zd-header-profile-image"
                        src={this.props.profileImageUrl}
                      />
                    )}
                  </span>
                  <span className="zd-header-profile-name align-self-center pl-1">
                    <i
                      className={
                        this.state.isOpenUserCardPopover
                          ? 'zdv3-icon-up-arrow'
                          : 'zdv3-icon-drop-down'
                      }
                      id="zdv3-icon-drop-down-popup"
                    ></i>
                  </span>
                </span>
              </BSNavLink>
              <Popover
                placement="bottom-end"
                trigger=" legacy"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.closePopover}
                target="Popover2"
                className="p-0 border-0 zd-profile-popover"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0">
                  <ProfileUserCard
                    title={
                      this.props.profileHeaderDetails
                        ? this.props.profileHeaderDetails.member_firstname +
                          ' ' +
                          this.props.profileHeaderDetails.member_lastname
                        : ''
                    }
                    subtitle={
                      this.props.profileHeaderDetails
                        ? this.props.profileHeaderDetails.member_email
                        : ''
                    }
                    avatar={
                      this.props.profileImageUrl
                        ? this.props.profileImageUrl
                        : this.state.defaultImageUrl
                    }
                    text=""
                    className=""
                  >
                    <ListGroup flush>
                      <span
                        onClick={() =>
                          this.setState({
                            isOpenUserCardPopover: false,
                          })
                        }
                      >
                        {zdProfileNavs.map(
                          ({ to, name, value, exact, badge }, index) => (
                            <ListGroupItem tag="li" action key={index}>
                              <BSNavLink
                                tag={NavLink}
                                to={to}
                                activeClassName="active"
                                exact={exact}
                                onClick={this.changeProfileTab}
                                data-name={value}
                              >
                                {name}
                                {badge !== '' ? (
                                  <b className="position-absolute badge badge-primary rubberBand">
                                    <small>{badge}</small>
                                  </b>
                                ) : (
                                  ''
                                )}
                              </BSNavLink>
                            </ListGroupItem>
                          ),
                        )}
                      </span>
                    </ListGroup>
                  </ProfileUserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
        <div
          style={{
            display: this.state.isFiltered ? 'block' : 'none',
          }}
        >
          <div
            className={
              this.state.genre_sectons.includes(this.props.headerActiveTab)
                ? 'genre  d-none'
                : 'genre d-none'
            }
          >
            <div className="d-flex justify-content-end">
              <span
                style={{
                  fontSize: '12px',
                  display: this.state.isFiltered ? 'block' : 'none',
                  color: '#4f7be9',
                }}
                className="my-auto zd-cursor zd-tag-reset"
                title="Reset"
                onClick={this.clearFilter}
              >
                <i className="zdv3-icon-reset"></i>
              </span>
              <DropDownGenrePre
                {...this.props}
                pageType={this.props.newPageType}
                trendingPage={this.props.newTrandingPageType}
                genrePreferance={this.state.genrePreferance}
                setGenrePreferance={data => {
                  this.setState({ genrePreferance: data });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
