import React, { Fragment, useRef } from 'react';
import cls from 'classnames';
import { FaHeadphones } from 'react-icons/fa';
import { MdLibraryMusic } from 'react-icons/md';

import ReactDragListView from 'react-drag-listview/lib/ReactDragListView';

const AudioListsPanel = ({
  onAudiopause,
  audioLists,
  notContentText,
  onCancel,
  onDelete,
  onPlay,
  pause,
  playId,
  loading,

  closeIcon,

  panelToggleAnimate,
  glassBg,
  remove,
  removeId,
  audioListsDragEnd,
  isMobile,
}) => {
  const panel = useRef();
  const arrow = useRef();

  const expandHistory = e => {
    if (panel.current && arrow.current) {
      if (panel.current.classList.contains('expand')) {
        panel.current.classList.remove('expand');
        arrow.current.classList.remove('expand-arrow');
      } else {
        panel.current.classList.add('expand');
        arrow.current.classList.add('expand-arrow');
      }
    }
  };

  // const togglePlay = () => {
  //   setPause;
  // };

  return (
    <div
      className={cls('audio-lists-panel', panelToggleAnimate, {
        'glass-bg': glassBg,
      })}
      key="audio-list-panel"
      ref={panel}
    >
      <div className="audio-lists-panel-header">
        <h2 className="title d-flex">
          <span key="panel-title">History </span>
          <span className="clear-all" key="clear-all" onClick={onDelete()}>
            Clear all
          </span>

          <Fragment>
            <span
              className="expand-history-btn zd-cursor ml-auto pr-2"
              title="Expand history"
              onClick={expandHistory}
              ref={arrow}
            >
              <i className="zdv3-icon-up-arrow"></i>
            </span>
          </Fragment>
          <span
            key="close-btn"
            className="close-btn"
            title="Close"
            onClick={onCancel}
          >
            {isMobile ? <FaHeadphones /> : closeIcon}
          </span>
        </h2>
      </div>

      <div
        className={cls('audio-lists-panel-content', {
          'no-content': audioLists.length < 1,
        })}
        key="audio-lists-panel-content"
      >
        {audioLists.length >= 1 ? (
          <ReactDragListView
            nodeSelector="li"
            handleSelector=".player-name"
            lineClassName="audio-lists-panel-drag-line"
            onDragEnd={audioListsDragEnd}
          >
            <ul>
              {audioLists.map((audio, i) => {
                const { name, singer } = audio;
                const playing = playId === audio.id;
                return (
                  <li
                    key={i}
                    title={
                      pause
                        ? 'Click to play'
                        : playing
                        ? 'Click to pause'
                        : 'Click to play'
                    }
                    className={cls(
                      'audio-item',
                      { playing },
                      { pause },
                      { remove: removeId === audio.id },
                    )}
                    onClick={() => {
                      if (pause === false) {
                        onAudiopause();
                      } else {
                        onPlay(audio.id);
                      }
                    }}
                  >
                    <span className="group player-status" key="player-status">
                      <span className="player-icons" key={`player-icons-${i}`}>
                        {playing && loading ? (
                          loading
                        ) : playing ? (
                          pause ? (
                            <i
                              className="zdv3-icon-play"
                              onClick={() => {
                                onAudiopause();
                              }}
                            ></i>
                          ) : (
                            <i
                              className="zdv3-icon-pause"
                              onClick={() => {
                                if (pause === true) {
                                  onAudiopause();
                                } else {
                                  onPlay(audio.id);
                                }
                              }}
                            ></i>
                          )
                        ) : (
                          <i
                            className="zdv3-icon-play"
                            onClick={() => {
                              if (pause === true) {
                                onAudiopause();
                              } else {
                                onPlay(audio.id);
                              }
                            }}
                          ></i>
                        )}
                      </span>
                    </span>

                    <div className="d-flex flex-column w-75 audio-item-song-details">
                      <span className="text-truncate audio-item-name">
                        {name}
                      </span>
                      <span className="text-truncate audio-item-singer">
                        {singer}
                      </span>
                    </div>
                    {/* <span className="group player-name" key="player-name">
                    {name}
                  </span>
                  <span className="group player-time" key="player-time">
                    {singer}
                  </span> */}
                    {remove ? (
                      <span
                        className="group player-delete"
                        key="player-delete"
                        title={`Click to delete ${name}`}
                        onClick={onDelete(audio.id)}
                      >
                        {closeIcon}
                      </span>
                    ) : undefined}
                  </li>
                );
              })}
            </ul>
          </ReactDragListView>
        ) : (
          <Fragment>
            <span key="no-content">
              <MdLibraryMusic />
            </span>
            <span className="no-data" key="no-data">
              {notContentText}
            </span>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AudioListsPanel;
