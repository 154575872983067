import React from 'react';

const ZDMediaPauseIcon = ({className}) => {
    const IconClassNames = (className) ? `zd-media-pause-icon ${className}` : 'zd-media-pause-icon';
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="0.9em" height="0.9em" viewBox="0 0 50 50" className={IconClassNames} stroke="currentColor" fill="currentColor">
    <path d="M25 3.569c-11.832 0-21.431 9.598-21.431 21.431s9.598 21.431 21.431 21.431c11.832 0 21.431-9.598 21.431-21.431s-9.598-21.431-21.431-21.431zM25 4.172c11.506 0 20.828 9.321 20.828 20.828s-9.321 20.828-20.828 20.828c-11.506 0-20.828-9.321-20.828-20.828s9.321-20.828 20.828-20.828zM19.151 18.226v12.931h1.847v-12.931h-1.847zM28.388 18.226v12.931h1.847v-12.931h-1.847z"></path>
    </svg>
  );
};
export default ZDMediaPauseIcon;
