import React, { useEffect, useState } from 'react';
import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';

import Avatar from '../Avatar';

const ProfileUserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const [fullUserName, setFullUserName] = useState('');
  useEffect(() => {
    if (localStorage.getItem('_zd_user_name')) {
      let name = localStorage.getItem('_zd_user_name');
      setFullUserName(name);
    }
  }, []);

  return (
    <Card inverse {...restProps} className="bg-gradient-theme">
      <CardBody className="text-center">
        <Avatar
          id="zd-popover-body-image"
          src={avatar}
          size={avatarSize}
          className="mb-2"
        />
        <CardTitle id="zd-popover-body-title">
          {fullUserName ? fullUserName : title}
        </CardTitle>
        <CardSubtitle id="zd-popover-body-subtitle">{subtitle}</CardSubtitle>
        <CardText>
          <small>{text}</small>
        </CardText>
      </CardBody>
      {children}
    </Card>
  );
};

ProfileUserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

ProfileUserCard.defaultProps = {
  avatarSize: 80,
};

export default ProfileUserCard;
