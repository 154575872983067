import axios from 'axios';
import React, { useEffect } from 'react';

const CheckValidEsID = () => {
  useEffect(() => {
    let loginInfo = localStorage.getItem('_zd_tk');
    if (loginInfo === null) {
      window.location.href = '/login';
    }
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo);
      checkElasticSearchValidAlias();
    }
  }, []);

  const checkElasticSearchValidAlias = () => {
    // console.log('checkElasticSearchValidAlias');
    axios
      .get(
        process.env.REACT_APP_ELASTICSEARCH_BASE_URL +
          '/_alias/' +
          window.zdMemberEsId(),
        {
          headers: {
            Authorization:
              'ApiKey ' + process.env.REACT_APP_ELASTICSEARCH_API_KEY,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(res => {
        console.log(res);
      });
  };

  return <></>;
};

export default CheckValidEsID;
