import queryString from 'query-string';
// import { useLocation } from 'react-router-dom';
import { zdSetBreadcrumb } from 'utils/Helpers';
import {
  zdsetClearGenreFilter,
  zdsetDateDropDownValue,
  zdsetGenreDropDownValue,
} from '../utils/Helpers';
/**
 *  All filter methods
 */

/* reset all the previous filter */
export function clearSearch(currentSearch, props) {
  // Clear all the default fields
  currentSearch = {};

  currentSearch['artist_id'] = [];
  currentSearch['label_name'] = [];
  currentSearch['lm_label_name'] = [];
  currentSearch['track_release_id'] = [];
  currentSearch['tag_id'] = [];
  currentSearch['genre_id'] = [];
  currentSearch['all_genre_id'] = [];
  currentSearch['lm_label_name'] = [];
  currentSearch['track_key'] = [];

  return currentSearch;
}

/**
 *  Artist filter on same page
 */

export function zdArtistFilter(artist_id, artist_name, props) {
  zdSetBreadcrumb('Artist', artist_name);
  let currentSearch = queryString.parse(props.location.search, {
    arrayFormat: 'index',
  });
  currentSearch = clearSearch(currentSearch);
  currentSearch['artist_id'].push(artist_id);
  props.history.push({
    search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
  });
}

/**
 *  artist filter redirects to New release track-view
 */
export function zdTrackArtistFilter(artist_id, artist_name, props) {
  clearFilterAll();
  zdSetBreadcrumb('Artist', artist_name);
  props.history.push(`/member/newrelease/track-view?artist_id[0]=${artist_id}`);
}

/**
 *  Label filter on same page
 */

export function zdLabelFilter(label_name, props) {
  let currentSearch = queryString.parse(props.location.search, {
    arrayFormat: 'index',
  });
  zdSetBreadcrumb('Label', label_name);
  currentSearch = clearSearch(currentSearch);
  currentSearch['label_name'].push(label_name);
  props.history.push({
    search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
  });
}

/**
 *  label filter redirects to New release track-view
 */
export function zdTrackLabelFilter(label_name, props) {
  zdSetBreadcrumb('Label', label_name);
  props.history.push(
    `/member/newrelease/track-view?label_name[0]=${encodeURIComponent(
      label_name,
    )}`,
  );
}

/**
 *  Genre filter on same page
 */

export function zdGenreFilter(
  genre_id,
  genre_name,
  genre_parent_id,
  genre_parent,
  props,
) {
  let currentSearch = queryString.parse(props.location.search, {
    arrayFormat: 'index',
  });
  currentSearch = clearSearch(currentSearch);
  if (
    genre_name === 'AFRO / LATIN / BRAZILIAN' ||
    genre_name === 'MOOMBAHTON'
  ) {
    currentSearch['tag_id'].push(genre_id);
  } else {
    currentSearch['all_genre_id'].push(genre_id);
  }
  zdSetBreadcrumb('Genre', genre_name, genre_parent, genre_parent_id);
  // let pathName = window.location.pathname;
  // alert(pathName);
  // let search = queryString.stringify(currentSearch, { arrayFormat: 'index' });

  // if (pathName === '/member' || pathName === '/member/newrelease') {

  //   search = queryString.stringify(currentSearch, { arrayFormat: 'index' });
  //   window.location = `/member/newrelease/track-view?${search}`;
  //   return;
  // }
  props.history.push({
    search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
  });
}

/**
 *  Genre filter redirects to New release track-view
 */
export function zdTrackGenreFilter(
  genre_id,
  genre_name,
  genre_parent_id,
  genre_parent,
  props,
) {
  zdSetBreadcrumb('Genre', genre_name, genre_parent, genre_parent_id);
  props.history.push(
    `/member/newrelease/track-view?all_genre_id[0]=${genre_id}`,
  );
}

/**
 *  Tag filter on same page
 */

export function zdTagFilter(
  tag_id,
  tag_name,
  genre_parent_id,
  genre_parent,
  props,
) {
  let currentSearch = queryString.parse(props.location.search, {
    arrayFormat: 'index',
  });
  zdSetBreadcrumb('Tag', tag_name, genre_parent, genre_parent_id);
  currentSearch = clearSearch(currentSearch);
  currentSearch['tag_id'].push(tag_id);
  let tag_ids = [
    [4, 2, 53],
    [31, 12, 16, 50],
    [11, 22],
  ];
  tag_ids.every(element => {
    if (element.includes(parseInt(tag_id))) {
      currentSearch = clearSearch(currentSearch);
      element.forEach(id => {
        // if (!currentSearch['tag_id'].includes(id.toString())) {
        currentSearch['tag_id'].push(id.toString());
        // }
      });
      return false;
    }
    return true;
  });
  props.history.push({
    search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
  });
}

/**
 *  Tag filter redirects to New release track-view
 */
export function zdTrackTagFilter(
  tag_id,
  tag_name,
  genre_parent_id,
  genre_parent,
  props,
) {
  zdSetBreadcrumb('Tag', tag_name, genre_parent, genre_parent_id);
  let tag_ids = [
    [2, 4, 53],
    [31, 12, 16, 50],
    [11, 22],
  ];
  let tag_id_filter = 'tag_id[0]=' + tag_id;
  tag_ids.every(element => {
    if (element.includes(parseInt(tag_id))) {
      element.forEach((id, index) => {
        if (tag_id !== id.toString()) {
          tag_id_filter += '&tag_id[' + index + ']=' + id.toString();
        }
      });
      return false;
    }
    return true;
  });
  props.history.push(`/member/newrelease/track-view?${tag_id_filter}`);
}

export function clearFilterAll(props) {
  // this.setState({ genrePreferance: true });
  zdsetClearGenreFilter(true);
  zdsetGenreDropDownValue('');
  zdsetDateDropDownValue('');

  localStorage.removeItem('breadcrumb');
  return true;
}
