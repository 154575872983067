import React, { useEffect, useState } from 'react';
import BottomBar from './BottomBar';
import MegaMenu from './MegaMenu';
import RightBar from './RightBar';
import gsap from 'gsap';
import './Main.css';
import MegaGenre from './megaMenuComponents/MegaGenre';
import GenreFilter from './GenreFilter';

const Main = props => {
  const [isActive, setIsActive] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);

  useEffect(() => {
    
    animation();
    return () => {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('body-overflow-hidden');
    };

  }, []);

  const animation = () => {
    const tl = gsap.timeline();
    tl.to('#mainMega', {
      opacity: 1,
    });
  };

  const handleClickAlphabeticView = () => {
    setShowAllTags(!showAllTags);
    document.getElementById('bot').scrollIntoView({
      behavior: 'smooth',
    })
  }
  
  return (
    <div className={props.activeTracksList.length>0 ? "main mainMega-mobile" : "main"} id="mainMega" style={{ opacity: 0 }}>
      {/* <div>
       
        <h2 className="genre_heading">
          <span className="default active">DEFAULT VIEW</span>
          <span
            className="alpha "
            onClick={handleClickAlphabeticView}
          >
            ALPHABETICAL VIEW
          </span>
        </h2>
      </div> */}
      <div className= {props.activeTracksList.length>0 ? "var-container var-container-tablet" : "var-container"}>
        <>
          {isActive ? (
            <div className="mega_genres">
              <MegaGenre setIsActive={setIsActive} isActive={isActive} />
            </div>
          ) : (
            <>
              <div id="top" className="var-container__top">
                <GenreFilter 
                 megaTagFilter={props.megaTagFilter}
                 megagenreDropDownFilter={props.megagenreDropDownFilter}
                 setIsActive={setIsActive}
                  isActive={isActive}
                 {...props}
                 showAllTags = {showAllTags}
                 setShowAllTags = {setShowAllTags}
                 activeTracksList ={props.activeTracksList}
                 setActiveTracksList={props.setActiveTracksList}
                />
                {/* <MegaMenu
                  {...props}
                
                  
                  megagenreDropDownFilter={props.megagenreDropDownFilter}
                  setIsActive={setIsActive}
                  isActive={isActive}
                  
                />
                <RightBar
                {...props}
                  megagenreDropDownFilter={props.megagenreDropDownFilter}
                /> */}
              </div>
              {/* <div id="bot">
                
                <BottomBar 
                  {...props} 
                  megaTagFilter={props.megaTagFilter}
                  showAllTags = {showAllTags}
                  setShowAllTags = {setShowAllTags}
                  activeTracksList ={props.activeTracksList}
                  setActiveTracksList={props.setActiveTracksList}
                />
              </div> */}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Main;
