import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { clearSearch } from '../../utils/Filters';

axios.defaults.timeout = 60000;

const GenrePreDropDown = props => {
  const [dropdownOpenNewest, setDropdownOpenNewest] = useState(false);
  const [genrePreferance, setGenrePreferance] = useState([]);
  let history = useHistory();

  const toggle = e => {
    setDropdownOpenNewest(prevState => !prevState);
  };

  useEffect(() => {
    if (props.genrePreApiLoad === 'yes') {
      getPreference();
      setTimeout(() => {
        props.setGenrePreApiLoad('No');
      }, 3000);
    }
  }, [props.genrePreApiLoad]);

  useEffect(() => {
    let pathname = window.location.href;
    if (pathname.includes('artist_id') || pathname.includes('label_name')) {
      setGenrePreferance([]);
    } else {
      if (props.genrePreferenceStatus === false) {
        props.setGenrePreferance(false);
      }
      if (props.genrePreferance || props.genrePreferenceStatus === true) {
        setGenrePreferance([]);
      } else {
        getPreference();
      }
    }
  }, [props.genrePreferance, props.genrePreferenceStatus]);

  const getPreference = () => {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (loginInfo.token) {
      const api = {
        baseUrl:
          process.env.REACT_APP_API_PATH + '/zdmapi/get-genre-preferences',
        xSessionToken: loginInfo.token,
      };
      let url = api.baseUrl;

      axios
        .get(url, {
          headers: { 'X-Session-Token': api.xSessionToken },
        })
        .then(res => {
          if (!res.data.error) {
            setGenrePreferance(res.data.name);
            // localStorage.setItem(
            //   'genre_preference',
            //   JSON.stringify(res.data.data),
            // );
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const handleRemove = id => {
    props.setGenrePreApiLoad('No');

    setDropdownOpenNewest(prevState => !prevState);
    let filteredGenPreference = genrePreferance.filter(
      item => item.tag_id !== id,
    );
    setGenrePreferance(filteredGenPreference);
    let newarr = [];
    filteredGenPreference.map(p => {
      newarr.push(p.tag_id);
    });
    let currentSearch = queryString.parse(props.location.search, {
      arrayFormat: 'index',
    });
    let track_key = currentSearch['track_key']
      ? currentSearch['track_key']
      : [];
    if (Object.keys(currentSearch).length > 0) {
      currentSearch = clearSearch(currentSearch);
      newarr.length &&
        newarr.forEach(id => {
          currentSearch['tag_id'].push(id.toString());
        });
      if (track_key.length > 0) {
        track_key.forEach(id => {
          currentSearch['track_key'].push(id.toString());
        });
      }

      history.push({
        search: queryString.stringify(currentSearch, { arrayFormat: 'index' }),
      });
    }
  };

  return (
    <>
      <div className="mr-2 zd-nowrap">
        {genrePreferance.length > 0 ? (
          <Dropdown isOpen={dropdownOpenNewest} toggle={toggle}>
            <DropdownToggle className="zd-top-dropdown zd-dropdown key-btn-clr  dropdown_key top-downloads-filter-btn">
              <span className="" style={{ color: '#4F7BE9' }}>
                {' '}
                Genre Preference ({genrePreferance.length})
              </span>
            </DropdownToggle>
            <DropdownMenu className="zd-history-drop-down genre-preference-menu">
              {genrePreferance.map((item, index) => {
                return (
                  <DropdownItem className="d-flex" key={index}>
                    <span className="mr-3">{item.tag_key}</span>{' '}
                    <span className="ml-auto filter-close-btn">
                      <AiFillCloseCircle
                        onClick={() => handleRemove(item.tag_id)}
                        size={12}
                        mr-1="true"
                      />
                    </span>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        ) : null}
      </div>
    </>
  );
};

export default GenrePreDropDown;
