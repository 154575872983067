/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  Collapse,
  CardBody,
} from 'reactstrap';
import ZipDJIcon from 'components/ZipDJIcon';
import axios from 'axios';
import ZDWaveLoader from 'components/ZDWaveLoader';
import { zdCheckAuthOnAjax, playTrack, joinArtist } from 'utils/Helpers';
import { GoFileZip } from 'react-icons/go';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const _shuffle = require('lodash/shuffle');

axios.defaults.timeout = 180000;

class ZDSlideDownloadManagerWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unauthorized: false,
      downloadItems: [],
      lastDownloadItems: null,
      totalSize: '',
      totalItems: 0,
      zdLoader: false,
      message: '',
      activeTab: 1,
      loadingText: '',
      showDownloadTracks: true,
      showPastDownloads: false,
      clearAllPopup: false,
    };
  }

  zdTabToggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  componentDidMount(previousProps, previousState) {
    this.getDownloadListItems();
    this.setState({ activeTab: 1 });
  }

  setTotalDownloads = item => {
    this.props.getTotalDownloadItems(item);
  };

  downloadManagerGlobalData = data => {
    this.props.downloadManagerGlobalData(data);
  };

  getDownloadListItems = callback => {
    let self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    if (typeof callback == 'undefined') {
      callback = function () {};
    }

    if (loginInfo) {
      const api = {
        baseUrl: process.env.REACT_APP_API_PATH + '/zdmapi/download-manager',
        xSessionToken: loginInfo.token,
      };

      axios
        .post(
          api.baseUrl,
          {},
          {
            timeout: 180 * 1000,
            headers: {
              'X-Session-Token': api.xSessionToken,
            },
          },
        )
        .then(function (response) {
          const data = response.data;
          self.setState({
            downloadItems: [],
            totalSize: '',
            totalItems: 0,
            message: '',
            track_ids: [],
            watermark_ids: [],
          });

          let watermark_ids = [];
          let track_ids = [];

          if (data) {
            self.setState({ unauthorized: false });
            var downloadItems = self.state.downloadItems;
            data.download_item_list.forEach(track => {
              downloadItems.push(track);
              track_ids.push(track.track_id);
              watermark_ids.push(track.watermark_id);
            });
            self.downloadManagerGlobalData(data);
            self.setState({
              downloadItems: downloadItems,
              totalSize: data.total_size,
              totalItems: data.total_items,
              message: data.message,
              track_ids: track_ids,
              watermark_ids: watermark_ids,
            });
          } else {
            self.setState({
              downloadItems: [],
              totalSize: '',
              totalItems: 0,
              message: data.message,
              track_ids: [],
              watermark_ids: [],
            });
          }
          self.setTotalDownloads(self.state.totalItems);
          self.renderDownloadItems();
          callback();
        })
        .catch(function (error) {
          zdCheckAuthOnAjax(error);
        });
    }
  };

  removeDownloadItem = e => {
    e.preventDefault();

    let self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    self.setState({ zdLoader: true, loadingText: '' });

    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/remove-track',
      xSessionToken: loginInfo.token,
    };

    let watermark_id = e.currentTarget.getAttribute('data-watermark_id');
    let track_id = e.currentTarget.getAttribute('data-id');

    axios
      .post(
        api.baseUrl,
        {
          xajaxargs: [watermark_id, track_id],
        },
        {
          timeout: 180 * 1000,
          headers: {
            'X-Session-Token': api.xSessionToken,
          },
        },
      )
      .then(function (response) {
        const data = response.data;
        if (data.error === false) {
          //Reset Download List
          if (document.getElementById('download_row_icon_' + track_id)) {
            document.getElementById(
              'download_row_icon_' + track_id,
            ).style.display = 'inline';
          }

          if (document.getElementById('track_download_row_icon_' + track_id)) {
            document.getElementById(
              'track_download_row_icon_' + track_id,
            ).style.display = 'inline';
          }
        }
        self.getDownloadListItems(function () {
          self.setState({ zdLoader: false, loadingText: '' });
        });
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
        self.setState({ zdLoader: false, loadingText: '' });
      });
  };

  trackDownload = e => {
    e.preventDefault();

    let self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    let download_type = e.currentTarget.getAttribute('data-type');
    let release_id = e.currentTarget.getAttribute('data-release_id');
    let track_id = e.currentTarget.getAttribute('data-id');
    let watermark_id = e.currentTarget.getAttribute('data-watermark_id');

    let download_id = 0;
    if (download_type === 'queue-download') {
      download_id = e.currentTarget.getAttribute('data-download_id');
    }

    self.setState({
      zdLoader: true,
      loadingText: `Preparing Download. Please wait!`,
    });

    const api = {
      xSessionToken: loginInfo.token,
    };

    let post_data = {};
    let url;
    if (download_type === 'batch-download') {
      if (process.env.REACT_APP_ENV == 'staging') {
        url =
          process.env.REACT_APP_API_PATH +
          '/zdmapi/download-manager/download-track-all';
      } else {
        let react_alt_api_paths = [
          process.env.REACT_APP_ALT_API_PATH_1,
          process.env.REACT_APP_ALT_API_PATH_2,
          process.env.REACT_APP_ALT_API_PATH_3,
        ];
        let shuffled_react_alt_api_paths = _shuffle(react_alt_api_paths);
        url =
          shuffled_react_alt_api_paths[0] +
          '/zdmapi/download-manager/download-track-all';
      }
    } else if (download_type === 'queue-download') {
      post_data = {
        xajaxargs: [download_id, 0],
      };

      url =
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/download-track';
    } else {
      post_data = {
        type: watermark_id,
        xajaxargs: [release_id, track_id, 0],
      };

      url =
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/download-track-single';
    }

    axios
      .post(url, post_data, {
        timeout: 180 * 1000,
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        const data = response.data;
        self.getDownloadListItems(function () {
          if (data.error === false && data.download_path !== '') {
            let fileURL = data.download_path.replace(
              /^\//g,
              process.env.REACT_APP_BACKEND_URL + '/',
            );
            window.zdDownloadFile(fileURL, '');
          }
          self.setState({ zdLoader: false, loadingText: '' });
          self.props.dmwNotification(data.message);
        });
      })
      .catch(function (error) {
        if (download_type === 'batch-download') {
          const api = {
            baseUrl:
              process.env.REACT_APP_API_PATH + '/zdmapi/download-manager',
            xSessionToken: loginInfo.token,
          };

          let download_link =
            process.env.REACT_APP_API_PATH +
            '/zdmapi/user-batch-downloads/download-zip/last_batch_file/?_sesst=' +
            api.xSessionToken;
          window.open(download_link, '_blank');

          axios
            .post(
              api.baseUrl,
              {},
              {
                timeout: 180 * 1000,
                headers: {
                  'X-Session-Token': api.xSessionToken,
                },
              },
            )
            .then(function (response) {
              const data = response.data;
              self.setState({
                downloadItems: [],
                totalSize: '',
                totalItems: 0,
                message: '',
                track_ids: [],
                watermark_ids: [],
              });

              let watermark_ids = [];
              let track_ids = [];

              if (data) {
                self.setState({ unauthorized: false });
                var downloadItems = self.state.downloadItems;
                data.download_item_list.forEach(track => {
                  downloadItems.push(track);
                  track_ids.push(track.track_id);
                  watermark_ids.push(track.watermark_id);
                });
                self.downloadManagerGlobalData(data);
                self.setState({
                  downloadItems: downloadItems,
                  totalSize: data.total_size,
                  totalItems: data.total_items,
                  message: data.message,
                  track_ids: track_ids,
                  watermark_ids: watermark_ids,
                });
              } else {
                self.setState({
                  downloadItems: [],
                  totalSize: '',
                  totalItems: 0,
                  message: data.message,
                  track_ids: [],
                  watermark_ids: [],
                });
              }
              self.setTotalDownloads(self.state.totalItems);
              self.renderDownloadItems();
              // callback();
            })
            .catch(function (error) {
              zdCheckAuthOnAjax(error);
            });
        }
        console.log(error);
        zdCheckAuthOnAjax(error);
        self.setState({ zdLoader: false, loadingText: '' });
      });
  };

  componentDidUpdate(nextProps) {
    // This will erase any local state updates!

    if (this.props.downloadManagerData !== nextProps.downloadManagerData) {
      let downloadData = this.props.downloadManagerData
        ? this.props.downloadManagerData
        : { download_item_list: [] };

      let watermark_ids = [];
      let track_ids = [];
      downloadData.download_item_list &&
        downloadData.download_item_list.length &&
        downloadData.download_item_list.map(track => {
          watermark_ids.push(track.watermark_id);
          track_ids.push(track.track_id);
        });
      this.setState({
        downloadItems: downloadData.download_item_list,
        totalSize: downloadData.total_size,
        totalItems: downloadData.total_items,
        message: downloadData.message,
        track_ids: track_ids,
        watermark_ids: watermark_ids,
      });
      this.setTotalDownloads(downloadData.total_items);
    }
  }

  zdDownloadManagerToggle() {
    document.body.classList.toggle('zd-slide-open');
  }

  toggleBatchDownload() {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    let self = this;
    self.setState({
      showDownloadTracks: !this.state.showDownloadTracks,
      showPastDownloads: !this.state.showPastDownloads,
    });
    if (!this.state.showDownloadTracks) {
      self.setState({
        zdLoader: true,
        loadingText: 'Loading Tracks List ...',
      });
      self.getDownloadListItems(function () {
        self.setState({ zdLoader: false, loadingText: '' });
      });
    }
    if (!this.state.showPastDownloads) {
      self.setState({
        lastDownloadItems: [],
        zdLoader: true,
        loadingText: 'Loading Past Downloads ...',
      });
      axios
        .post(
          process.env.REACT_APP_API_PATH + '/zdmapi/user-batch-downloads',
          {},
          {
            timeout: 180 * 1000,
            headers: {
              'X-Session-Token': loginInfo.token,
            },
          },
        )
        .then(function (response) {
          const data = response.data;
          self.setState({
            lastDownloadItems: self.renderLastDownloadItems(data),
            zdLoader: false,
            loadingText: '',
          });
        })
        .catch(function (error) {
          zdCheckAuthOnAjax(error);
          self.setState({ zdLoader: false, loadingText: '' });
        });
    }
  }

  clearQueueTrack = e => {
    e.preventDefault();

    let self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    let download_id = e.currentTarget.getAttribute('data-id');
    let download_index = e.currentTarget.getAttribute('data-index');

    self.setState({ zdLoader: true, loadingText: '' });

    const api = {
      xSessionToken: loginInfo.token,
    };

    let post_data = {
      xajaxargs: [download_id, download_index],
    };

    let url =
      process.env.REACT_APP_API_PATH + '/zdmapi/download-manager/delete-batch';

    axios
      .post(url, post_data, {
        timeout: 180 * 1000,
        headers: {
          'X-Session-Token': api.xSessionToken,
        },
      })
      .then(function (response) {
        self.getDownloadListItems(function () {
          self.setState({ zdLoader: false, loadingText: '' });
        });
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
        self.setState({ zdLoader: false, loadingText: '' });
      });
  };

  renderLastDownloadItems(items) {
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';
    if (items.length < 1) {
      return (
        <div className="zd-info-msg text-center mt-3">
          No previous downloads
        </div>
      );
    }
    return (
      <ListGroup>
        {items.map((item, j) => {
          let download_link =
            process.env.REACT_APP_API_PATH +
            '/zdmapi/user-batch-downloads/download-zip/' +
            item.batch_filename +
            '?_sesst=' +
            loginInfo.token;
          return (
            <ListGroupItem
              key={item.batch_download_id}
              onClick={e => {
                window.open(download_link, '_blank');
              }}
            >
              <div className="album-image">
                <span
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <GoFileZip size="15px" />
                </span>
              </div>
              <div className="album-title">
                <span className="track-name zd-text-ellipsis">
                  {item.download_name}
                </span>
                <span className="artist-name zd-text-ellipsis">
                  {item.download_datetime_timeago}
                </span>
              </div>
              <ListGroup className="zd-actions zd-default-list">
                <ListGroupItem>
                  <span
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                    }}
                    className="zd-download-btn zd-cursor"
                  >
                    <i className="zd-player-icon-download"></i>
                  </span>
                </ListGroupItem>
              </ListGroup>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }
  renderDownloadItems() {
    return this.state.downloadItems.map((media, index) => {
      const {
        track_mix,
        track_id,
        wave_path,
        release_input_feedback,
        release_cover,
        genre_title,
        track_genre_id,
        release_label,
        release_label_id,
        track_filename,
        release_id,
        artist,
        watermark_id,
        track_file_size,
        archive_flag,
        play_status,
      } = media;

      const join_artist = joinArtist(JSON.stringify(artist));
      const track_path =
        process.env.REACT_APP_UPLOAD_TRACK_PATH + '/' + track_filename;
      const wave_img_path =
        process.env.REACT_APP_UPLOAD_TRACK_PATH + '/img/' + wave_path;

      return (
        <ListGroupItem
          key={index}
          tabIndex={index}
          id={index}
          data-index={index}
          rel={track_id}
        >
          <div className="album-image">
            <a
              onClick={e => {
                playTrack(e);
                this.props.AddMediaToPlay(e);
              }}
              id={'play-link-' + track_id}
              className="zd-item-play"
              data-play-status="play"
              data-current-play-status="play"
              data-track-id={track_id}
              data-play-id={release_id}
              data-release_id={release_id}
              data-label_id={release_label_id}
              data-id={track_id}
              data-archive_flag={archive_flag}
              data-source={track_filename}
              data-thumb={release_cover}
              data-track={track_mix}
              // data-artist={join_artist}
              data-artist={artist.map(artistItem => {
                return artistItem.artist_name;
              })}
              data-artist_details={JSON.stringify(artist)}
              data-is_pack="0"
              data-type="releases"
              data-feedback={release_input_feedback}
              data-trackpath={track_path}
              data-wavepath={wave_img_path}
              data-genres={genre_title}
              data-genre-id={track_genre_id}
              data-release={release_label}
              ///////
            >
              <ZipDJIcon iconType={play_status ? 'repeat-track' : 'play'} />
            </a>
          </div>
          <a className="album-title">
            <span className="track-name zd-text-ellipsis">
              {release_label + ' ' + track_mix}
            </span>
            <span className="artist-name zd-text-ellipsis">{join_artist}</span>
          </a>
          <ListGroup className="zd-actions zd-default-list">
            <ListGroupItem>
              <a
                onClick={this.trackDownload}
                data-type="single-download"
                className="zd-download-btn"
                data-toggle="download"
                data-filesize={track_file_size}
                title={release_label}
                data-id={track_id}
                data-watermark_id={watermark_id}
                data-clear-watermark_id={watermark_id}
                data-clear-track_id={track_id}
                data-release_id={release_id}
                data-title={track_mix}
              >
                <i className="zd-player-icon-download"></i>
              </a>
            </ListGroupItem>
            <ListGroupItem>
              <a
                onClick={e => {
                  this.removeDownloadItem(e);
                }}
                className="zd-delete-btn zd-blue mb-1"
                data-toggle="delete"
                title={release_label}
                data-id={track_id}
                data-release_id={release_id}
                data-watermark_id={watermark_id}
                data-title={track_mix}
              >
                <i className="zd-icon-delete-2"></i>
              </a>
            </ListGroupItem>
          </ListGroup>
        </ListGroupItem>
      );
    });
  }

  clearAllTrack = e => {
    this.toggleClearPopup(e);

    this.removeAllDownloadItems(this.state.watermark_ids, this.state.track_ids);
  };

  removeAllDownloadItems = (watermark_ids, track_ids) => {
    let self = this;
    let loginInfo = localStorage.getItem('_zd_tk');
    loginInfo = loginInfo !== null ? JSON.parse(loginInfo) : '';

    self.setState({ zdLoader: true, loadingText: '' });
    const api = {
      baseUrl:
        process.env.REACT_APP_API_PATH +
        '/zdmapi/download-manager/remove-track',
      xSessionToken: loginInfo.token,
    };

    let watermark_ids_string = watermark_ids.join();
    let track_ids_string = track_ids.join();

    axios
      .post(
        api.baseUrl,
        {
          xajaxargs: [watermark_ids_string, track_ids_string],
        },
        {
          timeout: 180 * 1000,
          headers: {
            'X-Session-Token': api.xSessionToken,
          },
        },
      )
      .then(function (response) {
        const data = response.data;
        if (data.error === false) {
          //Reset Download List
          track_ids.map(track_id => {
            if (document.getElementById('download_row_icon_' + track_id)) {
              document.getElementById(
                'download_row_icon_' + track_id,
              ).style.display = 'inline';
            }

            if (
              document.getElementById('track_download_row_icon_' + track_id)
            ) {
              document.getElementById(
                'track_download_row_icon_' + track_id,
              ).style.display = 'inline';
            }
          });
        }
        self.setState({
          track_ids: [],
          watermark_ids: [],
        });
        self.getDownloadListItems(function () {
          self.setState({ zdLoader: false, loadingText: '' });
        });
      })
      .catch(function (error) {
        console.log(error);
        zdCheckAuthOnAjax(error);
        self.setState({ zdLoader: false, loadingText: '' });
      });
  };

  toggleClearPopup = e => {
    this.setState({
      clearAllPopup: !this.state.clearAllPopup,
    });
  };

  render() {
    const filterAjaxLoader = this.state.zdLoader ? (
      <div className="zd-loader zd-page-loader zd-widget-loader">
        <ZDWaveLoader />
        <p className="d-flex justify-content-center align-items-center h-100 mt-4">
          {this.state.loadingText}
        </p>
      </div>
    ) : (
      ''
    );

    return (
      <React.Fragment>
        <div className="zd-download-manager-sidebar zd-download-manager-tags">
          {filterAjaxLoader}
          <h5 className="zd-download-manager-title">Download Manager</h5>
          <div className="zd-download-widget">
            <Card
              className={
                'zd-download-widget-section zd-download-list zd-default-list' +
                (this.state.showDownloadTracks ? '' : ' card-collapsed')
              }
            >
              <CardHeader
                onClick={this.toggleBatchDownload.bind(this)}
                className="zd-primary-dropdown zd-hide zd-blue"
              >
                DOWNLOAD TRACKS
                <div className="float-right">
                  {this.state.showDownloadTracks ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
              </CardHeader>
              <Collapse isOpen={this.state.showDownloadTracks}>
                <CardBody id="test">
                  <div
                    className={
                      this.state.totalItems > 0
                        ? `text-muted pb-3 d-flex`
                        : `text-muted pb-3 d-flex zd-hide`
                    }
                  >
                    <span
                      className="zd-cursor dw-manager-closed zd-blue-active"
                      onClick={this.zdDownloadManagerToggle}
                    >
                      Close
                    </span>
                    <span
                      className="ml-auto pr-3 zd-cursor"
                      onClick={this.toggleClearPopup}
                    >
                      Clear All
                    </span>
                  </div>
                  {this.state.clearAllPopup ? (
                    <div className="zd-loader zd-page-loader zd-widget-loader d-flex">
                      <div className="mb-5 pb-5">
                        <p className="p-3 font-weight-bold">
                          Are you sure you want to clear all the tracks?
                        </p>
                        <div className="d-flex justify-content-around">
                          <button
                            className="btn btn-secondary zd-clear-btn zd-white-font border-0 font-weight-bold"
                            onClick={this.clearAllTrack}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-primary zd-batch-download-btn zd-white-font border-0 font-weight-bold"
                            onClick={this.toggleClearPopup}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <ListGroup>{this.renderDownloadItems()}</ListGroup>

                  {this.state.message ? (
                    <>
                      <span
                        className="zd-cursor ml-2 dw-manager-closed zd-blue-active"
                        onClick={this.zdDownloadManagerToggle}
                      >
                        Close
                      </span>
                      <div className="zd-info-msg mt-2">
                        {this.state.message}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </CardBody>
              </Collapse>
            </Card>

            <Card
              className={
                'zd-download-widget-section zd-batch-reset-items zd-default-list' +
                (this.state.showPastDownloads ? '' : ' card-collapsed ')
              }
            >
              <CardHeader
                onClick={this.toggleBatchDownload.bind(this)}
                className={
                  this.state.showPastDownloads
                    ? 'zd-primary-dropdown zd-download-background'
                    : 'zd-primary-dropdown'
                }
              >
                PAST DOWNLOADS
                <div className="float-right">
                  {this.state.showPastDownloads ? (
                    <MdKeyboardArrowDown />
                  ) : (
                    <MdKeyboardArrowUp />
                  )}
                </div>
              </CardHeader>
              <Collapse isOpen={this.state.showPastDownloads}>
                <CardBody>
                  <div className="text-muted pb-3">
                    Last 5 batch downloads from the previous 24 hours.
                  </div>
                  {this.state.lastDownloadItems}
                </CardBody>
              </Collapse>
            </Card>
          </div>
          <div className="zd-download-footer">
            {/*
          <div className="zd-batch-reset-btn">
            <span onClick={this.toggleBatchDownload.bind(this)}>
              Past Downloads <small>(Last 5 from 24 Hours)</small>
            </span>
          </div>
          */}
            <span
              className="zd-cart-size mt-2"
              data-filesize={this.state.totalSize}
            >
              Size: {this.state.totalSize}MB
            </span>
            {this.state.totalSize > 0 ? (
              <button
                onClick={this.trackDownload}
                data-type="batch-download"
                data-release_id=""
                data-id=""
                data-watermark_id=""
                className="btn btn-primary zd-batch-download-btn float-right zd-hide-mobileTab"
              >
                Batch Download
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ZDSlideDownloadManagerWidget;
