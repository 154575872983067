import axios from 'axios';

export const saveSearchHistory = previousURL => {
  return;
  console.log('saveSearchHistory', previousURL);

  const text = window.location.search;
  const urlParams = new URLSearchParams(text);
  const searchQuery = urlParams.get('q');

  // Make an axios call to save the search query
  const api = {
    baseUrl:
      process.env.REACT_APP_API_PATH + '/zdmapi/save-user-track-search-history',
    xSessionToken: JSON.parse(localStorage.getItem('_zd_tk')),
  };

  const post_data = {
    search_text: `${searchQuery}`,
  };

  console.log(api.xSessionToken.token);
  axios.post(api.baseUrl, post_data, {
    headers: { 'X-Session-Token': api.xSessionToken.token },
  });
};
